import { useGlobalStore, usePersistantStore } from "@/stores/global";
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useEffect, useState } from "react";
import { TopBar } from "@/shared/structure/TopBar";
import useRights from "@/shared/api/useRights";
import { LocalShipping, Warehouse } from "@mui/icons-material";
import DeliveryMachines from "./DeliveryMachines";
import Bauhof from "./Bauhof";

export default function MachineTransportWrap() {
  const setPageTitle = useGlobalStore((state) => state.setPageTitle);


  useEffect(() => setPageTitle("Maschinentransport"), []);
  const rights = useRights();

  const [list, setList] = useState<"DELIVERY" | "TAKEAWAY">("DELIVERY");

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          p: 0,
          background: "#eee",
        }}
      >
        <TopBar>
          <ToggleButtonGroup
            size="small"
            orientation="horizontal"
            exclusive
            value={list}
            onChange={(_, type) => type && setList(type)}
          >
            <ToggleButton value="DELIVERY">
              <LocalShipping sx={{mr: 1}} /> Transportgeräte
            </ToggleButton>
            <ToggleButton value="TAKEAWAY">
              <Warehouse sx={{mr: 1}} /> Abholgeräte
            </ToggleButton>
          </ToggleButtonGroup>
        </TopBar>
        {list === "DELIVERY" ? <DeliveryMachines /> : <Bauhof />}
      </Box>
    </Box>
  );
}
