import ApiService from "@/api/ApiService";
import { useQueryClient } from "@tanstack/react-query";

export function useJobKeys() {
    const queryClient = useQueryClient();
  
    const fetchJobKeys = (phaseId: number) => {
      const queryKey = ["jobKeys", phaseId];
  
      // Try to get cached data synchronously
      const cachedData = queryClient.getQueryData(queryKey);
      if (cachedData) {
        return cachedData;
      }
  
      // If no cached data, trigger fetch in the background
      queryClient.fetchQuery({
        queryKey,
        queryFn: ApiService.getJobKeys(phaseId),
      });
  
      return null; // Data is still loading
    };
  
    return { fetchJobKeys };
  }