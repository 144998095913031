import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { ProjectState } from "./planning/Project";
import { CircularProgress, IconButton, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ArrowForward } from "@mui/icons-material";
import EnhancedTable from "@/shared/views/EnhancedTable";
import ApiService from "@/api/ApiService";
import { usePersistantStore } from "@/stores/global";
import useRights from "@/shared/api/useRights";
import { User } from "@/types/types";
import dayjs from "dayjs";

interface OverdueState {
  date: string;
  overdueSince: string | null;
  manager: User;
  status: "PLANNING" | "RAW";
  phaseId: number;
  phaseName: string;
  projectName: string;
  projectNumber: string;
  projectId: number;
}

export default function Overdue({
  type = "overdue",
}: {
  type: "overdue" | "ready";
}) {
  const navigate = useNavigate();
  const region = usePersistantStore((store) => store.region);
  const rights = useRights();

  const query = useQuery<OverdueState[], AxiosResponse>({
    queryKey: [type === "overdue" ? "getOverdue" : "getReady", region],
    queryFn:
      type === "overdue"
        ? ApiService.getOverdue(
            region,
            "DISPOSITION" in rights ? "ALL" : "MINE"
          )
        : ApiService.getReady(region),
  });

  return (query?.data?.length ?? 0) === 0 ? null : (
    <Paper
      sx={{ overflow: "hidden", display: "flex", flexDirection: "column" }}
    >
      <div
        style={{
          padding: "5px 10px",
          textTransform: "uppercase",
          fontWeight: "bold",
          color: "#555",
          fontSize: "15px",
          background: "#edf0f9",
          border: "1px solid rgba(0,0,0,0.2)",
          boxShadow: "inset 0px -4px 5px rgba(0,0,0,0.1)",
        }}
      >
        {type === "overdue" ? "Überfällig" : "Bereit zur Feinplanung"}
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          overflow: "auto",
          flexDirection: "column",
        }}
      >
        {query.data?.map((x) => (
          <div
            key={x.phaseId}
            onClick={() =>
              navigate("/project/" + x.projectId, {
                state: { phaseId: x.phaseId, date: x.date },
              })
            }
            className={"overduebox"}
          >
            <div
              className={
                "obcolor color " + (x.status === "PLANNING" ? "blue" : "yellow")
              }
            />
            <div className="obfacts">
              <div className="obbl">
                {x.manager.firstName + " " + x.manager.name}
              </div>
              <div className="obpro">
                <span className="projectnumber">{x.projectNumber}</span>
                {x.projectName}
              </div>
              <div className="obdateline">
                <div className="obdate">
                  {dayjs(x.date).format("DD.MM.YYYY")}
                </div>
                {!!x.overdueSince ? (
                  <div className="oboverdue">
                    <span className="obodx">Überfällig:</span>
                    {dayjs(x.overdueSince).fromNow(true)}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Paper>
  );
}
