import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Fragment, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import ApiService from "@/api/ApiService";
import { AxiosResponse } from "axios";
import { CheckCircle, ErrorOutline, ContentCopy } from "@mui/icons-material";

interface CopyButtonProps {
  text: string;
}

const CoordinateText: React.FC<CopyButtonProps> = ({ text }) => {
  const [copyStatus, setCopyStatus] = useState<"idle" | "success" | "error">(
    "idle"
  );

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setCopyStatus("success");
    } catch (error) {
      setCopyStatus("error");
    }

    setTimeout(() => setCopyStatus("idle"), 2000);
  };

  return (
    <div className="coordinate">
      <Tooltip
        title={
          copyStatus === "success"
            ? "Kopiert!"
            : copyStatus === "error"
            ? "Fehler beim Kopieren"
            : "Kopieren"
        }
      >
        <IconButton size="small" onClick={handleCopy}>
          {copyStatus === "success" ? (
            <CheckCircle fontSize="small" color="success" />
          ) : copyStatus === "error" ? (
            <ErrorOutline fontSize="small" color="error" />
          ) : (
            <ContentCopy fontSize="small" />
          )}
        </IconButton>
      </Tooltip>
      <div className="coordinate_text_wrap">
        <div className="coordinate_text">{text}</div>
        <div className="coordinate_text_exp">
          Kopiere die Koordinaten ins Karten-Suchfeld im #transplan!
        </div>
      </div>
    </div>
  );
};

export default function OrderLocationDialog({
  orderId,
  type = "ORDER",
  close,
}: {
  orderId: number;
  type: "RESOURCEBOOKING" | "ORDER";
  close: () => void;
}) {
  const query = useQuery<any, AxiosResponse>({
    queryKey: ["getOrderLocation", "" + orderId, type],
    enabled: !!orderId,
    queryFn: ApiService.getOrderLocation(orderId, type),
  });

  return (
    <Dialog open={!!orderId} maxWidth="lg" fullWidth onClose={close}>
      {query.isLoading || !orderId ? (
        <CircularProgress />
      ) : query.isLoadingError ? (
        "Error"
      ) : (
        <Fragment>
          <DialogTitle
            sx={{
              p: 1,
              px: 2,
              background: "#002169",
              color: "white",
              borderBottom: "1px solid rgba(255,255,255,0.5)",
            }}
          >
            POI der Bestellung
          </DialogTitle>

          <DialogContent
            className="target_wrapper"
            sx={{
              p: 0,
              overflow: "auto",
            }}
          >
            <div className="target_xx">
              <div className="target_title">Ziel</div>
              <div className="target_value">
                {query.data.target === "SITE"
                  ? "Baustelle"
                  : query.data.target === "LAGER"
                  ? "LAGERPLATZ"
                  : query.data.target}
              </div>
            </div>
            {!!query.data.position ? (
              <Fragment>
                {query.data.target === "LAGER" &&
                !query.data.position.useYard ? (
                  <div className="target_warning">
                    Kein Lagerplatz erfasst! Es folgen die Koordinaten des
                    Baustellenorts.
                  </div>
                ) : null}
                <div className="target_xx">
                  <div className="target_title">Koordinaten</div>
                  <div className="target_value">
                    <CoordinateText
                      text={
                        query.data.target === "SITE" ||
                        !query.data.position.useYard
                          ? query.data.position.site.lat +
                            "," +
                            query.data.position.site.lng
                          : query.data.target === "LAGER"
                          ? query.data.position.yard.lat +
                            "," +
                            query.data.position.yard.lng
                          : "???"
                      }
                    />
                  </div>
                </div>
                <div className="target_xx">
                  <div className="target_title">Nur Solo?</div>
                  <div className="target_value">
                    {query.data.position.onlySolo
                      ? "Ja, nur Solo möglich!"
                      : "Nein, auch Sattel möglich."}
                  </div>
                </div>
                {query.data.position.comment.length > 0 ? (
                  <div className="target_xx">
                    <div className="target_title">Anfahrtshinweise</div>
                    <div className="target_value">
                      {query.data.position.comment}
                    </div>
                  </div>
                ) : null}
              </Fragment>
            ) : (
              <div className="target_warning">Kein POI gesetzt!</div>
            )}
          </DialogContent>
          <DialogActions
            sx={{
              borderTop: "1px solid #aaa",
              justifyContent: "space-between",
            }}
          >
            <div />
            <Button onClick={close}>Schließen</Button>
          </DialogActions>
        </Fragment>
      )}
    </Dialog>
  );
}
