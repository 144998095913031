import { ArrowDropDown } from "@mui/icons-material";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { PossibleCalendarTypes } from "../MainCalendar";
import useReferenceNames from "@/shared/forms/useReferenceNames";
import { usePersistantStore } from "@/stores/global";
import buildTree from "@/components/shared/buildTree";
import useRights from "@/shared/api/useRights";

interface Tag {
  id: number;
  type: "RESOURCE" | "HUMAN" | "CREW";
  name: string;
  region: { id: number; name: string };
}

const CategoryItem = ({ x, onChange, showRegion }) => {
  const [open, setOpen] = useState(false);
  const hoverTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleMouseEnter = () => {
    // Set a timeout to open after 200ms
    hoverTimeout.current = setTimeout(() => {
      setOpen(true);
    }, 700);
  };

  const handleMouseLeave = () => {
    // Clear the timeout and close immediately
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
      hoverTimeout.current = null;
    }
    setOpen(false);
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <MenuItem className="categoryItemMenu" onClick={() => onChange(x.id)}>
        {x.name}
      </MenuItem>
      {open ? (
        <Box sx={{ backgroundColor: "rgba(0,0,0,0.1)" }}>
          {x.children.map((y) => (
            <FunItem
              key={y.name}
              x={y}
              onChange={onChange}
              showRegion={showRegion}
            />
          ))}
        </Box>
      ) : null}
    </div>
  );
};

export const FunItem = ({ x, onChange, showRegion }) =>
  x.type === "category" ? (
    <CategoryItem x={x} onChange={onChange} showRegion={showRegion} />
  ) : (
    <MenuItem onClick={() => onChange(x.id)}>
      {(x.name || "Unbekannt") + (showRegion ? " (" + x.region.name + ")" : "")}
    </MenuItem>
  );

export default function SelectCalendarDialog({
  type,
  reference,
  onChange,
}: {
  type: PossibleCalendarTypes;
  reference: number | null;
  onChange: (type: PossibleCalendarTypes, reference: number | null) => void;
}) {
  const rights = useRights();
  const isDispo = "DISPOSITION" in rights;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const region = usePersistantStore((state) => state.region);
  const [regionCheck, setRegionCheck] = useState(region);
  const open = !!anchorEl;
  const open2 = !!anchorEl2;
  const firstRender = useRef(true);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const close = useCallback(() => {
    setAnchorEl(null);
    setAnchorEl2(null);
  }, []);

  const tagNames = useReferenceNames<Tag, Tag>("resourceTag", (o) => o);

  const [resources, staffs, crews, firstCrew] = useMemo(() => {
    const r: Tag[] = [];
    const s: Tag[] = [];
    const c: Tag[] = [];
    for (const t of tagNames.values())
      if (
        ["CALENDAR_ONLY", "WISHABLE", "REQUESTABLE"].includes(
          t?.calendarType || ""
        ) &&
        (region === 0 || t.region.id === region) &&
        !t?.usesShortPlanning
      )
        (t.type === "HUMAN" ? s : t.type === "RESOURCE" ? r : c).push(t);
    const crewTree = (
      isDispo ? [] : [{ name: "Meine Projekte", id: 0 }]
    ).concat(buildTree(c));
    const firstCrew = isDispo ? c[0]?.id ?? 0 : 0;

    return [buildTree(r), buildTree(s), crewTree, firstCrew];
  }, [tagNames, region, isDispo]);

  console.log(crews);

  useEffect(() => {
    if (region === regionCheck) return;
    setRegionCheck(region);
    if (tagNames.get(reference ?? 0) && firstCrew > 0)
      onChange("CREWS", firstCrew);
  }, [region, firstCrew]);

  useEffect(() => {
    if (
      (!type || !["CREWS", "STAFF", "RESOURCES", "PROJECTS"].includes(type))
    )
      onChange("CREWS", firstCrew);
  }, [type, firstCrew]);

  const title =
    type === "CREWS"
      ? "Kolonnen"
      : type === "STAFF"
      ? "Mitarbeiter"
      : type === "RESOURCES"
      ? "Maschinen"
      : type === "PROJECTS"
      ? "Baustellen"
      : "Lädt...";

  const hasSecondMenu =
    type === "STAFF" || type === "RESOURCES" || type === "CREWS";

  const title2 = !hasSecondMenu
    ? ""
    : type==="CREWS" && reference === 0 ? "Meine Projekte" :  (tagNames.get(reference ?? 0)?.name || "Unbekannt") +
      (region === 0
        ? " (" +
          (tagNames.get(reference ?? 0)?.region.name || "Unbekannt") +
          ")"
        : "");

  console.log(crews, staffs, resources);

  return (
    <div>
      <Button onClick={handleClick} endIcon={<ArrowDropDown />}>
        {title}
      </Button>
      {hasSecondMenu ? (
        <Button onClick={handleClick2} endIcon={<ArrowDropDown />}>
          {title2}
        </Button>
      ) : null}
      {hasSecondMenu ? (
        <Menu
          open={open2}
          anchorEl={anchorEl2}
          onClose={close}
          slotProps={{
            paper: {
              style: {
                maxHeight: "400px",
              },
            },
          }}
        >
          {(type === "RESOURCES"
            ? resources
            : type === "CREWS"
            ? crews
            : staffs
          ).map((x) => (
            <FunItem
              x={x}
              key={x.name}
              showRegion={region === 0 && x.id !== 0}
              onChange={(id) => {
                onChange(type, id);
                close();
              }}
            />
          ))}
        </Menu>
      ) : null}
      <Menu open={open} anchorEl={anchorEl} onClose={close}>
        <MenuItem
          onClick={() => {
            type === "PROJECTS" || onChange("PROJECTS", null);
            close();
          }}
        >
          Baustellen
        </MenuItem>
        <MenuItem
          onClick={() => {
            type === "CREWS" ||
              onChange("CREWS", crews.length > 0 ? crews[0].id : 0);
            close();
          }}
        >
          Kolonnen
        </MenuItem>
        <MenuItem
          onClick={() => {
            type === "RESOURCES" ||
              onChange("RESOURCES", resources.length > 0 ? resources[0].id : 0);
            close();
          }}
        >
          Maschinen
        </MenuItem>
        <MenuItem
          onClick={() => {
            type === "STAFF" ||
              onChange("STAFF", staffs.length > 0 ? staffs[0].id : 0);
            close();
          }}
        >
          Mitarbeiter
        </MenuItem>
      </Menu>
    </div>
  );
}
