import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Fragment, useCallback, useState } from "react";

import dayjs from "dayjs";
import ColorPicker from "@/shared/forms/ColorPicker";
import NewRangePicker from "@/shared/forms/NewRangePicker";
import ManyToOneSelect from "@/shared/forms/ManyToOneSelect";
import { usePersistantStore } from "@/stores/global";
import { StaffMember } from "../../listView/CalendarListView";
import { CalProject } from "../../calendarElements/InnerCalendar";
import LabelWrapper from "@/components/root/LabelWrapper";

export interface ManualBooking {
  deleted?: boolean;
  staffMember: number | null;
  project: CalProject | null;
  erfasser: StaffMember | null;
  resource: number | null;
  start: string | null;
  end: string | null;
  comment: string;
  produktiv: boolean;
}

export default function AddBookingDialog({
  save,
  OpenButton,
  initialState,
}: {
  save: (order: ManualBooking) => void;
  OpenButton: any;
  initialState: ManualBooking;
}) {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState<ManualBooking>(initialState);
  const region = usePersistantStore((s) => s.region);

  const close = useCallback(() => {
    setOpen(false);
  }, []);

  const openAndSet = useCallback(() => {
    setState(initialState);
    setOpen(true);
  }, [initialState]);

  const valid = !!state.start && !!state.end;

  return (
    <Fragment>
      <OpenButton onClick={openAndSet} />
      {open ? (
        <Dialog open={open} maxWidth="xl" fullWidth onClose={close}>
          <Fragment>
            <DialogTitle
              sx={{
                p: 1,
                px: 2,
                background: "#002196",
                color: "white",
                borderBottom: "1px solid rgba(255,255,255,0.5)",
              }}
            >
              Kalendereintrag
            </DialogTitle>
            <DialogContent
              sx={{
                p: 0,
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <LabelWrapper label="Zeitraum">
                <NewRangePicker
                  small
                  onChange={([startDate, endDate]) => {
                    setState((s) => ({
                      ...s,
                      start: !!startDate
                        ? startDate.format("YYYY-MM-DD")
                        : null,
                      end: !!endDate ? endDate.format("YYYY-MM-DD") : null,
                    }));
                  }}
                  value={[
                    dayjs(state.start, "YYYY-MM-DD"),
                    dayjs(state.end, "YYYY-MM-DD"),
                  ]}
                />
              </LabelWrapper>

              <LabelWrapper label="Projekt">
                <ManyToOneSelect
                  entity={"project"}
                  remoteFilter={{ region: ["region", region], active: true }}
                  currentId={state.project?.id || 0}
                  disabled={false}
                  placeholder="Bitte wählen"
                  sideEffectOnlyOnChange={true}
                  setId={function (id: number) {
                    setState((s) => ({
                      ...s,
                      project: !!id
                        ? { id, name: "", number: "", comment: "" }
                        : null,
                    }));
                  }}
                />
              </LabelWrapper>

              <LabelWrapper label="Erfasser">
                <ManyToOneSelect
                  entity={"staffMember"}
                  remoteFilter={{
                    region: ["region", region],
                    baumobilErfasser: true,
                  }}
                  placeholder="Bitte wählen"
                  currentId={state.erfasser?.id || 0}
                  disabled={false}
                  sideEffectOnlyOnChange={true}
                  setId={function (id: number) {
                    setState((s) => ({
                      ...s,
                      erfasser: !!id
                        ? { id, name: "", number: "", tags: [] }
                        : null,
                    }));
                  }}
                />
              </LabelWrapper>

              <LabelWrapper label="Produktiv?">
                <ToggleButtonGroup
                  exclusive
                  disabled={false}
                  value={state.produktiv}
                  onChange={(_, produktiv) =>
                    setState((s) => ({
                      ...s,
                      produktiv,
                    }))
                  }
                >
                  <ToggleButton color="success" value={true}>
                    Ja
                  </ToggleButton>
                  <ToggleButton color="error" value={false}>
                    Nein
                  </ToggleButton>
                </ToggleButtonGroup>
              </LabelWrapper>

              <LabelWrapper label="Weitere Informationen">
                <TextField
                  size="small"
                  fullWidth
                  value={state.comment}
                  placeholder="Weitere Informationen"
                  multiline
                  onChange={(e) =>
                    setState((s) => ({
                      ...s,
                      comment: (e.target as HTMLInputElement).value,
                    }))
                  }
                />
              </LabelWrapper>
            </DialogContent>
            <DialogActions sx={{ borderTop: "1px solid #aaa" }}>
              <Button onClick={close}>Abbrechen</Button>
              <Button
                variant="outlined"
                color="primary"
                disabled={!valid}
                onClick={() => {
                  save(state);
                  close();
                }}
              >
                OK
              </Button>
            </DialogActions>
          </Fragment>
        </Dialog>
      ) : null}
    </Fragment>
  );
}
