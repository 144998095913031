import React, { useEffect, useState } from "react";
import { Box, Button, IconButton } from "@mui/material";
import { ProjectState, ProjectJob, ProjectPhase } from "../Project";
import { ContentCopy, ContentPaste } from "@mui/icons-material";
import { date } from "@/shared/reactdatagrid-community/filterTypes";

interface CopyPasterProps {
  job: ProjectJob;
  project: ProjectState;
  disabled: boolean;
  phaseId: number;
  setField: (changes: Partial<ProjectJob>) => void;
  setProjectField: (changes: Partial<ProjectState>) => void;
  setPhaseField: (changes: Partial<ProjectPhase>) => void;
}

interface CopyState {
  phaseExceptions: number[];
  projectExceptions: number[];
  materialOrders: ProjectJob["materialOrders"];
  resourceOrders: ProjectJob["resourceOrders"];
  job: Partial<ProjectJob>;
}

const CopyPaster: React.FC<CopyPasterProps> = ({
  job,
  disabled,
  project,
  phaseId,
  setField,
  setPhaseField,
  setProjectField,
}) => {
  const [copyState, setCopyState] = useState<CopyState | null>(null);
  const [isPasteAble, setIsPasteable] = useState(true);

  useEffect(() => setIsPasteable(true), [job.date, phaseId]);


  const handleCopy = () => {
    const phaseExceptions = project.phases[phaseId].resourceOrders
      .filter((x) => x.exceptions.includes(job.uuid))
      .map((x) => x.id);
    const projectExceptions = project.resourceOrders
      .filter((x) => x.exceptions.includes(job.uuid))
      .map((x) => x.id);
    const materialOrders = job.materialOrders.map((x) => ({ ...x, id: 0 }));
    const resourceOrders = job.resourceOrders.map((x) => ({ ...x, id: 0 }));
    const jobData = { ...job };
    const propertiesToDelete: (keyof ProjectJob)[] = [
      "date",
      "materialOrders",
      "resourceOrders",
      "id",
      "uuid",
      "status",
    ];
    propertiesToDelete.forEach((property) => delete jobData[property]);
    setCopyState({
      phaseExceptions,
      projectExceptions,
      materialOrders,
      resourceOrders,
      job: jobData,
    });
    setIsPasteable(false);
  };

  console.log(copyState);

  const handlePaste = () => {
    if (!copyState) {
      console.log("copystate", copyState);
      return;
    }

    setProjectField({
      resourceOrders:
        copyState.projectExceptions.length > 0
          ? project.resourceOrders.map((x) =>
              copyState.projectExceptions.includes(x.id)
                ? { ...x, changed: true, exceptions: x.exceptions.concat([job.uuid]) }
                : x
            )
          : project.resourceOrders,
      phases: project.phases.map((phase, i) =>
        phaseId === i
          ? {
              ...phase,
              resourceOrders:
                copyState.phaseExceptions.length > 0
                  ? phase.resourceOrders.map((x) =>
                      copyState.phaseExceptions.includes(x.id)
                        ? {
                            ...x,
                            changed: true,
                            exceptions: x.exceptions.concat([job.uuid]),
                          }
                        : x
                    )
                  : phase.resourceOrders,
              jobs: Object.fromEntries(
                Object.entries(phase.jobs).map((entry) =>
                  entry[0] === job.date
                    ? [
                        entry[0],
                        {
                          ...entry[1],
                          ...copyState.job,
                          changed: true,
                          materialOrders: entry[1].materialOrders.concat(
                            copyState.materialOrders
                          ),
                          resourceOrders: entry[1].resourceOrders.concat(
                            copyState.resourceOrders
                          ),
                        },
                      ]
                    : entry
                )
              ),
            }
          : phase
      ),
    });

    setIsPasteable(false);
  };

  return (
    <Box sx={{ display: "flex", gap: 1, paddingX: 1 }}>
      {!!copyState ? (
        <IconButton
          size="small"
          disabled={!isPasteAble || disabled}
          onClick={handlePaste}
        >
          <ContentPaste />
        </IconButton>
      ) : null}
      <IconButton size="small" onClick={handleCopy}>
        <ContentCopy />
      </IconButton>
    </Box>
  );
};

export default CopyPaster;
