import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Fragment } from "react";
import dayjs from "dayjs";
import { useQuery } from "@tanstack/react-query";
import ApiService from "@/api/ApiService";
import OrderInformation from "../listView/OrderInformation";
import { useNavigate } from "react-router-dom";



export default function JobInfoDialog({
  jobId,
  close,
}) {
  
  const query = useQuery<any, AxiosResponse>({
    queryKey: ["getJobApp", ""+jobId],
    enabled: !!jobId,
    queryFn: ApiService.getEntity("projectJob", jobId, true, "app"),
  });

  const navigate = useNavigate();


  return (
    <Dialog open={!!jobId} maxWidth="lg" fullWidth onClose={close}>
      {query.isLoading||!jobId ? <CircularProgress/> : query.isLoadingError || !query.data?.phase ? "Error" : <Fragment>
        <DialogTitle
          sx={{
            p: 1,
            px: 2,
            background: "#002169",
            color: "white",
            borderBottom: "1px solid rgba(255,255,255,0.5)",
          }}
        >
          {query.data.phase.project.name+" ("+query.data.phase.project.number+"): "+dayjs(query.data.date,"YYYY-MM-DD").format("DD.MM.YYYY")}
        </DialogTitle>
        <DialogContent
          sx={{
            p: 0,
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <OrderInformation data={query.data} isTransDispo={false} setModalOpen={false} />
        </DialogContent>
        <DialogActions sx={{ borderTop: "1px solid #aaa", justifyContent:"space-between" }}>
            <Button onClick={() => window.open("/project/" + query.data.phase.project.id + "?phaseId=" + query.data.phase.id + "&date=" + query.data.date, "_blank")} variant="contained">Planung öffnen</Button>
          <Button onClick={close}>Schließen</Button>
        </DialogActions>
      </Fragment>}
    </Dialog>
  );
}
