import { PossibleCalendarTypes } from "@/components/calendar/MainCalendar";
import BaseApiService from "@/shared/api/BaseApiService";
import request from "@/shared/api/request";

export default class ApiService extends BaseApiService {
  static getEntitiesWithFilter(
    entity: string,
    filter: { [field: string]: string | number | [string, number] },
    v?: string
  ) {
    let add = {};
    if (!!v) {
      add = { v };
    }
    return () =>
      request({
        url: "/entity/" + entity + "/",
        method: "GET",
        params: { filter: btoa(JSON.stringify(filter)), ...add },
      });
  }

  static getResourceStatus(
    type: "HUMAN" | "CREW" | "RESOURCE",
    tag: number,
    region: number,
    filter: {type: "dates", dates: string[] } | {type: "phase"; phase: number; startConstraint: string | null; endConstraint: string | null; exceptions: string[];}
  ) {
    return () =>
      request({
        url: "/data/resStatus/"+type+"/"+tag+"/"+region+"/"+btoa(JSON.stringify(filter)),
        method: "GET",
      });
  }

  static getNullErfasser(
    region: number,
  ) {
    return () =>
      request({
        url: "/data/nullErfasser/"+region,
        method: "GET",
      });
  }

  static getBestellfrist(
    region: number,
    date: string
  ) {
    return () =>
      request({
        url: "/data/bestellfrist/"+region+"/"+date,
        method: "GET",
      });
  }

  static getBookingList(
    date: string,
    region: number,
  ) {
    return () =>
      request({
        url: "/data/bookingList/"+date+"/"+region,
        method: "GET",
      });
  }

  static getLiveMap(
    type: string,
    query: string,
    region: number
  ) {
    return () =>
      request({
        url: "/data/jMap/"+region+"/"+type+"/"+query,
        method: "GET",
      });
  }

  static parseOrderConfirmation(file: File, possibleMixingPlants: string[]) {
    const data = new FormData();
    data.append("file", file);
    data.append("possibleMixingPlants", JSON.stringify(possibleMixingPlants));
    return request({
      url: "/parseOrderConfirmation/",
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data,
    });
  }

  static sendOrder(jobId: string) {
    return () =>
      request({
        url: "/data/sendOrder/" + jobId,
        method: "POST",
        data: {},
      });
  }

  static updateErfasser(bookingId: string) {
    return (erfasserId: number) =>
      request({
        url: "/data/updateErfasser/" + bookingId,
        method: "POST",
        data: {erfasserId},
      });
  }

  static setPlantOrderStatus(orderId: number) {
    return (status: string) =>
      request({
        url: "/data/setPlantOrderStatus/" + orderId + "/" + status,
        method: "POST",
        data: {},
      });
  }

  static setTransportOrderStatus(orderId: number) {
    return (status: string) =>
      request({
        url: "/data/setTransportOrderStatus/" + orderId + "/" + status,
        method: "POST",
        data: {},
      });
  }

  static getBauhofArticles(projectId: string) {
    return () =>
      request({
        url: "/data/bauhofArticles/" + projectId,
        method: "GET",
      });
  }

  static getTransportStatus(date: string, region: number) {
    return () =>
      request({
        url: "/data/getTransportStatus/" + date+"/"+region,
        method: "GET",
      });
  }

  static getOrderHistory(projectId: string) {
    return () =>
      request({
        url: "/data/orderHistory/" + projectId,
        method: "GET",
      });
  }

  static getOrderLocation(projectId: string, type: "JOB" | "ORDER") {
    return () =>
      request({
        url: "/data/orderLocation/" + projectId + "/" + type,
        method: "GET",
      });
  }

  static getJobKeys(phaseId: number) {
    return () =>
      request({
        url: "/data/jobKeys/" + phaseId,
        method: "GET",
      });
  }

  static getOverdue(region: number, type: "ALL" | "MINE") {
    return () =>
      request({
        url: "/data/overdue/" + region + "/"+type,
        method: "GET",
      });
  }

  static getReady(region: number) {
    return () =>
      request({
        url: "/data/readyState/" + region,
        method: "GET",
      });
  }

  static getMachineTransport(region: number) {
    return () =>
      request({
        url: "/data/mt_transport/" + region,
        method: "GET",
      });
  }

  static getBauhofTransport(region: number) {
    return () =>
      request({
        url: "/data/mt_bauhof/" + region,
        method: "GET",
      });
  }

  static searchMaterials() {
    return (data: { search: string }) =>
      request({
        url: "/data/searchMaterials/" + data.search,
        method: "GET",
      });
  }

  static changeCalendar() {
    return (data) =>
      request({
        url: "/data/changeCalendar",
        method: "POST",
        data,
      });
  }

  static updateCrew() {
    return (data) =>
      request({
        url: "/data/updateCrew",
        method: "POST",
        data
      });
  }

  static setTransportStatus() {
    return (data) =>
      request({
        url: "/data/setTransportStatus",
        method: "POST",
        data,
      });
  }

  static setPlantStatus() {
    return (data) =>
      request({
        url: "/data/setPlantStatus",
        method: "POST",
        data,
      });
  }

  static changeKGPCalendar() {
    return (data) =>
      request({
        url: "/data/changeKGPCalendar",
        method: "POST",
        data,
      });
  }

  static uploadFile() {
    return (file) => {
      const data = new FormData();
      data.append("file", file);
      return request({
        url: "/data/uploadImage",
        method: "POST",
        data,
        headers: { "Content-Type": "multipart/form-data" },
      });
    };
  }

  static autoBook() {
    return (id) =>
      request({
        url: "/data/autoBooking/" + id,
        method: "POST",
      });
  }

  static getCalendar(
    type: PossibleCalendarTypes,
    reference: number,
    month: string,
    region: number
  ) {
    return () =>
      request({
        url:
          "/data/calendar/" +
          type +
          "/" +
          reference +
          "/" +
          month +
          "/" +
          region,
        method: "GET",
      });
  }

  static getDispoCal(date: string, region: number) {
    return () =>
      request({
        url: "/data/dispoCal/" + date + "/" + region,
        method: "GET",
      });
  }

  static getKGP(reference: number, date: string, region: number) {
    return () =>
      request({
        url: "/data/kgp/" + reference + "/" + date + "/" + region,
        method: "GET",
      });
  }

  static getJonasKalender(month: string, region: number, active: string[]) {
    return () =>
      request({
        url: "/data/jonaskalender/" + month + "/" + region + "/" + active.join(","),
        method: "GET",
      });
  }

  static getOpenRequests(
    region: number,
    dispoCategory: number,
    type: "LONG" | "SHORT"
  ) {
    return () =>
      request({
        url: "/data/open/" + region + "/" + dispoCategory + "/" + type,
        method: "GET",
      });
  }

  static getCollisions(region: number, dispoCategory: number) {
    return () =>
      request({
        url: "/data/collisions/" + region + "/" + dispoCategory,
        method: "GET",
      });
  }

  static getFreeRequests(region: number, dispoCategory: number, week: string) {
    return () =>
      request({
        url: "/data/free/" + region + "/" + dispoCategory + "/" + week,
        method: "GET",
      });
  }

  static getMap(region: number) {
    return () =>
      request({
        url: "/data/map/" + region,
        method: "GET",
      });
  }

  static getEntitiesByTag(entity: BackendEntity, tag: number, region: number) {
    return () =>
      request({
        url: "/entityByTag/" + entity + "/" + tag + "/" + region,
        method: "GET",
      });
  }
}
