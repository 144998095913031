import { Box } from "@mui/material";
import { MapContainer, TileLayer, useMap } from "react-leaflet";

import { useEffect, useState } from "react";

import L from "leaflet";
import { DraggableMarker } from "../maps/DraggableMarker";
import Geocoder from "../maps/Geocoder";
import { attribution, logistics_day } from "../maps/tiles";

const BoundsSetter = ({ boundRequest, positions }) => {
  const map = useMap();
  useEffect(() => {
    const pos = positions.filter((x) => !!x && !(Math.abs(x.lat-49.565) < 0.01 && Math.abs(x.lng-7.07) < 0.01));
    if (!pos.length) return;
    map.fitBounds(L.latLngBounds(pos), {
      maxZoom: Math.max(map.getZoom(),13),
      paddingTopLeft: [50, 50],
      paddingBottomRight: [370, 50],
    });
  }, [boundRequest]);
  return null;
};

export const POIEditor = ({
  lat,
  lng,
  setLat = (lat) => {},
  setLng = (lng) => {},
  setLatLng = (latLng) => {},
  noPOIs = false
}: {
  noPOIs?: boolean;
  lat: number;
  lng: number;
  setLat?: (lat: number) => any;
  setLatLng?: (latLng: {lat: number, lng: number;}) => any;
  setLng?: (lng: number) => any;
}) => {
  let mapLat = lat;
  let mapLng = lng;
  if (lat === 0 || lng === 0 || lat === null || lng === null) {
    mapLat = 49.565;
    mapLng = 7.07;
  }

  const [boundRequest, setBoundRequest] = useState(0);

  const forceSetBounds = () => {
    setBoundRequest(Math.random());
  };

  useEffect(() => {
      forceSetBounds();
  }, []);

  return (
    <Box sx={{ position: "relative", height: "70vh" }}>
      <MapContainer
        style={{
          width: "100%",
          height: "100%",
          border: "1px solid rgba(0,0,0,0.25)",
        }}
        center={[49.565, 7.07]}
        zoom={9}
        scrollWheelZoom={true}
      >
        <BoundsSetter
          boundRequest={boundRequest}
          positions={[{ lat: mapLat, lng: mapLng }]}
        />
        <TileLayer
          attribution={attribution}
          url={
            logistics_day
          }
        />

        <DraggableMarker
          color={"black"}
          letter={"A"}
          position={{ lat: mapLat, lng: mapLng }}
          setPosition={({ lat, lng }) => {
            setLat(lat);
            setLng(lng);
            setLatLng({lat, lng});
            forceSetBounds();
          }}
          draggable={true}
        />
      </MapContainer>
      <Box
        sx={{
          zIndex: 1200,
          position: "absolute",
          right: "20px",
          top: "20px",
          width: "350px",
        }}
      >
        <Box
          sx={{
            padding: "20px",
            border: "1px solid rgba(0,0,0,0.25)",
            background: "#f9f9f9",
          }}
        >
          <Geocoder
            noPOIs={noPOIs}
            setPosition={(plant) => {
              setLat(plant.lat);
              setLng(plant.lng);
              setLatLng({lat: plant.lat, lng: plant.lng});
              forceSetBounds();
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
