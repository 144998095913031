import ApiService from "@/api/ApiService";
import { Add, Loop } from "@mui/icons-material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import AddExternalDialog, { ExternalOrder } from "./AddExternalDialog";
import dayjs from "dayjs";

type SingleJobExternalMaterialOrder = ExternalOrder & { plant: number };

const initialExternalOrder: () => ExternalOrder = () => ({
  info: "",
  logistics: "",
  article: "",
  amount: 100,
  contact: "",
  project: "",
  number: "",
  firstLoad: "xAM",
  date: dayjs().format("YYYY-MM-DD")
});

export function DirectExternalMaterialBooking({
  plant
}: {
  plant: number;
}) {

 const queryClient = useQueryClient();

  const saveMutation = useMutation<
    SingleJobExternalMaterialOrder,
    AxiosResponse,
    SingleJobExternalMaterialOrder
  >({
    mutationFn: ApiService.createEntity("manualMaterialOrder"),
    onSuccess: (answer) => {
      queryClient.invalidateQueries({queryKey: ["getMaterialCalendar"]});
    },
  });


 
  

  const loading =
    saveMutation.isPending;

  return true ? (
    <AddExternalDialog
      save={function (order: ExternalOrder): void {
        saveMutation.mutate({...order, plant});
      }}
      OpenButton={
        ({onClick}) => <div className="directbookingButton external" onClick={!loading ? onClick : () => {}}>
          {loading ? <Loop fontSize="inherit" /> : <Add fontSize="inherit" />}
          <div className="dibutext">hinzufügen</div>
        </div>
      }
      initialState={{
        ...initialExternalOrder()
      }}
    />
  ) : null;
}
