import ApiService from "@/api/ApiService";
import { Add, AddComment, Loop } from "@mui/icons-material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import AddCommentDialog, { CalendarComment } from "./AddCommentDialog";
import { usePersistantStore } from "@/stores/global";


const initialCalendarComment: (date: string, region: number) => CalendarComment = (date, region) => ({
  color: "#8bc34a",
  text: "",
  title: "",
  deleted: false,
  region: region || null,
  start: date,
  end: date,
});

export function DirectCommentCreator({
  date
}: {
  date: string;
}) {

 const queryClient = useQueryClient();

 const region = usePersistantStore(r => r.region);

  const saveMutation = useMutation<
    CalendarComment,
    AxiosResponse,
    CalendarComment
  >({
    mutationFn: ApiService.createEntity("calendarNote"),
    onSuccess: (answer) => {
      queryClient.invalidateQueries({queryKey: ["getOverview"]});
    },
  });


 
  

  const loading =
    saveMutation.isPending;

  return true ? (
    <AddCommentDialog
      save={function (order: CalendarComment): void {
        saveMutation.mutate(order);
      }}
      OpenButton={
        ({onClick}) => <div className="directbookingButton comment" onClick={!loading ? onClick : () => {}}>
          {loading ? <Loop fontSize="inherit" /> : <AddComment fontSize="inherit" />}
        </div>
      }
      initialState={{
        ...initialCalendarComment(date, region)
      }}
    />
  ) : null;
}
