import {
  Box,
  Button,
  Paper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import dayjs from "dayjs";
import LabelWrapper from "../../root/LabelWrapper";
import { PositionEditor } from "./PositionEditor";
import {
  initialPosition,
  ProjectJob,
  ProjectPhase,
  ProjectState,
  SitePosition,
} from "../Project";
import {
  CheckCircle,
  Checklist,
  DataArraySharp,
  Delete,
  WrongLocation,
} from "@mui/icons-material";
import { Fragment, useEffect, useMemo, useState } from "react";
import ResourceTab from "./ResourceTab";
import MaterialTab from "./MaterialTab";
import TimePicker from "react-time-picker";
import ApiService from "@/api/ApiService";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import Countdown from "../elements/Countdown";
import StatusSelector from "../dialogs/StatusSelector";
import useRights from "@/shared/api/useRights";
import CopyPaster from "./CopyPaster";

export const gewerke = {
  asphalt: { id: "asphalt", name: "Asphalt" },
  bau: { id: "bau", name: "Bau" },
  sonstiges: { id: "sonstiges", name: "Sonstiges" },
};

const alertPolyfill = (title, description, options) => {
  const result = window.confirm(
    [title, description].filter(Boolean).join("\n")
  );

  if (result) {
    const confirmOption = options.find(({ style }) => style !== "cancel");
    confirmOption && confirmOption.onPress();
  } else {
    const cancelOption = options.find(({ style }) => style === "cancel");
    cancelOption && cancelOption.onPress();
  }
};

export default function DayEditor({
  project,
  saveProject,
  phaseId,
  date,
  setPhaseField,
  setProjectField,
  setField,
}: {
  phaseId: number;
  date: string;
  project: ProjectState;
  setPhaseField: (changes: Partial<ProjectPhase>) => void;
  setProjectField: (changes: Partial<ProjectState>) => void;
  setField: (changes: Partial<ProjectJob>, createBreakpoint?: boolean) => void;
}) {
  const [currentPage, setCurrentPage] = useState<
    "JOB" | "RESOURCES" | "MATERIAL" | "TRANSPORT"
  >("JOB");

  const rights = useRights();
  const isDispo = "DISPOSITION" in rights;

  const [showStatus, setShowStatus] = useState(true);

  const phase = project.phases[phaseId];
  const job = project.phases[phaseId].jobs[date];
  const [forceEnd, setForceEnd] = useState(0);

  const noChanges = !isDispo && job.status === "FINE";

  const jobId = job.id;

  const queryClient = useQueryClient();

  const query = useQuery<ProjectJob, AxiosResponse>({
    queryKey: ["getProjectJob", "" + jobId, date],
    enabled: jobId !== 0,
    queryFn: ApiService.getEntity("projectJob", jobId, true, "app"),
  });

  const endsQuery = useQuery<string, AxiosResponse>({
    queryKey: ["getBestellfrist", "" + project.region, date],
    queryFn: ApiService.getBestellfrist(project.region, date),
  });

  const tradeTemplateQuery = useQuery<TradeTemplate, AxiosResponse>({
    queryKey: ["getTradeTemplate", "" + phase.trade],
    enabled: !!phase.trade,
    queryFn: ApiService.getEntity("tradeTemplate", phase.trade ?? 0, true),
  });

  useEffect(() => {
    if (!tradeTemplateQuery.isSuccess) return;
    setShowStatus(tradeTemplateQuery.data.usesAsphaltPlanningStates);
  }, [tradeTemplateQuery.isSuccess, tradeTemplateQuery.data]);

  const mutation = useMutation<any, AxiosResponse, any>({
    mutationFn: ApiService.sendOrder(jobId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getProjectJob", "" + jobId],
      });
      saveProject();
    },
  });

  const onOrderClick = () =>
    alertPolyfill(
      "Bestellung sicher absenden?",
      "Die Bestellung kann danach nicht mehr geändert werden!",
      [
        {
          text: "Nein, abbrechen.",
          onPress: () => {},
          style: "cancel",
        },
        {
          text: "Ja, Bestellung absenden!",
          onPress: () => mutation.mutate({}),
        },
      ]
    );

  const orderStatus = useMemo(() => {
    if (!query.isSuccess) {
      let ends;

      if (endsQuery.isSuccess) {
        ends = dayjs(endsQuery.data, "YYYY-MM-DD HH:mm:ss");
      } else {
        ends = dayjs(date, "YYYY-MM-DD").subtract(1, "day").hour(11);
        if (ends.day() === 0) ends = ends.subtract(2, "days");
        if (ends.day() === 6) ends = ends.subtract(1, "day");
      }
      if (ends < dayjs()) {
        return {
          status: "PLANNING",
          ends,
        };
      }
      return null;
    } else if (!query.data.orderSentAt) {
      let ends;
      if (endsQuery.isSuccess) {
        ends = dayjs(endsQuery.data, "YYYY-MM-DD HH:mm:ss");
      } else {
        ends = dayjs(date, "YYYY-MM-DD").subtract(1, "day").hour(11);
        if (ends.day() === 0) ends = ends.subtract(2, "days");
        if (ends.day() === 6) ends = ends.subtract(1, "day");
      }
      return {
        status: "PLANNING",
        ends,
      };
    } else if (!!query.isSuccess && !!query.data.orderSentAt) {
      return {
        status: "SENT",
        subtext:
          "von " +
          query.data.orderSentBy?.firstName +
          " " +
          query.data.orderSentBy?.name +
          " " +
          dayjs
            .utc(query.data?.orderSentAt, "YYYY-MM-DD HH:mm:ss")
            .local()
            .fromNow(),
      };
    }
  }, [
    query.data?.orderSentAt,
    query.data?.orderSentBy,
    query.data?.date,
    endsQuery.data,
    date,
    forceEnd,
  ]);

  const changeable =
    !orderStatus ||
    isDispo ||
    (project.writeable &&
      orderStatus?.status === "PLANNING" &&
      orderStatus.ends.diff(dayjs(), "hour", true) > 0);

  if (phase.deleted || job.deleted) return null;

  return (
    <Fragment>
      <Paper
        sx={{
          alignItems: "center",
          display: "flex",
          gap: "10px",
        }}
        className={
          !orderStatus
            ? "orderstatus none"
            : orderStatus.status === "SENT"
            ? "orderstatus sent"
            : orderStatus.ends.diff(dayjs(), "hour", true) > 1
            ? "orderstatus running"
            : orderStatus.ends.diff(dayjs(), "hour", true) > 0
            ? "orderstatus soon"
            : "orderstatus none"
        }
      >
        <Checklist sx={{ m: "10px" }} fontSize="large" />
        <Box
          sx={{
            flex: 1,
            margin: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {!orderStatus ? (
            <Box sx={{ textAlign: "center", fontWeight: "bold" }}>
              Ohne Status (noch nicht gespeichert)
            </Box>
          ) : orderStatus?.status === "SENT" ? (
            <Box sx={{ textAlign: "center", fontWeight: "bold" }}>Bestellt</Box>
          ) : orderStatus.ends.diff(dayjs(), "hour", true) > 0 ? (
            <Box sx={{ textAlign: "center", fontWeight: "bold" }}>
              In Planung
            </Box>
          ) : null}

          {!orderStatus ? null : orderStatus?.status === "SENT" ? (
            <Box sx={{ textAlign: "center", color: "#555" }}>
              {orderStatus.subtext}
            </Box>
          ) : orderStatus.ends.diff(dayjs(), "hour", true) > 0 ? (
            <Countdown
              ended={() => setForceEnd(new Date())}
              to={orderStatus.ends}
            />
          ) : (
            <Box sx={{ textAlign: "center", color: "#555" }}>
              Bestellfrist abgelaufen
            </Box>
          )}
        </Box>
        {changeable &&
        !!orderStatus &&
        orderStatus?.status === "PLANNING" &&
        orderStatus.ends.diff(dayjs(), "hour", true) < 4 ? (
          <Box
            sx={{
              borderLeft: "1px solid #aaa",
              padding: "10px",
              display: "flex",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "rgba(0,0,0,0.2)",
              },
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              alignSelf: "stretch",
            }}
            onClick={onOrderClick}
          >
            <CheckCircle />
            <Box>Bestellung absenden</Box>
          </Box>
        ) : null}
      </Paper>
      <Paper
        sx={{
          flex: 1,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          justifyContent: "initial",
        }}
      >
        {" "}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              borderTop: "1px solid rgba(0,0,0,0.2)",
              borderTopLeftRadius: "4px",
              borderTopRightRadius: "4px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                borderLeft: "1px solid rgba(0,0,0,0.2)",
                borderRight: "1px solid #aaa",
                background: "#edf0f9",
                borderBottom: "1px solid #aaa",
                boxShadow: "inset 0px -4px 5px rgba(0,0,0,0.1)",
                px: 2,
                fontSize: "15px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              <div>
                {dayjs(date, "YYYY-MM-DD").format("ddd, DD. MMMM YYYY")}
              </div>
            </Box>
            <ToggleButtonGroup
              sx={{
                background: "#edf0f9",
                ".MuiToggleButtonGroup-firstButton": {
                  borderLeft: "none !important",
                },
                ".MuiToggleButtonGroup-groupedHorizontal.Mui-selected": {
                  background: "white !important",
                  borderBottom: "none",
                  borderLeft: "1px solid #aaa",
                },
                ".MuiToggleButtonGroup-groupedHorizontal": {
                  borderRadius: 0,
                  borderTop: "none",
                  borderBottom: "1px solid #aaa",
                  borderRight: "1px solid #aaa",
                },
              }}
              orientation="horizontal"
              exclusive
              value={currentPage}
              onChange={(_, val) => val && setCurrentPage(val)}
            >
              <ToggleButton value="JOB">Tag</ToggleButton>
              <ToggleButton value="RESOURCES">
                Personal & Maschinen
              </ToggleButton>
              <ToggleButton value="MATERIAL">Material & Logistik</ToggleButton>
            </ToggleButtonGroup>
            <Box
              sx={{
                flex: 1,
                background: "#edf0f9",
                borderRight: "1px solid rgba(0,0,0,0.2)",
                borderBottom: "1px solid #aaa",
                boxShadow: "inset 0px -4px 5px rgba(0,0,0,0.1)",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {project.writeable ? (
                <CopyPaster
                  disabled={!changeable || noChanges}
                  setPhaseField={setPhaseField}
                  setProjectField={setProjectField}
                  setField={setField}
                  job={job}
                  project={project}
                  phaseId={phaseId}
                />
              ) : null}
            </Box>
          </Box>
          {currentPage === "JOB" ? (
            <Box
              sx={{
                flex: 1,
                display: "flex",
                overflow: "hidden",
                alignItems: "stretch",
              }}
            >
              <Box
                sx={{
                  minWidth: "400px",
                  flex: 1,
                  overflow: "auto",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {showStatus ? (
                  <LabelWrapper label="Status">
                    <StatusSelector
                      currentStatus={job.status}
                      setStatus={(status) => setField({ status })}
                    />
                  </LabelWrapper>
                ) : null}
                <LabelWrapper label="Arbeitsbeginn">
                  <TimePicker
                    onChange={
                      noChanges
                        ? (startTime) => {}
                        : (startTime) =>
                            setField({
                              startTime,
                            })
                    }
                    className={!job.startTime ? "nostart2" : ""}
                    disableClock
                    value={job.startTime}
                  />
                </LabelWrapper>
                <LabelWrapper label="Dauer">
                  <ToggleButtonGroup
                    orientation="horizontal"
                    exclusive
                    value={job.duration}
                    onChange={(_, duration) =>
                      duration &&
                      setField({
                        duration,
                      })
                    }
                  >
                    <ToggleButton value="FULL">Ganztägig</ToggleButton>
                    <ToggleButton value="HALF">Halbtags</ToggleButton>
                  </ToggleButtonGroup>
                </LabelWrapper>
                <LabelWrapper label="Beschreibung">
                  <TextField
                    size="small"
                    fullWidth
                    multiline
                    value={job.comment}
                    placeholder="Beschreibung"
                    onChange={(e) =>
                      setField({
                        comment: (e.target as HTMLInputElement).value,
                      })
                    }
                  />
                </LabelWrapper>
                <div style={{ flex: 1 }} />
                {project.writeable && (isDispo || job.status === "PLANNING") ? (
                  <Button
                    sx={{ alignSelf: "flex-start", m: 1 }}
                    startIcon={<Delete />}
                    color="error"
                    variant="outlined"
                    onClick={() => {
                      const changes = {};
                      let autoFillExceptions = (
                        phase?.autoFillExceptions ?? []
                      ).slice();
                      if (!autoFillExceptions.includes(job.date)) {
                        autoFillExceptions.push(job.date);
                        changes["autoFillExceptions"] = autoFillExceptions;
                      }
                      let deletedJobs = (
                        phase?.deletedJobs ?? []
                      ).slice();
                      if (jobId !== 0){
                        deletedJobs.push(job.id);
                        changes["deletedJobs"] = deletedJobs;
                      }
                      const njobs = {...phase.jobs};
                      delete njobs[job.date];
                      changes["jobs"] = njobs;
                      setPhaseField(changes, true);
                    }}
                  >
                    Tag löschen
                  </Button>
                ) : null}
              </Box>
              <Box
                id="mapContainer"
                sx={{
                  flex: 10,
                  borderLeft: "1px solid #aaa",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ position: "relative", flex: 1 }}>
                  <PositionEditor
                    position={
                      (job.position ?? phase.position ?? project.position) ||
                      initialPosition
                    }
                    onChange={(changes: Partial<SitePosition>) =>
                      setField({
                        position: {
                          ...((job.position ??
                            phase.position ??
                            project.position) ||
                            initialPosition),
                          ...changes,
                        },
                      })
                    }
                  />
                </Box>
                {!!job.position ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: 1,
                      borderTop: "1px solid #aaa",
                      color: "#777",
                      fontStyle: "italic",
                      fontSize: "80%",
                      alignItems: "center",
                    }}
                  >
                    <Box>Angepasster Ort</Box>
                    <Button
                      size="small"
                      onClick={() =>
                        setField({
                          position: null,
                        })
                      }
                      variant="outlined"
                      startIcon={<WrongLocation />}
                    >
                      zurücksetzen
                    </Button>
                  </Box>
                ) : null}
              </Box>
            </Box>
          ) : currentPage === "RESOURCES" ? (
            <ResourceTab
              changeable={changeable && !noChanges}
              region={project.region}
              projectState={project.resourceOrders}
              setProjectState={(resourceOrders) =>
                setProjectField({ resourceOrders })
              }
              phaseState={phase.resourceOrders}
              setPhaseState={(resourceOrders) =>
                setPhaseField({ resourceOrders })
              }
              jobState={job.resourceOrders}
              setJobState={(resourceOrders) => setField({ resourceOrders })}
              jobUuid={job.uuid}
              jobId={job.id}
              jobDate={job.date}
              phaseUuid={phase.uuid}
            />
          ) : currentPage === "MATERIAL" ? (
            <MaterialTab
              projectId={project.id ?? 0}
              changeable={changeable && !noChanges}
              supplierOrders={project.supplierOrders}
              jobState={job.materialOrders}
              setJobState={(materialOrders) => setField({ materialOrders })}
            />
          ) : null}
        </Box>
      </Paper>
    </Fragment>
  );
}
