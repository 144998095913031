import ApiService from "@/api/ApiService";
import NewRangePicker from "@/shared/forms/NewRangePicker";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import dayjs from "dayjs";
import { forwardRef, useState, useImperativeHandle } from "react";

export default forwardRef(function PartDateSelector({}: {}, ref) {
  const [data, setData] = useState<
    | {
        resourceBookingId: number;
        originDate: string;
        resourceId: number;
        resourceType: "HUMAN" | "RESOURCE" | "NONE";
        startDate: string;
        jobId: number;
      }
    | false
  >(false);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);

  const queryClient = useQueryClient();

  const changeMutation = useMutation<any, AxiosResponse, any>({
    mutationFn: ApiService.changeCalendar(),
    onSettled: (answer) => {
      queryClient.invalidateQueries({ queryKey: ["getCalendar"] });
      queryClient.invalidateQueries({ queryKey: ["getOpenRequests"] });
      setData(false);
    },
  });

  useImperativeHandle(ref, () => ({
    openModal: (incomingData: typeof data) => {
      if (!incomingData) return;
      setData(incomingData);
      setStartDate(incomingData.startDate);
      setEndDate(incomingData.startDate);
    },
  }));


  return !!data ? (
    <Dialog open={true} maxWidth="sm" onClose={() => setData(false)}>
      <DialogTitle>{data.jobId === 0 ? "Eintrag löschen" : "Umdisponieren"}</DialogTitle>
      <DialogContent>
        <NewRangePicker
          small
          onChange={([startDate, endDate]) => {
            setStartDate(!!startDate ? startDate.format("YYYY-MM-DD") : null);
            setEndDate(!!endDate ? endDate.format("YYYY-MM-DD") : null);
          }}
          value={[dayjs(startDate, "YYYY-MM-DD"), dayjs(endDate, "YYYY-MM-DD")]}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setData(false)}>Abbrechen</Button>
        <LoadingButton
          variant="contained"
          color="error"
          loading={changeMutation.isPending}
          onClick={() => {
            changeMutation.mutate(
              data.resourceBookingId > 0
                ? {
                    partMoveWithRange: {
                      resourceBookingId: data.resourceBookingId,
                      jobId: data.jobId,
                      startDate,
                      endDate,
                    },
                  }
                : {
                    createBookingWithRange: {
                      resourceId: data.resourceId,
                      jobId: data.jobId,
                      resourceType: data.resourceType,
                      startDate,
                      endDate,
                    },
                  }
            );
          }}
        >
          {data.jobId === 0 ? "Löschen" : "Umdisponieren"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  ) : null;
});
