import dayjs from "dayjs";
import { create } from "zustand";

type MarkerType = "plant" | "site" | "truck" | "ghost";

interface MarkerInfo {
  type: MarkerType;
  data: {
    id?: string;
    name?: string;
    editid?: string;
    actuality?: string;
    status?: string;
  };
}

export interface LivemapState {
  selectedMarker: {
    id: string;
    type: MarkerType;
    data: MarkerInfo["data"];
    tableId: string;
  } | null;
  getDate: () => dayjs.Dayjs;

  date: string;
  setDate: (date: dayjs.Dayjs) => void;
  setSelectedMarker: (
    id: string | null,
    marker: MarkerInfo | null,
    date: string | null
  ) => void;
}

export const useLivemapStore = create<LivemapState>((set, get) => ({
  selectedMarker: null,
  getDate: () => dayjs(get().date),
  date: dayjs().format("YYYY-MM-DD"),
  setDate: (date) => set((state) => ({ date: date.format("YYYY-MM-DD"), selectedMarker: null })),
  setSelectedMarker: (id, marker, date) => {
    console.log("setting update", id, marker, date);
    if (!id) {
      set({ selectedMarker: null });
      return;
    }

    const [type, tableId] = (() => {
      if (marker.type === "plant") {
        return ["mischanlage", `${marker.data.id}@${date}`];
      } else if (marker.type === "site") {
        return ["baustelle", `${marker.data.id}@${date}`];
      } else {
        return ["lkw", `${marker.data.id}@${date}`];
      }
    })();

    set({
      selectedMarker: {
        id,
        type,
        data: marker.data,
        tableId,
      },
    });
  },
}));
