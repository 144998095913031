import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
} from "@mui/material";
import {
  GhostType,
  KGPLocation,
  ResourceBookingType,
  SpBookingType,
} from "../KGP";
import KGPResourceOrder from "./KGPResourceOrder";
import KGPResource from "./KGPResource";
import { useDrop } from "react-dnd";
import ApiService from "@/api/ApiService";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useState } from "react";
import Project from "@/components/planning/Project";
import LabelWrapper from "@/components/root/LabelWrapper";
import ManyToOneSelect from "@/shared/forms/ManyToOneSelect";
import { useGlobalStore, usePersistantStore } from "@/stores/global";

export default function KGPFreeProjectDrop() {
  const [jobSelectorHandler, setJobSelectorHandler] = useState<
    ((erfasser: number, project: number) => void) | null
  >(null);
const date = useGlobalStore((state) => state.date);
  const region = usePersistantStore((store) => store.region);

  const [project, setProject] = useState(null);
  const [erfasser, setErfasser] = useState(null);

  const queryClient = useQueryClient();

  const changeMutation = useMutation<any, AxiosResponse, ChangeData>({
    mutationFn: ApiService.changeKGPCalendar(),
    onSettled: (answer) => {
      queryClient.invalidateQueries({ queryKey: ["getCalendar"] });
      queryClient.invalidateQueries({ queryKey: ["getKGP"] });
    },
  });

  const [{ isOver }, drop] = useDrop<
    ResourceBookingType | SpBookingType | GhostType,
    void,
    { isOver: boolean }
  >(
    () => ({
      accept: ["ghost", "spBooking", "kgBooking"],
      collect: (monitor) => {
        return {
          isOver: !!monitor.isOver({ shallow: true }),
        };
      },
      drop: (item, monitor) => {
        if (monitor.didDrop()) return;
        setJobSelectorHandler(
          (
              _currentState:
                | ((erfasser: number, project: number) => void)
                | null
            ) =>
            (erfasser: number, project: number) => {
              changeMutation.mutate({
                delete:
                  item.type === "kgBooking" || item.type === "spBooking"
                    ? { type: item.type, id: item.id }
                    : null,
                create: {
                  type: "kgBooking",
                  resource: item.resource.id,
                  erfasser,
                  project,
                  date,
                },
              });
              setJobSelectorHandler(null);
              setProject(null);
              setErfasser(null);
            }
        );
      },
    }),
    []
  );

  return (
    <Box ref={drop} className={"kgpbox_adder " + (isOver ? " dropover" : "")}>
      Neuer Einsatz
      {!!jobSelectorHandler ? (
        <Dialog
          open={true}
          fullWidth
          maxWidth="md"
          onClose={() => setJobSelectorHandler(null)}
        >
          <DialogTitle>Disponieren</DialogTitle>
          <DialogContent>
            <LabelWrapper label="Projekt">
              <ManyToOneSelect<{ id: number }>
                size="small"
                showWithoutChoice
                autoSelect={false}
                remoteFilter={{ region: ["region", region], active: true }}
                entity={"project"}
                displayFunction={(o) => !!o ? o.name + " (" + o.number + ")" : "(unbekannt)"}
                currentId={project ?? 0}
                setId={setProject}
                placeholder={"Projekt"}
              />
            </LabelWrapper>
            <LabelWrapper label="Erfasser">
              <ManyToOneSelect<{ id: number }>
                size="small"
                showWithoutChoice
                autoSelect={false}
                remoteFilter={{
                  region: ["region", region],
                  baumobilErfasser: true,
                }}
                entity={"staffMember"}
                displayFunction={(o) =>
                  !!o ? o.name + ", " + o.firstName : "(unbekannt)"
                }
                currentId={erfasser ?? 0}
                setId={setErfasser}
                placeholder={"Erfasser"}
              />
            </LabelWrapper>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setJobSelectorHandler(null)}>
              Abbrechen
            </Button>
            <Button
             variant="contained"
              disabled={!erfasser || !project}
              onClick={() => jobSelectorHandler(erfasser, project)}
            >
              Hinzufügen
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </Box>
  );
}
