import { Close } from "@mui/icons-material";
import { Box, Typography, Divider, IconButton } from "@mui/material";
import { ReactNode } from "react";
import { useLivemapStore } from "./useLivemapStore";

interface SidebarProps {
  open: boolean;
  title?: string;
  description?: string;
  children?: ReactNode;
}

const Sidebar: React.FC<SidebarProps> = ({
  open,
  title,
  description,
  children,
}) => {
  if (!open) return null;

  const setSelectedMarker = useLivemapStore(s => s.setSelectedMarker);
  return (
    <Box
      sx={{
        flex: 1,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        bgcolor: "background.paper",
        borderLeft: 1,
        borderColor: "divider",
        boxShadow: 3,
        overflowY: "auto",
      }}
    >
      <Box p={2} display={"flex"} gap={1}>
        <Box flex={1}>
        {title && (
          <Typography variant="h6">
            {title}
          </Typography>
        )}
        {description && (
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        )}
        </Box>
        <IconButton onClick={() => setSelectedMarker(null)} sx={{alignSelf: "flex-start"}} size="small"><Close fontSize="inherit" /></IconButton>
      </Box>

      <Box p={2} pt={0} flex={1}>
        {children}
      </Box>
    </Box>
  );
};

export default Sidebar;
