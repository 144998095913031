import dayjs from "dayjs";
import { CalJob } from "../calendarElements/InnerCalendar";
import { WithOrders } from "./CalendarListView";
import {
  AccountBalance,
  AccountCircle,
  ChatBubble,
  Checklist,
  LocalShipping,
  ManageSearch,
  MyLocation,
  Schedule,
  Send,
} from "@mui/icons-material";
import { Fragment } from "react/jsx-runtime";
import { useMemo } from "react";
import { createDeliveryText } from "@/components/planning/editors/MaterialTab";
import { Button, Tooltip } from "@mui/material";
import { DirectBooking } from "./DirectBooking";
import { DirectMaterialBooking } from "./DirectMaterialBooking";
import { DirectMaterialEditor } from "./DirectMaterialEditor";
import { TransState } from "./MixingPlantView";

function parseErrorCode(errorCode: number | null): string[] {
  const errorMessages: { [key: number]: string } = {
    1: "Baustelle nicht gefunden", // Construction site not found
    2: "Erfasser nicht gefunden", // Recorder not found
    4: "Mitarbeiter nicht gefunden", // Employee not found
    8: "Fahrzeug nicht gefunden", // Vehicle not found
    16: "Gerät nicht gefunden", // Device not found
    32: "Mitarbeiter Beginnzeit nicht gefunden", // Employee start time not found
    64: "Mitarbeiter Endzeit nicht gefunden", // Employee end time not found
    128: "Fahrer nicht gefunden", // Driver not found
    256: "Maschinenführer nicht gefunden", // Machine operator not found
    512: "Allgemeiner Fehler", // General error
  };

  if (errorCode === null) {
    return [];
  }

  if (errorCode === 0) {
    return [];
  }

  const messages: string[] = [];
  for (const [bit, message] of Object.entries(errorMessages)) {
    if ((errorCode & Number(bit)) !== 0) {
      messages.push(message);
    }
  }

  return messages;
}

const XMember = ({ data, last }: { data: any; last: boolean }) => {
  const content = (
    <span
      className={
        "xstatus xstatus-" +
        (!!data.interfaceStatus
          ? "error"
          : data.interfaceStatus === 0
          ? "success"
          : "none")
      }
    >
      {(data.type === "HUMAN"
        ? data.staffMember?.name
        : data.resource?.name + " (" + data.resource?.number + ")") +
        (!last ? "; " : "")}
    </span>
  );

  if (!data.interfaceStatus) return content;
  const messages = parseErrorCode(data.interfaceStatus);
  return (
    <Tooltip
      title={
        <Fragment>
          {messages.map((x) => (
            <div>{x}</div>
          ))}
        </Fragment>
      }
    >
      {content}
    </Tooltip>
  );
};

const groupBookings = (bookings: any[]) => {
  const groups: { [tag: string]: any[] } = {};
  for (const b of bookings) {
    const res = b.type === "HUMAN" ? b.staffMember : b.resource;
    if (!res || b.deleted) continue;
    let tag = res.tags
      .filter((x) => ["WISHABLE", "REQUESTABLE"].includes(x.calendarType))
      .map((x) => x.name)
      .sort()
      .join(", ");
    if (tag.length === 0)
      tag = res.tags
        .filter((x) =>
          ["WISHABLE", "REQUESTABLE", "CALENDAR_ONLY"].includes(x.calendarType)
        )
        .map((x) => x.name)
        .sort()
        .join(", ");
    if (tag.length === 0)
      tag = b.type === "HUMAN" ? "Mitarbeiter" : "Sonstige Geräte";
    if (!(tag in groups)) groups[tag] = [];
    groups[tag].push(b);
  }
  return Object.entries(groups).sort((a, b) =>
    a[1][0].type === b[1][0].type
      ? a[0].localeCompare(b[0])
      : b[1][0].type.localeCompare(a[1][0].type)
  );
};

export default function OrderInformation({
  data,
  showMaterial = true,
  showResources = true,
  withDirectBooking = false,
  showInfo = true,
  showOrders = true,
  setCalendar,
  isTransDispo = false,
  setModalOpen,
}: {
  data: CalJob & WithOrders;
  showMaterial: boolean;
  withDirectBooking?: boolean;
  showResources: boolean;
  showOrders: boolean;
  isTransDispo: boolean;
  setModalOpen: () => void;
}) {
  const comments = [
    [data.comment, ""],
    [data.phase.comment, " zur Bauphase"],
    [data.phase.project.comment, " zum Projekt"],
  ]
    .map(([x, y]) => [x.trim(), y])
    .filter(([x, y]) => !!x);

  const gb = showResources
    ? groupBookings(
        data.resourceBookingsAndOrders.resourceBookings.slice() ?? []
      )
    : [];

  return (
    <div className="orderInformationWrapper">
      {showInfo ? (
        <div className="oIDataWrapper">
          <div className="oIDataLine">
            <div className="oIDataIcon">
              <Checklist fontSize="inherit" />
            </div>
            <div className="oIDataTextWrapper">
              <div className="oIDataDescription">Bestellstatus</div>
              <div className="oIDataText">
                {!data.orderSentAt
                  ? "noch nicht bestellt - Planung"
                  : "bestellt von " +
                    data.orderSentBy.firstName +
                    " " +
                    data.orderSentBy.name +
                    " " +
                    dayjs
                      .utc(data.orderSentAt, "YYYY-MM-DD HH:mm:ss")
                      .local()
                      .fromNow()}
              </div>
            </div>
          </div>

          <div className="oIDataLine">
            <div className="oIDataIcon">
              <Schedule fontSize="inherit" />
            </div>
            <div className="oIDataTextWrapper">
              <div className="oIDataDescription">Beginn</div>
              <div className="oIDataText">
                {(!!data.startTime
                  ? "ab " + data.startTime + " Uhr"
                  : !!data.firstUnload && !data.firstUnload.startsWith("x")
                  ? "Erster LKW kommt um " + data.firstUnload + " Uhr"
                  : "Beginn nach Absprache") +
                  (data?.duration === "HALF" ? " - halbtags" : "")}
              </div>
            </div>
          </div>

          {!!data.phase.project.manager ? (
            <div className="oIDataLine">
              <div className="oIDataIcon">
                <AccountCircle fontSize="inherit" />
              </div>
              <div className="oIDataTextWrapper">
                <div className="oIDataDescription">Bauleiter</div>
                <div className="oIDataText">
                  {data.phase.project.manager.firstName +
                    " " +
                    data.phase.project.manager.name}
                </div>
              </div>
            </div>
          ) : null}

          {!!data.phase.project.client ? (
            <div className="oIDataLine">
              <div className="oIDataIcon">
                <AccountBalance fontSize="inherit" />
              </div>
              <div className="oIDataTextWrapper">
                <div className="oIDataDescription">Kunde</div>
                <div className="oIDataText">
                  {data.phase.project.client.name}
                </div>
              </div>
            </div>
          ) : null}

          {comments.map(([comment, subtitle], i) => (
            <div key={"komm" + i} className="oIDataLine">
              <div className="oIDataIcon">
                <ChatBubble fontSize="inherit" />
              </div>
              <div className="oIDataTextWrapper">
                <div className="oIDataDescription">Kommentar{subtitle}</div>
                <div className="oIDataText">{comment}</div>
              </div>
            </div>
          ))}
        </div>
      ) : null}
      {showMaterial ? (
        <Fragment>
          <div className="oIDataHeader">
            <div className="oIttt">Material & Logistik</div>
            {withDirectBooking ? (
              <DirectMaterialBooking
                job={data.id}
                date={data.date}
                region={data.phase.project.region}
                project={data.phase.project.id}
              />
            ) : null}
          </div>
          {data.materialOrders.length === 0 ? (
            <div className="oIZero">Keine Bestellung</div>
          ) : (
            <div className="oIMaterialWrapper">
              {data.materialOrders
                .filter((x) => !x.deleted)
                .map((order) => (
                  <div key={order.id} className="oImaterialLine">
                    <div className="oIMaterialComponent oibutton">
                    <TransState
                        state={order.transportStatus}
                        id={order.id}
                        readOnly={!isTransDispo}
                      />
                    </div>
                    <div className="oIMaterialComponent oitime">
                      <Schedule fontSize="inherit" />
                      {!order.firstUnload
                        ? "anschließend"
                        : !order.firstUnload.startsWith("x")
                        ? order.firstUnload
                        : order.firstUnload === "xAM"
                        ? "vormittags"
                        : order.firstUnload === "xPM"
                        ? "nachmittags"
                        : "???"}
                    </div>
                    <div className="oIMaterialComponent oiarticle">
                      <div>
                        {!!order.article
                          ? order.amount +
                            " " +
                            order.article.unit +
                            " " +
                            order.article.name +
                            " von " +
                            order.article.plant.name +
                            " (" +
                            order.article.number +
                            ")"
                          : "nur Baustellendienst"}
                      </div>
                      {order.additionalArticles.map((x) => (
                        <div key={x.id}>
                          {x.amount +
                            " " +
                            x.article.unit +
                            " " +
                            x.article.name +
                            " (" +
                            x.article.number}
                        </div>
                      ))}
                    </div>

                    {((uuu) =>
                      uuu.length > 0 ? (
                        <div className="oIMaterialComponent oitruck">
                          <LocalShipping fontSize="inherit" />
                          {uuu}
                        </div>
                      ) : null)(
                      createDeliveryText(order.transportInformation, order.takt)
                    )}

                    <div className="oIMaterialComponent oicomment">
                      {order.comment.length > 0 ? (
                        <Fragment>
                          <ChatBubble fontSize="inherit" />
                          {[
                            !order.position &&
                            !!order.article &&
                            !order.article.bauhof
                              ? "Unbepreister Artikel!"
                              : "",
                            order.easyTarget === "LAGER"
                              ? "Anlieferung zum Lagerplatz!"
                              : "",
                            order.comment,
                          ]
                            .filter((x) => x.length > 0)
                            .join(" - ")}
                        </Fragment>
                      ) : null}
                    </div>
                    
                    {withDirectBooking ? (
                      <DirectMaterialEditor
                        order={order}
                        job={data.id}
                        setModalOpen={setModalOpen}
                        date={data.date}
                        region={data.phase.project.region}
                        project={data.phase.project.id}
                      />
                    ) : null}

                    
                  </div>
                ))}
            </div>
          )}
        </Fragment>
      ) : null}
      {showResources || showOrders ? (
        <Fragment>
          <div className="oIDataHeader">
            <div className="oIttt">Geräte & Mitarbeiter</div>
            {withDirectBooking ? (
              <DirectBooking
                job={data.id}
                date={data.date}
                region={data.phase.project.region}
                project={data.phase.project.id}
              />
            ) : null}
          </div>
          {data.resourceBookingsAndOrders.resourceOrders.length + gb.length ===
          0 ? (
            <div className="oIZero">Keine Bestellung</div>
          ) : (
            <div className="oIResourceWrapper">
              {showOrders &&
              (data.resourceBookingsAndOrders.resourceOrders.filter(
                (x) => !x.deleted
              ).length > 0 ||
                withDirectBooking) ? (
                <div className="oiOpen">
                  {data.resourceBookingsAndOrders.resourceOrders
                    .filter((x) => !x.deleted)
                    .map((o) => (
                      <div
                        key={o.id}
                        className="oiOpenResource"
                        onClick={
                          !!o.rejection
                            ? undefined
                            : () =>
                                setCalendar(
                                  o.type === "CREW"
                                    ? "CREWS"
                                    : o.type === "RESOURCE"
                                    ? "RESOURCES"
                                    : "STAFF",
                                  o.tag?.id ?? null,
                                  dayjs(data.date, "YYYY-MM-DD")
                                )
                        }
                      >
                        <div
                          className="oiResTitle"
                          style={
                            !!o.rejection
                              ? { textDecoration: "line-through" }
                              : {}
                          }
                        >
                          {o.open + "x "}
                          {o.type === "CREW" ? "Kolonne " : ""}
                          {o.tag?.name}
                        </div>
                        {o.comment.length > 0 ? (
                          <div className="oiOpenResComment">{o.comment}</div>
                        ) : null}
                        {!!o.rejection ? (
                          <div className="oiOpenResComment">
                            {o.rejection.comment} (
                            {o.rejection.createdBy.firstName}{" "}
                            {o.rejection.createdBy.name})
                          </div>
                        ) : null}
                      </div>
                    ))}
                </div>
              ) : null}
              {gb.map(([title, orders]) => (
                <div key={title} className="oiRealRes">
                  <div className="oiRealResTitle">{title}</div>
                  <div className="oiResNames">
                    {orders
                      .sort((a, b) =>
                        a.type !== b.type
                          ? a.type.localeCompare(b.type)
                          : a.type === "RESOURCE"
                          ? (a.resource?.name ?? "Z").localeCompare(
                              b.resource?.name ?? "Z"
                            )
                          : a.isLeader && !b.isLeader
                          ? -1
                          : !a.isLeader && b.isLeader
                          ? 1
                          : (a.staffMember?.name ?? "Z").localeCompare(
                              b.staffMember?.name ?? "Z"
                            )
                      )
                      .map((o, i, f) => (
                        <XMember
                          key={o.id}
                          data={o}
                          last={i === f.length - 1}
                        />
                      ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </Fragment>
      ) : null}
    </div>
  );
}
