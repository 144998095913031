import React, { useEffect, useMemo, useState } from "react";
import { AxiosResponse } from "axios";
import BaseApiService from "../api/BaseApiService";
import { useQuery } from "@tanstack/react-query";
import {
  Autocomplete,
  AutocompleteProps,
  Box,
  CircularProgress,
  Skeleton,
  TextField,
} from "@mui/material";
import { HMobiledata } from "@mui/icons-material";

interface BasicEntity {
  id: number;
  name: string;
}

export default function ManyToOneSelect<T extends BasicEntity>({
  entity,
  currentId,
  setId,
  filter,
  favorites=[],
  version=undefined,
  favoritesTitle="",
  placeholder = "",
  remoteFilter,
  remoteFilterReady = true,
  fullWidth = true,
  autoSelect = false,
  sx = {},
  displayFunction = o => !!o ? o.name + ("firstName" in o ? ", "+o.firstName : "") + ("number" in o ? " ("+o.number+")" : "") : "",
  filterReady = true,
  label = "",
  disabled = false,
  size="medium",
  sideEffectOnlyOnChange=false,
  sideEffect,
  clearIcon=undefined,
  showWithoutChoice = false,
}: {
  entity: string;
  version?: string;
  favorites?: number[],
  favoritesTitle?: string;
  sx?: any;
  autoSelect?: boolean;
  clearIcon?: any;
  fullWidth?: boolean;
  currentId: number;
  disabled: boolean;
  sideEffectOnlyOnChange: boolean;
  filterReady?: boolean;
  remoteFilterReady?: boolean;
  size?: "medium" | "small";
  filter?: (x: T) => boolean;
  displayFunction?: (x: T | undefined) => string;
  remoteFilter?: {[field: string]: string | [string, any]},
  setId: (newId: number) => any;
  label?: string;
  placeholder?: string;
  sideEffect?: (selectedObject: T | null) => any;
  showWithoutChoice?: boolean;
}) {
  const query = useQuery<T[], AxiosResponse>({
    queryKey: !!remoteFilter ? ["get" + entity[0].toUpperCase() + entity.slice(1) + "s",JSON.stringify(remoteFilter)]: ["get" + entity[0].toUpperCase() + entity.slice(1) + "s"],
    enabled: remoteFilterReady,
    queryFn: !!remoteFilter ? BaseApiService.getEntitiesWithFilter(entity, remoteFilter, version) : BaseApiService.getEntities(entity, undefined, version),
  });

  const [names, setNames] = useState(new Map<number, T>());

  const [humanTriggered, setHumanTriggered] = useState(false);


  useEffect(() => {
    if (!!sideEffect && names.size > 0 && (!sideEffectOnlyOnChange || humanTriggered)) sideEffect(currentId > 0 && names.has(currentId) ? (names.get(currentId)??null) : null);
    setHumanTriggered(false);
  },[currentId, names]);

  useEffect(() => {
    if (!query.isSuccess || !filterReady) return;
    const names = new Map<number, T>();
    for (let o of query.data) {
      if (!!filter && !filter(o)) continue;
      names.set(o.id, o);
    }
    if (names.size === 1) {
      const x = names.values().next().value;
      if (currentId !== x.id){
        console.log("CHANGE 1");
        setId(x.id);
        setHumanTriggered(true);
      }
    } else if (currentId > 0 && !names.has(currentId)){
      console.log("CHANGE 2");
      setId(0);
      setHumanTriggered(true);
    } else if (autoSelect && names.size > 1 && currentId === 0){
      const x = names.values().next().value;
      console.log("CHANGE 3");
      setId(x.id);
      setHumanTriggered(true);
    }
    setNames(names);
  }, [query.data, filterReady, filter]);

  return !query.isSuccess ? (
    <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
  ) : names.size === 1 && !!currentId && !showWithoutChoice ? null : (
    <Autocomplete
      sx={{ mt: "10px", mb: "6px", ...sx }}
      value={!!currentId ? currentId : null}
      onChange={(_event: any, newValue: number | null) => {
        setHumanTriggered(true);
        setId(!!newValue ? newValue : 0);
      }}
      clearIcon={clearIcon}
      size={size}
      fullWidth={fullWidth}
      options={Array.from(names.values())
        .sort((a: T, b: T) => {
          const fa = favorites.includes(a.id);
          const fb = favorites.includes(b.id);
          return fa && !fb ? -1 : !fa && fb ? 1 : -displayFunction(b).localeCompare(displayFunction(a));
        })
        .map((x: T) => x.id)}
      groupBy={(id: number) =>
        favorites.includes(id) ? favoritesTitle : displayFunction(names.get(id)).substring(0, 1).toUpperCase()
      }
      disabled={disabled}
      getOptionLabel={(id: number) => displayFunction(names.get(id))}
      renderInput={(params: any) => <TextField {...params} placeholder={!!placeholder ? placeholder : label} label={label} />}
    />
  );
}
