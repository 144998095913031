import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Fragment, useCallback, useState } from "react";
import LabelWrapper from "../root/LabelWrapper";

import dayjs from "dayjs";
import ColorPicker from "@/shared/forms/ColorPicker";
import NewRangePicker from "@/shared/forms/NewRangePicker";
import ManyToOneSelect from "@/shared/forms/ManyToOneSelect";
import { usePersistantStore } from "@/stores/global";

export interface CalendarComment {
  color: string;
  text: string;
  title: string;
  deleted?: boolean;
  region: null | number;
  start: string |null;
  end: string |null;
}

export default function AddCommentDialog({
  save,
  OpenButton,
  initialState,
}: {
  save: (order: CalendarComment) => void;
  OpenButton: any;
  initialState: CalendarComment;
}) {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState<CalendarComment>(initialState);
  const region = usePersistantStore(s => s.region);

  const close = useCallback(() => {
    setOpen(false);
  }, []);

  const openAndSet = useCallback(() => {
    setState(initialState);
    setOpen(true);
  }, [initialState]);

  const valid = !!state.start && !!state.end;

  return (
   <Fragment>
      <OpenButton onClick={openAndSet} />
      {open ? <Dialog open={open} maxWidth="xl" fullWidth onClose={close}>
        <Fragment>
          <DialogTitle
            sx={{
              p: 1,
              px: 2,
              background: "#002196",
              color: "white",
              borderBottom: "1px solid rgba(255,255,255,0.5)",
            }}
          >
            Kalendereintrag
          </DialogTitle>
          <DialogContent
            sx={{
              p: 0,
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
             <LabelWrapper label="Zeitraum">
            <NewRangePicker small 
                            onChange={([startDate, endDate]) => {
                              setState(s => ({
                                ...s,
                                start: !!startDate ? startDate.format("YYYY-MM-DD") : null,
                                end: !!endDate ? endDate.format("YYYY-MM-DD") : null
                              }));
                            }}
                            value={[
                              dayjs(state.start,"YYYY-MM-DD"),
                              dayjs(state.end,"YYYY-MM-DD"),
                            ]} />
                            </LabelWrapper>
            
            <LabelWrapper label="Titel">
              <div style={{display: "flex", alignItems: "stretch"}}><TextField
                size="small"
                fullWidth
                value={state.title}
                placeholder="Titel"
                onChange={(e) =>
                  setState((s) => ({
                    ...s,
                    title: (e.target as HTMLInputElement).value,
                  }))
                }
              />
              <ColorPicker value={state.color} onChange={function (color: string) {
                setState((s) => ({
                  ...s,
                  color
                }))
              } } size={"small"} /></div>
            </LabelWrapper>
            <LabelWrapper label="Beschreibung">
              <TextField
                size="small"
                fullWidth
                value={state.text}
                placeholder="Beschreibung"
                multiline
                onChange={(e) =>
                  setState((s) => ({
                    ...s,
                    text: (e.target as HTMLInputElement).value,
                  }))
                }
              />
            </LabelWrapper>
            {!region ? <LabelWrapper label="Niederlassung">
              <ManyToOneSelect entity={"region"} placeholder="Bitte wählen" currentId={state.region} disabled={false} sideEffectOnlyOnChange={false} setId={function (region: number) {
                setState((s) => ({
                  ...s,
                  region: region || null
                }))
              } } />
            </LabelWrapper> : null}
          </DialogContent>
          <DialogActions sx={{ borderTop: "1px solid #aaa" }}>
            <Button onClick={close}>Abbrechen</Button>
            <Button
              variant="outlined"
              color="primary"
              disabled={!valid}
              onClick={() => {
                save(state);
                close();
              }}
            >
              OK
            </Button>
          </DialogActions>
        </Fragment>
      </Dialog> : null}
    </Fragment>
  );
}
