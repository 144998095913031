import ApiService from "@/api/ApiService";
import {
  PostAdd,
  Error as ErrorIcon,
  Add,
  Delete,
  Factory,
  Warning,
  Info,
} from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import {
  forwardRef,
  Fragment,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDropzone } from "react-dropzone";
import LabelWrapper from "../../root/LabelWrapper";
import { uniqueId } from "lodash";
import NewRangePicker from "@/shared/forms/NewRangePicker";
import ManyToOneSelect from "@/shared/forms/ManyToOneSelect";
import { SupplierOrder } from "../Project";

const baseStyle = {
  flex: 1,
  marginTop: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  backgroundColor: "#eaeaea",
};

const acceptStyle = {
  backgroundColor: "rgb(223, 255, 205)",
};

const rejectStyle = {
  backgroundColor: "rgb(255, 205, 205)",
};

interface ParsedArticle {
  article_number: string;
  article_name: string;
  unit: string;
  delivery_type: string;
  price: string;
  amount: string;
  plant: string;
  plantId: number;
  id: string;
  deleted: boolean;
  actualId: 0;
}

export interface ParsedState {
  supplier_name: string;
  id: number;
  supplierId: number;
  valid_from: string | null;
  valid_until: string | null;
  document_number: string;
  articles: ParsedArticle[];
}

export default forwardRef(
  ({ save }: { save: (data: ParsedState) => void }, ref) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [open, setOpen] = useState(false);
    const [possibleMixingPlants, setPossibleMixingPlants] = useState<
      { id: number; name: string }[]
    >([]);
    const [parsedState, setParsedState] = useState<
      ParsedState | { supplierId: number; supplier_name: string; id: number }
    >({ supplierId: 0, supplier_name: "", id: 0 });

    useImperativeHandle(ref, () => ({
      edit: (order: SupplierOrder) => {
        setParsedState({
          supplier_name: order.supplier.name,
          id: order.id,
          supplierId: order.supplier.id,
          valid_from: order.validFrom,
          valid_until: order.validUntil,
          document_number: order.number,
          articles: order.positions.map((pos) => ({
            article_number: pos.article.number,
            article_name: pos.article.name,
            unit: pos.article.unit || "t",
            delivery_type: pos.deliveryType.name,
            price: pos.price || "",
            amount: ""+(pos.amount || ""),
            plant: pos.article.plantName,
            plantId: pos.article.plant,
            id: uniqueId(),
            deleted: !!pos.deleted,
            actualId: pos.id,
          })),
        });
        setOpen(true);
      },
    }));

    const addPosition = () => {
      if (!parsedState || !("articles" in parsedState)) return;
      const articles = parsedState.articles.filter((t) => !t.deleted);
      articles.push({
        delivery_type: "",
        article_number: "",
        article_name: "",
        price: "",
        unit: "",
        amount: "",
        plant: "",
        plantId: 0,
        actualId: 0,
        id: uniqueId(),
        deleted: false,
      });
      setParsedState({
        ...parsedState,
        articles,
      });
    };

    const deletePosition = (uuid: string) => {
      if (!parsedState || !("articles" in parsedState)) return;
      setParsedState({
        ...parsedState,
        articles: parsedState.articles.map((t) => ({
          ...t,
          deleted: t.deleted || t.id === uuid,
        })),
      });
    };

    const setPositionField = (
      uuid: string,
      key: keyof ParsedArticle,
      value: string
    ) => {
      if (!parsedState || !("articles" in parsedState)) return;
      setParsedState((ps) => ({
        ...ps,
        articles: (ps?.articles ?? []).map((t) => ({
          ...t,
          [key]: t.id === uuid ? value : t[key],
        })),
      }));
    };

    const onDrop = useCallback(
      async (acceptedFiles) => {
        // Do something with the files
        setIsLoading(true);
        try {
          const result = await ApiService.parseOrderConfirmation(
            acceptedFiles[0],
            possibleMixingPlants.map((x) => x.name)
          );
          if (!("supplier_name" in result)) {
            throw "Invalid";
          }
          result.supplier_name = parsedState.supplier_name;
          result.articles = result.articles.map((a: ParsedArticle) => ({
            article_number: !!a.article_number ? a.article_number : "",
            article_name: !!a.article_name ? a.article_name : "",
            unit: !!a.unit ? a.unit : "t",
            delivery_type: !!a.delivery_type ? a.delivery_type : "",
            price:
              !!a.price &&
              (typeof a.price == "number" || !isNaN(parseFloat(a.price)))
                ? parseFloat(a.price).toFixed(2).replace(/\./, ",")
                : "",
            amount:
              !!a.amount &&
              (typeof a.amount == "number" || !isNaN(parseInt(a.amount)))
                ? a.amount + ""
                : "",
            plantId:
              possibleMixingPlants.find(
                (x) => x.name === (!!a.plant ? a.plant : "")
              )?.id ?? 0,
            plant: "",
            id: uniqueId(),
            deleted: false,
          }));
          setParsedState((s) => ({ ...s, ...result }));
          setIsLoading(false);
        } catch (e) {
          setIsLoading(false);
          setIsError(true);
          await new Promise((resolve) => setTimeout(resolve, 3000));
          setIsError(false);
        }
      },
      [possibleMixingPlants]
    );
    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isFocused,
      isDragAccept,
      isDragReject,
    } = useDropzone({
      onDrop,
      accept: { "application/pdf": [] },
      disabled: !parsedState.supplierId,
    });

    const close = useCallback(() => {
      setOpen(false);
      setParsedState({ supplierId: 0, id: 0, supplier_name: "" });
    }, []);

    const setField = (changes: Partial<ParsedState>) => {
      if (!parsedState) return;
      const newState = { ...parsedState, ...changes };
      setParsedState(newState);
    };

    const style = useMemo(
      () => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      }),
      [isFocused, isDragAccept, isDragReject]
    );

    console.log(parsedState);
    const valid =
      !!parsedState &&
      "articles" in parsedState &&
      parsedState.supplier_name !== "" &&
      parsedState.document_number !== "" &&
      !!parsedState.valid_from &&
      !!parsedState.valid_until &&
      parsedState.articles.every(
        (article) =>
          article.deleted ||
          (!!article.article_name &&
            !!article.article_number &&
            !!article.plantId)
      );

    return (
      <Fragment>
        <Button startIcon={<Add />} onClick={() => setOpen(true)}>
          AB hinzufügen
        </Button>
        <Dialog open={open} maxWidth="xl" fullWidth onClose={close}>
          {!!parsedState && "articles" in parsedState ? (
            <Fragment>
              <DialogTitle
                sx={{
                  p: 1,
                  px: 2,
                  background: "#002169",
                  color: "white",
                  borderBottom: "1px solid rgba(255,255,255,0.5)",
                }}
              >
                Auftragsbestätigung: Manuelle Kontrolle
              </DialogTitle>
              <DialogContent
                sx={{
                  p: 0,
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box>
                  <LabelWrapper label="Auftragsnummer*">
                    <TextField
                      size="small"
                      fullWidth
                      value={parsedState.document_number}
                      placeholder="Auftragsnummer"
                      onChange={(e) =>
                        setField({
                          document_number: (e.target as HTMLInputElement).value,
                        })
                      }
                    />
                  </LabelWrapper>
                  <LabelWrapper label="Gültigkeitszeitraum*">
                    <NewRangePicker
                      small
                      onChange={([startDate, endDate]) =>
                        setField({
                          valid_from: !!startDate
                            ? startDate.format("YYYY-MM-DD")
                            : null,
                          valid_until: !!endDate
                            ? endDate.format("YYYY-MM-DD")
                            : null,
                        })
                      }
                      value={[
                        !!parsedState.valid_from
                          ? dayjs(parsedState.valid_from)
                          : null,
                        !!parsedState.valid_until
                          ? dayjs(parsedState.valid_until)
                          : null,
                      ]}
                    />
                  </LabelWrapper>
                </Box>
                <TableContainer sx={{ flex: 1 }}>
                  <Table size="small" stickyHeader>
                    <TableHead sx={{ background: "#eee" }}>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Artikelnummer*
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Artikelname*
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Lieferwerk*
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Zufuhrart
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>Menge</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Einheit
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>Preis</TableCell>
                        <TableCell sx={{}}>
                          <IconButton
                            size="small"
                            onClick={() => addPosition()}
                          >
                            <Add />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {parsedState.articles
                        .filter((x) => !x.deleted)
                        .map((x) => (
                          <TableRow>
                            <TableCell align="right">
                              <TextField
                                size="small"
                                fullWidth
                                variant="outlined"
                                value={x.article_number}
                                onChange={(e) =>
                                  setPositionField(
                                    x.id,
                                    "article_number",
                                    (e.target as HTMLInputElement).value
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                size="small"
                                fullWidth
                                variant="outlined"
                                value={x.article_name}
                                onChange={(e) =>
                                  setPositionField(
                                    x.id,
                                    "article_name",
                                    (e.target as HTMLInputElement).value
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <ManyToOneSelect
                                showWithoutChoice
                                autoSelect
                                size="small"
                                sx={{ minWidth: 200 }}
                                clearIcon={null}
                                entity={"plant"}
                                currentId={x.plantId}
                                remoteFilterReady={!!parsedState.supplierId}
                                disabled={false}
                                sideEffectOnlyOnChange={false}
                                sideEffect={(plant) =>
                                  setPositionField(
                                    x.id,
                                    "plant",
                                    plant?.name || ""
                                  )
                                }
                                remoteFilter={{
                                  supplier: [
                                    "supplier",
                                    parsedState.supplierId,
                                  ],
                                }}
                                setId={(e) =>
                                  setPositionField(x.id, "plantId", e)
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                size="small"
                                fullWidth
                                variant="outlined"
                                value={x.delivery_type}
                                onChange={(e) =>
                                  setPositionField(
                                    x.id,
                                    "delivery_type",
                                    (e.target as HTMLInputElement).value
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell align="right">
                              <TextField
                                size="small"
                                fullWidth
                                variant="outlined"
                                value={x.amount}
                                onChange={(e) =>
                                  setPositionField(
                                    x.id,
                                    "amount",
                                    (e.target as HTMLInputElement).value
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell align="right">
                              <TextField
                                size="small"
                                fullWidth
                                variant="outlined"
                                value={x.unit}
                                onChange={(e) =>
                                  setPositionField(
                                    x.id,
                                    "unit",
                                    (e.target as HTMLInputElement).value
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell align="right">
                              <TextField
                                size="small"
                                fullWidth
                                variant="outlined"
                                value={x.price}
                                onChange={(e) =>
                                  setPositionField(
                                    x.id,
                                    "price",
                                    (e.target as HTMLInputElement).value
                                  )
                                }
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      €
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </TableCell>
                            <TableCell sx={{}}>
                              <IconButton
                                size="small"
                                onClick={() => deletePosition(x.id)}
                              >
                                <Delete />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </DialogContent>
              <DialogActions sx={{ borderTop: "1px solid #aaa" }}>
                <Button onClick={close}>Abbrechen</Button>
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={!valid}
                  onClick={() => {
                    save(parsedState);
                    close();
                  }}
                >
                  OK
                </Button>
              </DialogActions>
            </Fragment>
          ) : (
            <Fragment>
              <DialogTitle
                sx={{
                  p: 1,
                  px: 2,
                  background: "#002169",
                  color: "white",
                  borderBottom: "1px solid rgba(255,255,255,0.5)",
                }}
              >
                Auftragsbestätigung: Datei einlesen
              </DialogTitle>
              <DialogContent>
                <ManyToOneSelect
                  version="parser"
                  entity={"supplier"}
                  placeholder="Bitte wählen..."
                  label="Lieferant"
                  currentId={parsedState.supplierId}
                  displayFunction={(x) => x.name}
                  sideEffect={(obj) => {
                    setField({
                      supplier_name: obj?.name || "",
                    });
                    setPossibleMixingPlants(
                      (obj?.plants || []).sort((a, b) =>
                        a.name.localeCompare(b.name)
                      )
                    );
                  }}
                  disabled={false}
                  sideEffectOnlyOnChange={false}
                  setId={(supplierId) =>
                    setField({
                      supplierId,
                    })
                  }
                />
                {!!parsedState.supplierId ? (
                  <Alert severity="info">
                    <AlertTitle>
                      Der ausgewählte Lieferant verfügt über folgende
                      Lieferwerke:
                    </AlertTitle>
                    {possibleMixingPlants.map((x) => x.name).join(", ")}
                    <Box sx={{ fontWeight: "bold", mt: 1 }}>
                      Falls die Auftragsbestätigung Artikel aus anderen Werken
                      enthält, muss das Werk zuerst in den Stammdaten angelegt
                      werden!
                    </Box>
                  </Alert>
                ) : null}
                <Paper variant="outlined" {...getRootProps({ style })}>
                  <input {...getInputProps()} />
                  {isError ? (
                    <ErrorIcon color="error" />
                  ) : isLoading ? (
                    <CircularProgress color="info" />
                  ) : !parsedState.supplierId ? (
                    <Box sx={{ textAlign: "center", color: "#444" }}>
                      <Factory />
                      <Box>Bitte wähle zuerst den Lieferanten.</Box>
                    </Box>
                  ) : (
                    <Box sx={{ textAlign: "center", color: "#444" }}>
                      <PostAdd />
                      <Box>Auftragsbestätigung hier ablegen</Box>
                    </Box>
                  )}
                </Paper>
              </DialogContent>
              <DialogActions sx={{ borderTop: "1px solid #aaa" }}>
                <Button onClick={close}>Abbrechen</Button>
                <Button
                  onClick={() =>
                    setParsedState((ps) => ({
                      ...ps,
                      ...{
                        supplier_name: ps.supplier_name,
                        valid_from: null,
                        valid_until: null,
                        document_number: "",
                        articles: [],
                      },
                    }))
                  }
                  disabled={!parsedState.supplierId}
                  variant="outlined"
                  color="primary"
                >
                  Manuelle Eingabe
                </Button>
              </DialogActions>
            </Fragment>
          )}
        </Dialog>
      </Fragment>
    );
  }
);
