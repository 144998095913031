import { useQuery } from "@tanstack/react-query";
import { GridColDef, GridRowsProp } from "@mui/x-data-grid";
import ApiService from "@/api/ApiService";
import dayjs from "dayjs";
import { usePersistantStore } from "@/stores/global";

interface LegacyDefinitionEntry {
  label: string;
  render: (row: any) => React.ReactNode;
  excludeForType: string;
}

interface TableDataResult {
  rows: GridRowsProp;
  material: Record<string, number>;
}



export const useTableData = (
  tableId: string,
  type: string
) => {
  
  const region = usePersistantStore(s => s.region);
  return useQuery<TableDataResult>({
    queryKey: ["tableData", tableId, region],
    enabled: !!type && !!tableId,
    queryFn: async () => {
      const {list: rows, material } = await  ApiService.getLiveMap(type,tableId, region)();
      return {
        rows,
        material
      };
    },
    staleTime: 30_000,
  });
};
