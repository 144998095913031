import { usePersistantStore, useGlobalStore } from "@/stores/global";
import RegionSelector from "./RegionSelector";
import BaseRootNav from "@/shared/structure/RootNav";
import BobDark from "@/assets/images/bob_dark.png";
import {
  LocalShippingTwoTone,
  CalendarMonthTwoTone,
  SettingsTwoTone,
  Home,
  CalendarMonth,
  Settings,
  Today,
  Landscape,
  Construction,
  EventNote,
  ConnectWithoutContact,
  Map,
} from "@mui/icons-material";
import useRights from "@/shared/api/useRights";
import { SvgIcon } from "@mui/material";
import ApiService from "@/api/ApiService";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { MachineTransport } from "@/assets/icons/icons";


const BookingList = (props) => {
  const region = usePersistantStore(s => s.region);

  const query = useQuery<any, AxiosResponse>({
    queryKey: ["getNull", "" + region],
    refetchInterval: 60000,
    enabled: !!region,
    queryFn: ApiService.getNullErfasser(region),
  });

  return <ConnectWithoutContact {...props} className={(props.className ?? "")+" "+(query.isSuccess && query.data > 0 ? " warning" : "")} />;

};

export default function RootNav() {
  const setAuthenticated = usePersistantStore(
    (state) => state.setAuthenticated
  );

  const rights = useRights();

  const pageTitle = useGlobalStore((state) => state.pageTitle);

  return (
    <BaseRootNav
      image={BobDark}
      name={pageTitle}
      menu={[
        !("SUPPLIER_VIEW" in rights)
          ? {
              Icon: Home,
              to: "/",
              text: "Übersicht",
              end: true,
            }
          : null,
        !("SUPPLIER_VIEW" in rights)
          ? {
              Icon: EventNote,
              to: "calendar",
              text: "Kalender",
              end: true,
            }
          : null,
        !("SUPPLIER_VIEW" in rights)
          ? {
              Icon: Construction,
              to: "kgp",
              text: "Kleingeräte",
              end: true,
            }
          : null,
        !("SUPPLIER_VIEW" in rights)
          ? {
              Icon: CalendarMonth,
              to: "calendar2",
              text: "Übersicht",
              end: true,
            }
          : null,

          
         
        !("SUPPLIER_VIEW" in rights)
          ? {
              Icon: Today,
              to: "disposition",
              text: "Disposition",
              end: true,
            }
          : null,
          ("MAP" in rights)
          ? {
              Icon: Map,
              to: "map",
              text: "Karte",
              end: true,
            }
          : null,
          ("DISPOSITION" in rights) || ("TRANSPORTDISPO" in rights)
          ? {
              Icon: MachineTransport,
              to: "machinetransport",
              text: "Maschinentransport",
              end: true,
            }
          : null,
        {
          Icon: Landscape,
          to: "plants",
          text: "Lieferungen",
          end: true,
        },
        ("DISPOSITION" in rights)
          ? {
              Icon: BookingList,
              to: "baumobil",
              text: "Baumobil-Erfasser",
              end: true,
            }
          : null,
        "BASEDATA" in rights
          ? {
              Icon: Settings,
              to: "settings",
              text: "Stammdaten",
              end: false,
            }
          : null,
      ].filter((x) => !!x)}
      setAuthenticated={setAuthenticated}
      elements={[
        import.meta.env.VITE_ENV_NAME !== "PROD" ? (
          <div
            key="env"
            style={{
              background: "#c5372c",
              alignSelf: "stretch",
              display: "flex",
              flex: 1,
              alignItems: "center",

              justifyContent: "center",
            }}
          >
            <div
              style={{
                fontSize: "35px",
                lineHeight: 1.2,
                padding: "0 5px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: "bold",
              }}
            >
              {import.meta.env.VITE_ENV_NAME}-Umgebung
            </div>
          </div>
        ) : null,
        <RegionSelector key="ks" />,
      ]}
    />
  );
}
