import ApiService from "@/api/ApiService";
import { Delete, Edit } from "@mui/icons-material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { Fragment } from "react";
import { ManualBooking } from "./AddBookingDialog";
import AddBookingDialog from "./AddBookingDialog";

export function DirectBookingEditor({ booking }: { booking: ManualBooking }) {
  const queryClient = useQueryClient();

  const saveMutation = useMutation<ManualBooking, AxiosResponse, ManualBooking>(
    {
      mutationFn: ApiService.editEntity("manualBooking"),
      onSuccess: (answer) => {
        queryClient.invalidateQueries({ queryKey: ["getCalendar"] });
      },
    }
  );

  const loading = saveMutation.isPending;

  return true ? (
    <div className="resOrderEdit">
    <div className="resOrderEditDatePart">
      <div className="resOrderButton">
        <AddBookingDialog
          save={function (order: ManualBooking): void {
            saveMutation.mutate(order);
          }}
          OpenButton={({ onClick }) => (
            <Edit onClick={onClick} fontSize="inherit" />
          )}
          initialState={booking}
        />
      </div>
      <div className="resOrderButton red">
        <Delete
          fontSize="inherit"
          onClick={() => saveMutation.mutate({ ...booking, deleted: true })}
        />
      </div>
    </div></div>
  ) : null;
}
