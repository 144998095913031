import { useGlobalStore, usePersistantStore } from "@/stores/global";
import {
  Box,
  CircularProgress,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import { Fragment, useEffect, useMemo, useState } from "react";
import DispoCalendarDateSelector from "../calendar/calendarElements/DispoCalendarDateSelector";
import ApiService from "@/api/ApiService";
import {
  ResultOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { TopBar } from "@/shared/structure/TopBar";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import dayjs from "dayjs";
import useRights from "@/shared/api/useRights";
import {
  Api,
  ArrowUpward,
  BeachAccess,
  Check,
  DataArray,
  Landscape,
  LocalShipping,
  ManageSearch,
  MyLocation,
  StickyNote2,
  Sync,
  X,
} from "@mui/icons-material";
import { createDeliveryText } from "../planning/editors/MaterialTab";
import { TransState, transStates } from "../calendar/listView/MixingPlantView";
import StatusSelector from "../planning/dialogs/StatusSelector";
import { DirectCommentCreator } from "./DirectCommentCreator";
import { CalendarComment } from "./AddCommentDialog";
import { DirectCommentEditor } from "./DirectCommentEditor";
import { textColors } from "@/shared/forms/ColorPicker";
import OrderHistoryDialog from "../calendar/dialogs/OrderHistoryDialog";
import OrderLocationDialog from "../calendar/dialogs/OrderLocationDialog";

function JCalenderElement({ data }) {
  let additionalClasses = "";
  let text = "";

  switch (data.type) {
    case "TRANSPORT":
      text = createDeliveryText(data.transportInformation, data.takt);
      break;
    case "MATERIAL":
      text =
        data.amount +
        data.article.unit +
        " " +
        data.article.name +
        " von " +
        (data.article.plantName || "?") +
        " (" +
        data.article.number +
        ")";
      break;
    case "VACATION":
      additionalClasses += " jvacation" + data.level;
      text = data.staffMember.name + ": " + data.title;
      break;
  }

  if (!text) return null;

  return (
    <div
      className={
        "jCalendarElement jCalendarElement_" + data.type + additionalClasses
      }
    >
      {text}
    </div>
  );
}

function JNoteElement({ data }: { data: CalendarComment }) {
  return (
    <div
      style={{
        backgroundColor: data.color,
      }}
      onClick={(e) => e.currentTarget.classList.toggle("opened")}
      className={"jCalendarElement jCalendarElement_note"}
    >
      <div
        className="jNote_title"
        style={{
          color: textColors[data.color],
        }}
      >
        {data.title}
      </div>
      <div className="jNote_desc">{data.text}</div>
      <div className="jNote_edit">
        <DirectCommentEditor comment={data} />
      </div>
    </div>
  );
}

function TransportLine({ x, y, ooo, readOnly, setModalOpen }) {
  const queryClient = useQueryClient();
  const changeMutation = useMutation<any, AxiosResponse, ChangeData>({
    mutationFn: ApiService.setTransportOrderStatus(x.id),
    onSettled: (answer) => {
      queryClient.invalidateQueries({ queryKey: ["getOverview"] });
    },
  });
  const status = transStates[(x?.status || "OPEN") as TransState];
  return (
    <div
      className={
        "jcal_expand jcal_site_trucks" +
        (!status.needsAction ? "" : " new") +
        (x.status === "DELETED" ||
        x.status === "STORNO" ||
        x.status === "RETRACTED"
          ? " jcal_deleted"
          : "")
      }
    >
      {ooo === 0 ? (
        <div
          className={
            "jcal_checker" +
            (changeMutation.isPending ? " jcal_checker_pending" : "")
          }
          style={{
            backgroundColor: status.backgroundColor,
            color: status.iconColorOnBackground,
          }}
          onClick={(e) => {
            if (!readOnly) changeMutation.mutate(status.next);
            e.stopPropagation();
          }}
        >
          <Tooltip
            title={
              <div className="jcaltooltip">
                <div className="jcaltooltip_title">
                  {changeMutation.isPending ? "Bitte warten..." : status.title}
                </div>
                <div className="jcaltooltip_icons">
                  <ManageSearch
                    fontSize="small"
                    sx={{ cursor: "pointer", "&:hover": { color: "#aaa" } }}
                    onClick={() => setModalOpen(["HISTORY", x.id])}
                  />
                  <MyLocation
                  fontSize="small"
                    sx={{ cursor: "pointer", "&:hover": { color: "#aaa" } }}
                    onClick={() => setModalOpen(["LOCATION", x.id])}
                  />
                </div>
              </div>
            }
          >
            {changeMutation.isPending ? (
              <Sync fontSize="inherit" />
            ) : (
              <status.icon fontSize="inherit" />
            )}
          </Tooltip>
        </div>
      ) : (
        <div className="jcal_checker empty">
          <ArrowUpward fontSize="inherit" />
        </div>
      )}
      <div className="jcal_checker_name">
      {ooo === 0 ? ((ts) =>
          !ts
            ? "anschl."
            : !ts.startsWith("x")
            ? ts
            : ts === "xAM"
            ? "vorm."
            : ts === "xPM"
            ? "nachm."
            : "???")(
          x.firstUnload
        )+" - " : ""}{createDeliveryText(y.transportInformation, x.takt)}
      </div>
    </div>
  );
}

function JSiteElement({ data, readOnly, setModalOpen }) {
  return (
    <div
      className={"jCalendarElement_site"}
      onClick={(e) => e.currentTarget.classList.toggle("opened")}
    >
      <div className="jcal_site_header jcal_expand">
        <span className="jcal_site_headernumber">
          {data.phase.project.number}
        </span>
        {data.phase.project.name}
      </div>
      {data.orders.map((x) =>
        x.type === "MATERIAL" ? (
          <Fragment key={x.key}>
            <div
              className={
                "jcal_expand jcal_site_material" +
                (x.status === "DELETED" ||
                x.status === "STORNO" ||
                x.status === "RETRACTED"
                  ? " jcal_deleted"
                  : "")
              }
            >
              {x.amount +
                x.article.unit +
                " " +
                x.article.name +
                " von " +
                (x.article.plantName || "?") +
                " (" +
                x.article.number +
                ")"}
            </div>
            <div
              className={
                "jcal_expand jcal_site_material_comment" +
                (x.status === "DELETED" ||
                x.status === "STORNO" ||
                x.status === "REJECTED"
                  ? " jcal_deleted"
                  : "")
              }
            >
              {[
                !x.firstUnload
                  ? ""
                  : !x.firstUnload.startsWith("x")
                  ? x.firstUnload + " Uhr"
                  : x.firstUnload === "xAM"
                  ? "vormittags"
                  : x.firstUnload === "xPM"
                  ? "nachmittags"
                  : "",
                x.easyTarget === "LAGER" ? "Anlieferung zum Lagerplatz!" : "",
                x.comment,
              ]
                .filter((x) => x.length > 0)
                .join(" - ")}
            </div>
          </Fragment>
        ) : x.type === "TRANSPORT" ? (
          x.trucks.length > 0 ? (
            x.trucks.map((y, ooo) => (
              <TransportLine
                setModalOpen={setModalOpen}
                key={y.key}
                y={y}
                x={x}
                ooo={ooo}
                readOnly={readOnly}
              />
            ))
          ) : null
        ) : (
          <div key={x.key} className="jcal_expand jcal_site_material">
            {x.text}
          </div>
        )
      )}
    </div>
  );
}

export default function JonasCalendar() {
  const setPageTitle = useGlobalStore((state) => state.setPageTitle);

  const date = useGlobalStore((state) => state.date);
  const month = date.substring(0, 7);
  const region = usePersistantStore((state) => state.region);
  const [modalOpen, setModalOpen] = useState(null);

  const [activeList, setActiveList] = useState<string[]>(["SITE", "NOTES"]);

  useEffect(() => setPageTitle("Übersicht"), []);
  const rights = useRights();

  const query = useQuery<any[], AxiosResponse>({
    queryKey: ["getOverview", month, "" + region, activeList],
    queryFn: ApiService.getJonasKalender(month, region, activeList),
  });

  const holidayQuery = useQuery<{ date: string }[], AxiosResponse>({
    queryKey: ["getHolidays", region],
    queryFn: ApiService.getEntitiesWithFilter(
      "holiday",
      !!region ? { region: ["region", region] } : {}
    ),
  });

  const boxes = useMemo(() => {
    const boxes = [];
    const start = dayjs(month + "-01", "YYYY-MM-DD").startOf("isoWeek");
    const end = dayjs(month + "-01", "YYYY-MM-DD")
      .endOf("month")
      .endOf("isoWeek");

    let kw = "";
    for (let now = dayjs(start); now <= end; now = now.add(1, "day")) {
      const nowkw = now.format("W");
      let kwbox = <div />;
      if (kw !== nowkw) {
        kwbox = <div className="jk-week">KW {nowkw}</div>;
        kw = nowkw;
      }
      const dstring = now.format("YYYY-MM-DD");
      const isHoliday = holidayQuery.isSuccess
        ? holidayQuery.data.some((x) => x.date === dstring)
        : false;
      boxes.push(
        <div
          className={
            "jk-day jk-day" +
            now.day() +
            (!dstring.startsWith(month) ? " jk-othermonth" : "") +
            (isHoliday ? " jk-holiday" : "")
          }
          key={"date" + dstring}
        >
          <div className="jk-dateheader">
            <div className="jk-first">
              {kwbox}
              <DirectCommentCreator date={now.format("YYYY-MM-DD")} />
            </div>
            <div className="jk-date">{now.format("D")}</div>
          </div>
          {Object.values(query?.data?.[dstring] ?? {})
            .sort((a, b) => (a?.type || "aa").localeCompare(b?.type || "aa"))
            .map((x) =>
              x.type === "SITE" ? (
                <JSiteElement
                  readOnly={!("TRANSPORTDISPO" in rights)}
                  data={x}
                  key={x.key}
                  setModalOpen={setModalOpen}
                />
              ) : !x.type ? (
                <JNoteElement data={x} key={x.id} />
              ) : (
                <JCalenderElement data={x} key={x.id} />
              )
            )}
        </div>
      );
    }
    return boxes;
  }, [month, query.data]);

  return (
    <DndProvider backend={HTML5Backend}>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            p: 0,
            background: "#eee",
          }}
        >
          <TopBar>
            <DispoCalendarDateSelector month />
            <ToggleButtonGroup
              size="small"
              orientation="horizontal"
              value={activeList}
              onChange={(_, type) => setActiveList(type)}
            >
              <ToggleButton value="SITE">
                <LocalShipping />
              </ToggleButton>
              <ToggleButton value="VACATION">
                <BeachAccess />
              </ToggleButton>
              <ToggleButton value="NOTES">
                <StickyNote2 />
              </ToggleButton>
            </ToggleButtonGroup>
          </TopBar>
          <OrderHistoryDialog
            orderId={
              !!modalOpen && modalOpen[0] === "HISTORY" ? modalOpen[1] : 0
            }
            close={() => setModalOpen(null)}
          />
          <OrderLocationDialog
            orderId={
              !!modalOpen && modalOpen[0] === "LOCATION" ? modalOpen[1] : 0
            }
            close={() => setModalOpen(null)}
          />
          <Paper
            sx={{ m: 1, flex: 1, position: "relative", overflow: "auto" }}
            className="jk-wrapper"
          >
            <div className="jk-header">Mo</div>
            <div className="jk-header">Di</div>
            <div className="jk-header">Mi</div>
            <div className="jk-header">Do</div>
            <div className="jk-header">Fr</div>
            <div className="jk-header">Sa</div>
            <div className="jk-header">So</div>
            {boxes}
            {!query.isSuccess ? (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: "rgba(255,255,255,0.5)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {query.isLoading ? <CircularProgress /> : <div>Error</div>}
              </div>
            ) : null}
          </Paper>
        </Box>
      </Box>
      {/*<CustomDragLayer />*/}
    </DndProvider>
  );
}
