import ApiService from "@/api/ApiService";
import { Event, Loop, MoreTime } from "@mui/icons-material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { ManualBooking } from "./AddBookingDialog";
import AddBookingDialog from "./AddBookingDialog";
import { useContext } from "react";
import { CalendarContext } from "@/components/shared/VerticalCalendar";
import dayjs from "dayjs";


const initialManualBooking: (date: string, staffMember: number|null, resource: number|null) => ManualBooking = (date, staffMember, resource) => ({
  staffMember,
  project: null,
  resource,
  erfasser: null,
  start: date,
  end: date,
  comment: "",
  produktiv:true
});

export function DirectBookingCreator({
  
  staffMember, resource
}: {
  staffMember: number | null;
  resource: number | null;
}) {

 const queryClient = useQueryClient();
   const view = useContext(CalendarContext);

  const saveMutation = useMutation<
    ManualBooking,
    AxiosResponse,
    ManualBooking
  >({
    mutationFn: ApiService.createEntity("manualBooking"),
    onSuccess: (answer) => {
      queryClient.invalidateQueries({queryKey: ["getCalendar"]});
    },
  });


  
  

  const loading =
    saveMutation.isPending;

  return true ? (
    <AddBookingDialog
      save={function (order: ManualBooking): void {
        saveMutation.mutate(order);
      }}
      OpenButton={
        ({onClick}) => <div className="directbookingButton calendar" onClick={!loading ? onClick : () => {}}>
          {loading ? <Loop fontSize="inherit" /> : <MoreTime fontSize="inherit" />}
        </div>
      }
      initialState={{
        ...initialManualBooking((view?.viewStart || dayjs()).format("YYYY-MM-DD"), staffMember, resource)
      }}
    />
  ) : null;
}
