import { useGlobalStore } from "@/stores/global";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import DatePicker from "react-date-picker";
import { IconButton, Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";

export default function DispoCalendarDateSelector({
  month = false,
  noFuture = false,
  overwriteDate,
  overwriteSetDate,
}: {
  month?: boolean;
  noFuture?: boolean;
  overwriteDate?: dayjs.Dayjs;
  overwriteSetDate: (date: dayjs.Dayjs) => void;
}) {
  let date = useGlobalStore((state) => state.getDate());
  let setDate = useGlobalStore((state) => state.setDate);
  if (!!overwriteDate) date = overwriteDate;
  if (!!overwriteSetDate) setDate = overwriteSetDate;
  const [calendarOpen, setCalendarOpen] = useState(false);
  const maxDate = noFuture ? dayjs() : undefined;

  return (
    <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
      <IconButton
        size="small"
        onClick={() => setDate(date.subtract(1, month ? "month" : "day"))}
      >
        <ArrowBackIos fontSize="small" />
      </IconButton>
      <Box
        onClick={() => setCalendarOpen(true)}
        sx={{
          display: "flex",
          minWidth: "145px",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          cursor: "pointer",
        }}
      >
        <Typography variant="h6">
          {date.format(month ? "MMMM YYYY" : "dd., DD.MM.YYYY")}
        </Typography>
        <Box
          sx={{
            position: "absolute",
            left: 0,
            bottom: 0,
            ".invisiblePicker .react-date-picker__wrapper": { display: "none" },
          }}
        >
          <DatePicker
            className={"invisiblePicker"}
            onChange={(date: Date) => setDate(dayjs(date))}
            isOpen={calendarOpen}
            maxDate={!!maxDate ?  maxDate.toDate() : undefined}
            clearIcon={null}
            onCalendarClose={() => {
              console.log("calendar  closes");
              setCalendarOpen(false);
            }}
            value={date.toDate()}
          />
        </Box>
      </Box>
      {!!maxDate && date.format("YYYY-MM-DD") >= maxDate.format("YYYY-MM-DD") ? null :<IconButton
        size="small"
        onClick={() => setDate(date.add(1, month ? "month" : "day"))}
      >
        <ArrowForwardIos fontSize="small" />
      </IconButton>}
    </Box>
  );
}
