import { useGlobalStore, usePersistantStore } from "@/stores/global";
import { Error, HistoryOutlined, Loop, PregnantWoman, SaveTwoTone, SyncProblem } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { create, merge, uniqueId } from "lodash";
import ArticlePage from "./pages/ArticlePage";
import BaseDataPage from "./pages/BaseDataPage";
import PlanningPage from "./pages/PlanningPage";
import { useBlocker, useLocation, useParams } from "react-router-dom";
import StoffstromPage from "./pages/StoffstromPage";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import FilePage from "./pages/FilePage";
import ResourceTab from "./editors/ResourceTab";
import ApiService from "@/api/ApiService";
import dayjs from "dayjs";
import {TransportInformation as TruckInfo} from "@/shared/types";
import { preview } from "vite";

export interface SupplierOrder {
  id: number;
  number: string;
  validFrom: string;
  validUntil: string;
  supplier: Supplier;
  positions: OrderPosition[];
  deleted?: boolean;
  externalId: string;
}

export interface OrderPosition {
  id: number;
  article: Article;
  deliveryType: DeliveryType;
  price: number | null;
  amount: number | null;
}

export interface Article {
  id: number;
  number: string;
  name: string;
  plantName: string;
  supplierName: string;
}

export interface DeliveryType {
  id: number;
  name: string;
}

export interface Supplier {
  id: number;
  number: string;
  name: string;
  contact: string;
  phone: string;
  email: string;
}

export interface Subcontractor {
  name: string;
  id: number;
}

export interface Client {
  name: string;
  id: number;
}

export interface SubcontractorOrder {
  subcontractor: Subcontractor;
  comment: string;
  id: number;
  deleted?: boolean;
}

export interface ProjectFile {
  id: number;
  deleted: boolean;
  description: string;
  filename: string;
  createdAt: string;
}

export interface ProjectState {
  id?: number;
  name: string;
  region: number;
  number: string;
  writeable: boolean;
  client: number | null;
  position: SitePosition;
  files: ProjectFile[];
  resourceOrders: (ResourceOrder & WithExceptions & WithPhaseExceptions)[];
  plannedStart: string | null;
  plannedEnd: string | null;
  manager: number | null;
  supplierOrders: SupplierOrder[];
  subcontractorOrders: SubcontractorOrder[];
  phases: ProjectPhase[];
  comment: string;
  stoffstrom: StoffstromOrder[];
  additionalUsers: number[];
}

export interface StoffstromOrder {
  attachments: { filename: string; originalName: string }[];
  id: number;
  deleted?: boolean;
  type: "OFFER" | "REQUEST";
  comment: string;
  category: string;
  amount: string;
  date: string;
}

export interface AdditionalArticle {
  id: number;
  deleted?: boolean;
  article: Article | null;
  amount: number;
}

export interface MaterialOrder {
  id: number;
  deleted?: boolean;
  comment: string;
  easyTarget: "SITE" | "LAGER";
  article: Article | null;
  position: OrderPosition | null;
  amount: number;
  takt: number;
  begleitschein: boolean;
  transportInformation: TruckInfo[] | null;
  firstUnload: string | null;
  additionalArticles: AdditionalArticle[];
}

export type {TruckInfo};

export const initialMaterialOrder: () => MaterialOrder = () => ({
  id: 0,
  deleted: false,
  amount: 100,
  easyTarget: "SITE",
  comment: "",
  article: null,
  position: null,
  takt: 0,
  begleitschein: false,
  firstUnload: null,
  additionalArticles: [],
  transportInformation: [
    {
      type: "SA",
      amount: 1,
      thermo: false,
      stahlmulde: false,
      bordmatik: false,
      allrad: false,
      siteWork: false,
    },
  ],
});

export interface ResourceOrder {
  id: number;
  deleted?: boolean;
  amount: number;
  startConstraint: string | null;
  endConstraint: string | null;
  tag: number | null;
  autoBooking: boolean;
  comment: string;
  type: "CREW" | "RESOURCE" | "HUMAN";
  references: (number | null)[];
}

export const initialResourceOrder: () => ResourceOrder = () => ({
  id: 0,
  deleted: false,
  amount: 1,
  tag: null,
  comment: "",
  startConstraint: null,
  endConstraint: null,
  autoBooking: false,
  type: "RESOURCE",
  references: [null],
});

export interface ProjectPhase {
  id?: number;
  name: string;
  comment: string;
  uuid: string;
  trade: number | null;
  position: SitePosition | null;
  plannedStart: string | null;
  plannedEnd: string | null;
  subcontractor: number | null;
  deleted?: boolean;
  jobs: { [date: string]: ProjectJob };
  resourceOrders: (ResourceOrder & WithExceptions)[];
}

export interface WithExceptions {
  exceptions: string[];
}

export interface WithPhaseExceptions {
  phaseExceptions: string[];
}

export interface SitePosition {
  site: { lat: number; lng: number };
  yard: { lat: number; lng: number };
  useYard: boolean;
  onlySolo: boolean;
  comment: string;
}

export const initialPosition = {
  site: { lat: 0, lng: 0 },
  yard: { lat: 0, lng: 0 },
  useYard: false,
  comment: "",
  onlySolo: false,
};

const initialState: (region: number) => ProjectState = (region) => {
  return {
    name: "",
    comment: "",
    number: "",
    position: merge({}, initialPosition),
    plannedStart: null,
    plannedEnd: null,
    writeable: true,
    region,
    manager: null,
    files: [],
    client: null,
    supplierOrders: [],
    resourceOrders: [],
    subcontractorOrders: [],
    stoffstrom: [],
    phases: [],
    additionalUsers: [],
  };
};

export interface ProjectJob {
  date: string;
  comment: string;
  deleted?: boolean;
  id: number;
  duration: "FULL" | "HALF";
  status: "PLANNING" | "RAW" | "READY" | "FINE";
  position: SitePosition | null;
  materialOrders: MaterialOrder[];
  resourceOrders: ResourceOrder[];
  uuid: string;
  startTime: string | null;
}

export const initialJob: (date: string) => ProjectJob = (date) => ({
  date,
  comment: "",
  id: 0,
  uuid: self.crypto.randomUUID(),
  deleted: false,
  duration: "FULL",
  status: "PLANNING",
  position: null,
  materialOrders: [],
  resourceOrders: [],
  startTime: "07:00",
});

export default function Project() {
  const { id: rawId } = useParams();

  const location = useLocation();
  const initialPage = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return !!location.state?.phaseId
      ? location.state
      : params.has('phaseId') && params.has('date')
      ? { date: params.get('date')!, phaseId: parseInt(params.get('phaseId')!) }
      : null;
  }, [location]);
  
  
  
  const [initialSelection, setInitialSelection] = useState({
    date: null,
    phase: 0,
  });


  const [wasChanged, setChanged] = useState(false);
  const [dateChangeNecessary, setDateChangeNecessary] = useState(false);

  

  const id = parseInt(rawId ?? "0");
  const region = usePersistantStore((s) => s.region);
  const [project, setState] = useState<ProjectState>(initialState(region));
  const [history, setHistory] = useState<{state: typeof project, wasChanged: typeof wasChanged}[]>([]);
  const [forwardHistory, setForwardHistory] = useState<{state: typeof project, wasChanged: typeof wasChanged}[]>([]);
  const [loadingState, setLoadingState] = useState<
    "loading" | "error" | "ready" | "initialLoading" | "initialError"
  >(
    (() => {
      const xx = project.id === id ? "loading" : "initialLoading";
      return xx;
    })()
  );

  const holidayQuery = useQuery<{date: string}[], AxiosResponse>({
    queryKey: ["getHolidays", project.region],
    queryFn: ApiService.getEntitiesWithFilter("holiday", {region: ["region",project.region]}),
  });

  const holidays = useMemo(() => {
    if (!holidayQuery.data) return new Set([]);
    return new Set(holidayQuery.data.map(x => x.date));
  },[holidayQuery.data])

  useEffect(() => {
    if (!dateChangeNecessary) return;
    setDateChangeNecessary(false);
    console.log("trigger date change");
    setState((project) => {
      let dateChanges = {};
      const phases = project.phases;

      let plannedStart = project.plannedStart;
      let plannedEnd = project.plannedEnd;

      for (let index = 0; index < phases.length; index++) {
        let changed = false;
        const dates = Object.values(phases[index].jobs)
          .filter((j) => !j.deleted)
          .map((x) => x.date);
        if (dates.length > 0) {
          const min = dates.reduce((min, c) => (c < min ? c : min));
          const max = dates.reduce((max, c) => (c > max ? c : max));

          if (!phases[index].plannedStart || min < phases[index].plannedStart){
            phases[index].plannedStart = min;
            changed = true;
          }
          if (!phases[index].plannedEnd || max > phases[index].plannedEnd){
            phases[index].plannedEnd = max;
            changed = true;
          }
          
        }

        if (
          !!phases[index].autoFill &&
          !!phases[index].plannedStart &&
          !!phases[index].plannedEnd
        ) {
          const start = dayjs(phases[index].plannedStart, "YYYY-MM-DD");
          const end = dayjs(phases[index].plannedEnd, "YYYY-MM-DD");
          for (let date = start; date <= end; date = date.add(1, "day")) {
            if (date.isoWeekday() > 5) continue;
            const datekey = date.format("YYYY-MM-DD");
            if (
              (!(holidays.has(datekey))) &&
              (!(datekey in phases[index].jobs) || !!phases[index].jobs[datekey].deleted) &&
              !phases[index].autoFillExceptions?.includes(datekey)
            ) {
              phases[index].jobs[datekey] = initialJob(datekey);
            }
          }
        }

        plannedStart =
          !!phases[index].plannedStart &&
          (!plannedStart || plannedStart > phases[index].plannedStart)
            ? phases[index].plannedStart
            : plannedStart;
        plannedEnd =
          !!phases[index].plannedEnd &&
          (!plannedEnd || plannedEnd < phases[index].plannedEnd)
            ? phases[index].plannedEnd
            : plannedEnd;
        phases[index].changed = !!phases[index].changed || changed;
      }
      return { ...project, phases, plannedStart, plannedEnd, changed: !!project.changed || plannedStart !== project.plannedStart || plannedEnd !== project.plannedEnd };
    });
  }, [dateChangeNecessary]);

  console.log(project);

  const loadData = useCallback(async () => {
    console.log("start of ", loadingState);
    setLoadingState(
      loadingState === "initialLoading" ? "initialLoading" : "loading"
    );
    try {
      const data = await ApiService.getEntity("project", id)();

      if (Array.isArray(data.position)) {
        data.position = { ...initialPosition };
      }

      data.supplierOrders = await ApiService.getEntitiesWithFilter(
        "supplierOrder",
        { project: ["project", id] }
      )();
      data.subcontractorOrders = await ApiService.getEntitiesWithFilter(
        "subcontractorOrder",
        { project: ["project", id] }
      )();
      data.phases = await ApiService.getEntitiesWithFilter("projectPhase", {
        project: ["project", id],
      })();
      for (let i = 0; i < data.phases.length; i++) {
        if (Array.isArray(data.phases[i].jobs)) data.phases[i].jobs = {};
        data.phases[i].jobs = Object.fromEntries(Object.entries(data.phases[i].jobs).filter(([_date,data]) => !data.deleted))
      }
      data.stoffstrom = await ApiService.getEntitiesWithFilter(
        "stoffstromEntry",
        {
          project: ["project", id],
        }
      )();
      if (!!initialPage) {
        for (let index = 0; index < data.phases.length; index++) {
          if (data.phases[index].id === initialPage.phaseId) {
            setInitialSelection({
              date: initialPage.date,
              phase: index,
            });
            break;
          }
        }
      }
      setState(data);
      setChanged(false);
      setLoadingState("ready");
    } catch (e) {
      console.log(e);
      setLoadingState(
        loadingState === "initialLoading" ? "initialError" : "error"
      );
    }
  }, []);

  const autoBookMutation = useMutation<any, AxiosResponse, number>({
    mutationFn: ApiService.autoBook(),
  });


  const saveMutation = useMutation<ProjectState, AxiosResponse, ProjectState>({
    mutationFn:
      id > 0
        ? ApiService.editEntity("project", id, {onlyChanged: 1})
        : ApiService.createEntity("project"),
    onSuccess: (answer) => {
      autoBookMutation.mutate(answer.id ?? 0);
      console.log("End of mu", loadingState);
      loadData();
    },
  });

  console.log(loadingState);

  useEffect(() => {
    if (id === 0) {
      setState(initialState(region));
      setChanged(false);
      setLoadingState("ready");
      return;
    }
    loadData();
  }, [id]);

  const [currentPage, setCurrentPage] = useState<
    "PROJECT" | "ARTICLES" | "PLANNING" | "STOFFSTROM" | "UPLOAD" | "RESOURCES"
  >(!!initialPage ? "PLANNING" : "PROJECT");

  useEffect(
    () =>
      setPageTitle(
        [project.number, project.name].filter((x) => x.length > 0).join(": ") ||
          "Neues Projekt"
      ),
    [project.number, project.name]
  );
  const setPageTitle = useGlobalStore((state) => state.setPageTitle);

  const setField = (changes: Partial<ProjectState>, createHistoryPoint: boolean = false) => {
    if (!project.writeable) return;
    if (createHistoryPoint){
      const updatedHistory = [...history, { state: project, wasChanged }];
      if (updatedHistory.length > 5) {
        updatedHistory.shift(); // Remove the oldest entry
      }
      setHistory(updatedHistory);
    } else {
      setHistory([]);
    }
    setForwardHistory([]);
    setChanged(true);
    const topChange = Object.keys(changes).some(key => !["files","resourceOrders","supplierOrders","subcontractorOrders", "phases", "stoffstrom"].includes(key));
  
    setState(prev => ({...prev, ...changes, changed: !!prev.changed || topChange}));
  };

  const undo = () => {
    if (history.length === 0) return; // Nothing to undo

    const previousState = history[history.length - 1];
    const updatedHistory = history.slice(0, history.length - 1); // Remove the last history item
    const updatedForwardHistory = [
      { state: project, wasChanged },
      ...forwardHistory,
    ];

    setHistory(updatedHistory); // Update history stack
    setForwardHistory(updatedForwardHistory); // Push current state to forward history
    setState(previousState.state); // Revert to previous state
    setChanged(previousState.wasChanged); // Restore the previous wasChanged value
  };

  const redo = () => {
    if (forwardHistory.length === 0) return; // Nothing to redo

    const nextState = forwardHistory[0];
    const updatedForwardHistory = forwardHistory.slice(1); // Remove the first forward history item
    const updatedHistory = [
      ...history,
      { state: project, wasChanged },
    ];

    setForwardHistory(updatedForwardHistory); // Update forward history stack
    setHistory(updatedHistory); // Push current state to history stack
    setState(nextState.state); // Apply the next state
    setChanged(nextState.wasChanged); // Restore the next wasChanged value
  };

  const blocker = useBlocker(wasChanged);

  console.log("BLOCKER", blocker);

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexGrow: 1,
        bgcolor: "background.paper",
        overflow: "hidden",
      }}
    >
      {loadingState === "initialError" ? (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          Es gab einen Fehler. Bitte versuche es erneut oder melde dich neu an.
        </Box>
      ) : loadingState === "initialLoading" ? (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Fragment>
          <Box
            sx={{
              borderRight: "1px solid #aeaeae",
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            {project.writeable ? <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                zIndex: 20,
                py: "5px",
                borderBottom: "1px solid #999",
                boxShadow: "0px 3px 3px rgba(0,0,0,0.2)",
              }}
            >
              <Button
                color={
                  saveMutation.isError || loadingState === "error" || wasChanged
                    ? "error"
                    : "primary"
                }
                disabled={saveMutation.isPending}
                onClick={() => saveMutation.mutate(project)}
                startIcon={
                  saveMutation.isPending || loadingState === "loading" ? (
                    <Loop />
                  ) : saveMutation.isError || loadingState === "error" ? (
                    <SyncProblem />
                  ) : (
                    <SaveTwoTone />
                  )
                }
              >
                Speichern
              </Button>
              <Dialog
                open={blocker.state === "blocked"}
                onClose={blocker.reset}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Du hast ungespeicherte Änderungen. Sie gehen verloren, wenn
                    du die Planung ohne zu speichern verlässt.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={blocker.reset}>zurück zur Planung</Button>
                  <Button onClick={blocker.proceed} color="error" autoFocus>
                    Trotzdem verlassen
                  </Button>
                </DialogActions>
              </Dialog>
            </Box> : null}
            <Box sx={{ flex: 1 }}>
              <ToggleButtonGroup
                sx={{
                  mb: "20px",
                  background: "white",
                  ".MuiToggleButtonGroup-firstButton": { borderTop: "none" },
                  ".MuiToggleButtonGroup-groupedVertical": {
                    borderRadius: 0,
                    borderLeft: "none",
                    borderRight: "none",
                  },
                }}
                orientation="vertical"
                exclusive
                fullWidth
                value={currentPage}
                onChange={(_, val) => val && setCurrentPage(val)}
              >
                <ToggleButton value="PROJECT">Stammdaten</ToggleButton>
                <ToggleButton value="ARTICLES">Einkauf</ToggleButton>
                <ToggleButton value="PLANNING">Planung</ToggleButton>
                <ToggleButton value="RESOURCES">Personal & Geräte</ToggleButton>
                <ToggleButton value="STOFFSTROM">Stoffstrom</ToggleButton>
                <ToggleButton value="UPLOAD">Dateien & Links</ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              bgcolor: "background.paper",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                background: "#eee",
              }}
            >
              {currentPage === "PLANNING" ? (
                <PlanningPage
                  undo={undo} redo={redo} undoPossible={history.length > 0} redoPossible={forwardHistory.length > 0}
                  setDateChangeNecessary={setDateChangeNecessary}
                  saveProject={() => saveMutation.mutate(project)}
                  initialSelection={initialSelection}
                  project={project}
                  setField={setField}
                />
              ) : currentPage === "RESOURCES" ? (
                <ResourceTab
                  region={project.region}
                  changeable={project.writeable}
                  projectState={project.resourceOrders}
                  setProjectState={(resourceOrders) =>
                    setField({ resourceOrders })
                  }
                  phaseState={[]}
                  setPhaseState={() => {}}
                  jobState={[]}
                  setJobState={() => {}}
                  jobUuid={null}
                  phaseUuid={null}
                />
              ) : currentPage === "ARTICLES" ? (
                <ArticlePage project={project} setField={setField} />
              ) : currentPage === "STOFFSTROM" ? (
                <StoffstromPage project={project} setField={setField} />
              ) : currentPage === "UPLOAD" ? (
                <FilePage project={project} setField={setField} />
              ) : (
                <BaseDataPage
                  project={project}
                  setField={setField}
                  setDateChangeNecessary={setDateChangeNecessary}
                />
              )}
            </Box>
          </Box>
        </Fragment>
      )}
    </Box>
  );
}


