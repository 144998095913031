import { useDrop } from "react-dnd";
import {
  ResourceBookingType,
  SpBookingType,
  GhostType,
  ResourceOrderType,
} from "../KGP";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ApiService from "@/api/ApiService";
import { AxiosResponse } from "axios";
import { Rejector } from "../calendarElements/ResInner";

export default function KGPResourceOrder({
  y,
  date,
}: {
  y: ResourceOrderType;
  date: string;
}) {
  const queryClient = useQueryClient();

  const changeMutation = useMutation<any, AxiosResponse, ChangeData>({
    mutationFn: ApiService.changeKGPCalendar(),
    onSettled: (answer) => {
      queryClient.invalidateQueries({ queryKey: ["getCalendar"] });
      queryClient.invalidateQueries({ queryKey: ["getKGP"] });
    },
  });

  const changeMutation2 = useMutation<any, AxiosResponse, ChangeData>({
    mutationFn: ApiService.changeCalendar(),
    onSettled: (answer) => {
      queryClient.invalidateQueries({ queryKey: ["getCalendar"] });
      queryClient.invalidateQueries({ queryKey: ["getKGP"] });
    },
  });

  const [{ isOver }, drop] = useDrop<
    ResourceBookingType | SpBookingType | GhostType,
    void,
    { isOver: boolean }
  >(
    () => ({
      accept: ["ghost", "spBooking", "kgBooking"],
      collect: (monitor) => {
        const isOver = !!monitor.isOver();
        return {
          isOver,
        };
      },
      drop: (item, monitor) => {
        changeMutation.mutate({
          delete:
            item.type === "kgBooking" || item.type === "spBooking"
              ? { type: item.type, id: item.id }
              : null,
          create: {
            type: "kleinGeraetBookingFromOrder",
            resource: item.resource.id,
            target: y.job,
            order: y.id,
            erfasser: y.erfasser.id
          },
        });
        document.querySelectorAll('.dragging').forEach(el => el.classList.remove('dragging'));
      },
    }),
    [y]
  );

  return (
    <div
      ref={drop}
      className={"kgpcontent resourceOrder2" + (isOver ? " dropover" : "")}
    >
      <div className="kgprestitle">Bedarf</div>
      {y.comment.length > 0 ? (
        <div className="kgpcomment">{y.comment}</div>
      ) : null}
      {!!y.wish ? (
        <div className="kgpreswish">
          <span className="kgpreswoshtitle">Wunsch:</span>
          <span className="kgpreswoshnum">{y.wish.number}</span>
          <span className="kgpreswoshname">{y.wish.name}</span>
        </div>
      ) : null}
      <div className="kgprejectorBase">
        <Rejector date={date} changeMutation={changeMutation2} orderId={y.id} />
      </div>
    </div>
  );
}
