import ApiService from "@/api/ApiService";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { MaterialOrder } from "@/components/planning/Project";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TextField,
  Tooltip,
} from "@mui/material";

import { GridColDef, DataGrid, useGridApiRef } from "@mui/x-data-grid";

import {
  ArrowLeft,
  ArrowRight,
  CheckBox,
  CheckBoxOutlineBlank,
  CheckCircle,
  DeleteForever,
  DraftsOutlined,
  East,
  Edit,
  Email,
  EmailOutlined,
  HowToReg,
  MyLocation,
  NewReleases,
  NotInterested,
  Warehouse,
  West,
} from "@mui/icons-material";
import dayjs from "dayjs";
import {
  createArticleText,
  createDeliveryText,
} from "@/components/planning/editors/MaterialTab";
import useRights from "@/shared/api/useRights";
import { usePersistantStore } from "@/stores/global";
import { useMemo, useState } from "react";
import { usePersistentDataGrid } from "@/shared/hooks/usePersistentDataGrid";
import OrderLocationDialog from "../calendar/dialogs/OrderLocationDialog";

type MachineTransportRow = {
  direction: "incoming" | "outgoing"; //
  date: string; //
  uid: string;
  coId: string | null;
  resourceId: number;
  resourceName: string;
  resourceNumber: string;
  name: string;
  id: number;
} & (
  | { type: "project"; number: string; crews: string[] }
  | { type: "specialPlace" }
);

function addCoidBadge(e: MouseEvent) {
  for (let element of document.getElementsByClassName(
    "mtt_coid" + e.currentTarget.dataset.coid
  )) {
    element.classList.add("mtt_coid_hover");
  }
}

function removeCoidBadge(e: MouseEvent) {
  for (let element of document.getElementsByClassName("mtt_coid_hover")) {
    element.classList.remove("mtt_coid_hover");
  }
}

function getColumns(transReadOnly, modalSetter): GridColDef[] {
  const cells: GridColDef<MachineTransportRow>[] = [];

  return cells.concat([
    {
      field: "direction",
      display: "flex",
      width: 50,
      headerName: "Richtung",
      renderCell: (params) => (
        <div
          onMouseEnter={params.row.coId ? addCoidBadge : undefined}
          onMouseLeave={params.row.coId ? removeCoidBadge : undefined}
          data-coid={params.row.coId ? params.row.coId : ""}
          className={
            "mtt_orderdir" +
            (params.row.coId ? " mtt_hascoid mtt_coid" + params.row.uid : "")
          }
        >
          {params.row.direction === "incoming" ? <West /> : <East />}
        </div>
      ),
    },
    {
      field: "date",
      headerName: "Datum",
      width: 150,
      type: "date",
      valueGetter: (value, row) => dayjs(row.date, "YYYY-MM-DD").toDate(),
      valueFormatter: (value, row) => {
        return dayjs(row.date, "YYYY-MM-DD").format("ddd, DD.MM.YYYY");
      },
    },
    {
      field: "time",
      headerName: "Uhrzeit",
      width: 100,
      valueGetter: (_, row) =>
        ((ts) =>
          !ts
            ? "ungeplant"
            : !ts.startsWith("x")
            ? ts
            : ts === "xAM"
            ? "vormittags"
            : ts === "xPM"
            ? "nachmittags"
            : "???")(row.time),
    },
    {
      field: "nfame",
      headerName: "Ort",
      display: "flex",
      flex: 1,
      sortable: false,
      valueGetter: (value, row) =>
        row.name + "_" + (row.type === "specialPlace" ? "" : row.number),
      renderCell: (params) =>
        params.row.type === "specialPlace" ? (
          <em>{params.row.name}</em>
        ) : (
          <Tooltip
            arrow
            title={
              params.row.crews.length === 0
                ? "Unbekannte Kolonne"
                : params.row.crews.join("; ")
            }
          >
            <div>
              <span className="mmt_number">{params.row.number}</span>
              {params.row.name}
            </div>
          </Tooltip>
        ),
    },
    {
      field: "target",
      display: "flex",
      width: 80,
      headerName: "Ziel",
      renderCell: (params) =>
        params.row.type === "specialPlace" ? null : (
          <Tooltip title={"Zielort anzeigen"}>
            <MyLocation
              sx={{ cursor: "pointer", "&:hover": { color: "#666" } }}
              onClick={() => modalSetter(parseInt(params.row.uid.substring(2)))}
            />
          </Tooltip>
        ),
    },
    {
      field: "resourceName",
      headerName: "Maschine",
      flex: 1,
      valueGetter: (value, row) => row.resourceName + "_" + row.resourceNumber,
      renderCell: (params) => (
        <>
          <span className="mmt_number">{params.row.resourceNumber}</span>
          {params.row.resourceName}
        </>
      ),
    },
    {
      field: "state",
      display: "flex",
      width: 80,
      headerName: "trans",
      renderCell: (params) => (
        <TransDMState readOnly={transReadOnly} row={params.row} />
      ),
    },
    {
      field: "comment",
      display: "flex",
      width: 150,
      headerName: "Kommentar",
      renderCell: (params) => (
        <TransComment readOnly={transReadOnly} row={params.row} />
      ),
    },
    /*{
      field: "plantState",
      display: "flex",
      width: 80,
      headerName: "Lieferstatus",
      renderCell: (params) => (
        <PlantState
          readOnly={readOnly}
          isBauhof={
            !!params.row.materialOrder.article &&
            !!params.row.materialOrder.article.bauhof
          }
          id={params.row.materialOrder.id}
          state={params.row.materialOrder.plantStatus}
        />
      ),
    }, */
  ]);
}

const states = {
  NEW: { icon: Email, title: "Neu", color: "darkblue", next: "SEEN" },
  SEEN: {
    icon: DraftsOutlined,
    title: "Gesehen",
    color: "darkgreen",
    next: "ACCEPTED",
  },
  ACCEPTED: {
    icon: CheckCircle,
    title: "Akzeptiert",
    color: "darkgreen",
    next: "NOT_NEEDED",
  },
  MOVED: {
    icon: NewReleases,
    title: "Verschoben",
    color: "orange",
    next: "SEEN",
  },
  STORNO: {
    icon: DeleteForever,
    title: "Storniert",
    color: "red",
    next: "STORNO_ACCEPTED",
  },
  NOT_NEEDED: {
    icon: NotInterested,
    title: "Kein Transport nötig",
    color: "#777",
    next: "NEW",
  },
};

const TransDMState = ({ row, readOnly }: { row: any; readOnly: boolean }) => {
  const queryClient = useQueryClient();
  const changeMutation = useMutation<any, AxiosResponse, any>({
    mutationFn: ApiService.setTransportStatus(),
    onSettled: (answer) => {
      queryClient.invalidateQueries({ queryKey: ["getTransportStatus"] });
    },
  });

  const cstate = row.state;

  const Icon = states[cstate].icon;

  return changeMutation.isPending ? (
    <CircularProgress />
  ) : (
    <Tooltip title={states[cstate].title}>
      <Icon
        sx={{
          cursor: "pointer",
          color: states[cstate].color,
          "&:hover": { color: "#666" },
        }}
        onClick={
          readOnly
            ? undefined
            : () =>
                changeMutation.mutate({ ...row, state: states[cstate].next })
        }
      />
    </Tooltip>
  );
};

const TransComment = ({ row, readOnly }: { row: any; readOnly: boolean }) => {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState("");
  const changeMutation = useMutation<any, AxiosResponse, any>({
    mutationFn: ApiService.setTransportStatus(),
    onSettled: (answer) => {
      queryClient.invalidateQueries({ queryKey: ["getTransportStatus"] });
      close();
    },
  });

  const close = () => {
    setOpen(false);
  };
  const openModal = () => {
    setComment(row.comment ?? "");
    setOpen(true);
  };
  const save = () => changeMutation.mutate({ ...row, comment });

  return (
    <div className="mtcommentwrap">
      <div className="mtcommenttext">{row.comment ?? ""}</div>
      <Edit
        fontSize="inherit"
        sx={{
          cursor: "pointer",
          marginLeft: "4px",
          verticalAlign: "middle",
          color: "#444",
          "&:hover": { color: "#666" },
        }}
        onClick={readOnly ? undefined : openModal}
      />
      {open ? (
        <Dialog open={open} maxWidth="md" fullWidth onClose={close}>
          <DialogTitle
            sx={{
              p: 1,
              px: 2,
              background: "#002169",
              color: "white",
              borderBottom: "1px solid rgba(255,255,255,0.5)",
            }}
          >
            Kommentar
          </DialogTitle>
          <DialogContent
            sx={{
              p: 1,
            }}
          >
            <TextField
              size="small"
              sx={{ mt: 1 }}
              fullWidth
              value={comment}
              placeholder="Kommentar"
              multiline
              onChange={(e) => setComment((e.target as HTMLInputElement).value)}
            />
          </DialogContent>
          <DialogActions
            sx={{
              borderTop: "1px solid #aaa",
              justifyContent: "space-between",
            }}
          >
            <Button onClick={close}>Schließen</Button>
            <Button variant="contained" onClick={save}>
              Speichern
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </div>
  );
};

export default function DeliveryMachines({}: {}) {
  const rights = useRights();
  const apiRef = useGridApiRef();

  const region = usePersistantStore((state) => state.region);

  const materialQuery = useQuery<MachineTransportRow[], AxiosResponse>({
    queryKey: ["getMachineTransport", "" + region],
    queryFn: ApiService.getMachineTransport(region),
  });

  const date = dayjs().subtract(7, "day").format("YYYY-MM-DD");
  const today = dayjs().format("YYYY-MM-DD");

  const stateQuery = useQuery<
    Record<string, MachineTransportRow>,
    AxiosResponse
  >({
    queryKey: ["getTransportStatus", "" + date, "" + region],
    queryFn: ApiService.getTransportStatus(date, region),
  });

  //NEW -> SEEN -> MOVED -> STORNO -> STORNO_ACCEPTED -> NOT_NEEDED
  const data = useMemo(() => {
    if (!materialQuery.isSuccess || !stateQuery.isSuccess) return [];
    const output = [];
    const seen = new Set();
    for (let row of materialQuery.data) {
      if (!Array.isArray(stateQuery.data) && row.uid in stateQuery.data) {
        //matches -> check if moved, seen or unnecessary
        const stateData = stateQuery.data[row.uid];
        seen.add(row.uid);
        const comment = stateData.comment || "";
        const state =
          stateData.state === "NEW" ||
          stateData.state === "STORNO" ||
          stateData.state === "STORNO_ACCEPTED"
            ? "NEW"
            : stateData.state === "NOT_NEEDED"
            ? "NOT_NEEDED"
            : stateData.date !== row.date
            ? "MOVED"
            : stateData.state || "SEEN";
        output.push({ ...row, state, comment });
      } else {
        //no state -> new
        output.push({ ...row, state: "NEW" });
      }
    }
    if (!Array.isArray(stateQuery.data)) {
      for (let uid in stateQuery.data) {
        if (seen.has(uid)) continue;
        const stateData = stateQuery.data[uid];
        if (
          stateData.date < today ||
          stateData.state === "STORNO_ACCEPTED" ||
          stateData.state === "NOT_NEEDED"
        )
          continue;
        //if before today or state === storno_accepted continue
        //else add as storno
        output.push({ ...stateData, state: "STORNO" });
      }
    }

    return output.sort(
      (a, b) =>
        a.date.localeCompare(b.date) ||
        a.id - b.id ||
        b.direction.localeCompare(a.direction) ||
        a.uid.localeCompare(b.uid)
    );
  }, [materialQuery.data, stateQuery.data]);

  const [modalOpen, setModalOpen] = useState<number>(0);
  const gridProps = usePersistentDataGrid(
    "deliverymachines",
    getColumns(!("TRANSPORTDISPO" in rights), setModalOpen)
  );

  return (
    <Paper
      sx={{
        overflow: "hidden",
        display: "flex",
        m: 1,
        flexDirection: "column",
      }}
    >
      <OrderLocationDialog
        orderId={!!modalOpen ? modalOpen : 0}
        close={() => setModalOpen(0)}
        type="RESOURCEBOOKING"
      />
      <div className="calListWrapper">
        {materialQuery.isLoading ? (
          <CircularProgress />
        ) : materialQuery.isLoadingError ? (
          "Error"
        ) : (
          <DataGrid<MachineTransportRow>
            rows={data}
            apiRef={apiRef}
            rowHeight={25}
            getRowId={(row) => row.uid}
            loading={materialQuery.isLoading || stateQuery.isLoading}
            getRowClassName={(params: any) => {
              const currentRowId = params.id;

              // Step 1: Get sorted row IDs (includes all rows, even filtered-out ones)
              const sortedRowIds = apiRef.current?.getSortedRowIds() || [];

              // Step 2: Filter only currently visible rows using `getRowIndexRelativeToVisibleRows`
              const visibleSortedRows = sortedRowIds.filter((rowId) => {
                return (
                  apiRef.current?.getRowIndexRelativeToVisibleRows(rowId) !== -1
                ); // Row must be in visible rows
              });

              // Step 3: Find index of current row in visible sorted rows
              const currentIndex = visibleSortedRows.indexOf(currentRowId);
              const currentRow = apiRef.current?.getRow(currentRowId);

              // Step 4: Check if the row is deleted (applies even to first row)
              const isDeleted =
                currentRow?.state === "STORNO"
                  ? "order-deleted"
                  : currentRow?.state === "NOT_NEEDED"
                  ? "order-not-needed"
                  : "";

              if (currentIndex === 0) {
                return isDeleted; // First row doesn't compare with previous row but can be deleted
              }

              // Step 5: Get previous row ID and row data
              const previousRowId = visibleSortedRows[currentIndex - 1];
              const previousRow = apiRef.current?.getRow(previousRowId);

              // Ensure both rows exist and have a valid `date` property
              if (
                !currentRow ||
                !previousRow ||
                !currentRow.date ||
                !previousRow.date
              ) {
                return isDeleted;
              }

              // Step 6: Compare dates
              const currentDate = dayjs(currentRow.date, "YYYY-MM-DD");
              const previousDate = dayjs(previousRow.date, "YYYY-MM-DD");

              const isDateDifferent = currentDate.isSame(previousDate, "day")
                ? ""
                : "date-different-row";

              // Step 7: Combine classes
              return `${isDateDifferent} ${isDeleted}`.trim(); // Trim ensures no extra spaces if one class is empty
            }}
            {...gridProps}
          />
        )}
      </div>
    </Paper>
  );
}
