import ApiService from "@/api/ApiService";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { Box, CircularProgress, Paper, Tooltip } from "@mui/material";

import { DataGrid, useGridApiRef } from "@mui/x-data-grid";

import dayjs from "dayjs";
import useRights from "@/shared/api/useRights";
import { usePersistantStore } from "@/stores/global";
import { date } from "@/shared/reactdatagrid-community/filterTypes";
import { Fragment } from "react/jsx-runtime";
import {
  ArrowForward,
  East,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import { useState } from "react";

export default function Bauhof({}: {}) {
  const rights = useRights();
  const apiRef = useGridApiRef();

  const region = usePersistantStore((state) => state.region);

  const materialQuery = useQuery<any, AxiosResponse>({
    queryKey: ["getBauhof", "" + region],
    queryFn: ApiService.getBauhofTransport(region),
  });

  const [hidden, setHidden] = useState([]);

  function toggleHidden(date: string): void {
    setHidden((prevHidden) =>
      prevHidden.includes(date)
        ? prevHidden.filter((d) => d !== date)
        : [...prevHidden, date]
    );
  }

  return (
    <Box
      sx={{
        overflow: "hidden",
        display: "flex",
        p: 1,
        flexDirection: "column",
      }}
    >
      <div className="calListWrapper">
        {materialQuery.isLoading ? (
          <CircularProgress />
        ) : materialQuery.isLoadingError ? (
          "Error"
        ) : (
          Object.entries(materialQuery.data)
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map(([date, content]) => (
              <Fragment key={date}>
                <div
                  className="bh_dateheader"
                  onClick={() => toggleHidden(date)}
                >
                  {hidden.includes(date) ? (
                    <ExpandMore fontSize="large" />
                  ) : (
                    <ExpandLess fontSize="large" />
                  )}
                  <div className="bh_date">
                    {dayjs(date, "YYYY-MM-DD").format("ddd, DD.MM.YYYY")}
                  </div>
                </div>
                {hidden.includes(date) ? null : (
                  <div className="bh_grid">
                    {Object.values(content)
                      .sort(
                        (a, b) =>
                          a.type.localeCompare(b.type) ||
                          a.name.localeCompare(b.name)
                      )
                      .map((x) => (
                        <Paper
                          key={x.id}
                          className={"bh_paper bh_papertype" + x.type}
                        >
                          <div className="bh_xheader">
                            <div className="bh_title">
                              {!!x.number ? (
                                <span className="bh_number">{x.number}</span>
                              ) : null}
                              {x.name}
                            </div>
                            {!!x.crews ? (
                              <div className="bh_crews">
                                {x.crews.join("; ")}
                              </div>
                            ) : null}
                          </div>
                          <div className="bh_devicelist">
                            {x.content
                              .sort((a, b) => a.number.localeCompare(b.number))
                              .map((resource) => (
                                <Tooltip
                                  title={
                                    !!resource.meta ? (
                                      <div>
                                        zuvor bis{" "}
                                        {dayjs(
                                          resource.meta.date,
                                          "YYYY-MM-DD"
                                        ).format("ddd, DD.MM.YYYY")}{" "}
                                        bei{" "}
                                        {!!resource.meta.number ? (
                                          <span className="bh_number">
                                            {resource.meta.number}
                                          </span>
                                        ) : null}
                                        {resource.meta.name}{" "}
                                        {!!resource.meta.crews
                                          ? "(" +
                                            resource.meta.crews.join("; ") +
                                            ")"
                                          : ""}
                                      </div>
                                    ) : null
                                  }
                                >
                                  <div
                                    className={
                                      "bh_resource" +
                                      (!!resource.meta &&
                                      !resource.meta.name.includes("Bauhof")
                                        ? " bh_resource_meta"
                                        : "")
                                    }
                                  >
                                    <div className="bh_easticon">
                                      <East fontSize="small" />
                                    </div>
                                    <div className="bhname">
                                      <span className="bh_number">
                                        {resource.number}
                                      </span>
                                      {resource.name}
                                    </div>
                                  </div>
                                </Tooltip>
                              ))}
                          </div>
                        </Paper>
                      ))}
                  </div>
                )}
              </Fragment>
            ))
        )}
      </div>
    </Box>
  );
}
