import { useGlobalStore, usePersistantStore } from "@/stores/global";
import { Box, Paper, ToggleButton } from "@mui/material";
import { useEffect, useState } from "react";
import SelectPlantDialog from "./dialogs/SelectPlantDialog";
import MixingPlantView from "./listView/MixingPlantView";
import { DirectExternalMaterialBooking } from "./listView/DirectExternalMaterialBooking";
import { History, HistoryOutlined } from "@mui/icons-material";
import useRights from "@/shared/api/useRights";

export default function MixingPlants() {
  const setPageTitle = useGlobalStore((state) => state.setPageTitle);
  const rights = useRights();
  const reference = usePersistantStore((state) => state.plantReference ?? (("PLANT" in rights) ? rights["PLANT"] : "0"));
  const setReference = usePersistantStore((state) => state.setPlantReference);

  const [history, setHistory] = useState(false);

  useEffect(() => setPageTitle("Lieferungen"), []);

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          p: 1,
          background: "#eee",
        }}
      >
        <Paper
          sx={{ overflow: "hidden", display: "flex", flexDirection: "column" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                background: "#edf0f9",
                border: "1px solid rgba(0,0,0,0.2)",
                borderTopLeftRadius: "4px",
                borderTopRightRadius: "4px",
                display: "flex",
                columnGap: "5px",
                boxShadow:
                  "inset 0px -4px 5px rgba(0,0,0,0.1)" /*borderBottom: "none"*/,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  ml: "5px",
                  justifyContent: "space-between",
                }}
              >
                <SelectPlantDialog
                  reference={reference}
                  onChange={(reference) => {
                    console.log(reference);
                    setReference(reference);
                  }}
                />
                <ToggleButton
                  size="small"
                  value="check"
                  selected={history}
                  onChange={() => setHistory((prevSelected) => !prevSelected)}
                >
                  <History sx={{mr: 1}} fontSize="small" /> Vergangenheit
                </ToggleButton>
                {(!(""+reference).includes(",")) && !isNaN(parseInt(reference)) && parseInt(reference) > 0 ? (
                  <DirectExternalMaterialBooking plant={parseInt(reference)} />
                ) : null}
              </Box>
            </Box>
            <MixingPlantView history={history} reference={reference} />
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
