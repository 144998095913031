import { Box, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";

interface MaterialSummaryTableProps {
  material: Record<string, number>;
}

const MaterialSummaryTable: React.FC<MaterialSummaryTableProps> = ({ material }) => {
  if (!material || Object.keys(material).length === 0) return null;

  return (
    <Paper variant={"outlined"} sx={{width: "100%", overflow: "hidden"}}>
      <Table size="small" sx={{tableLayout: "fixed"}}>
        <TableHead>
          <TableRow>
            <TableCell sx={{fontWeight: "bold"}}>Material</TableCell>
            <TableCell sx={{width: "100px",fontWeight: "bold"}} align="right">Menge</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(material).sort((a,b) => a[0].localeCompare(b[0])).map(([name, [amount, number, unit]]) => (
            <TableRow key={name}>
              <TableCell><Box title={number} sx={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>{name}</Box></TableCell>
              <TableCell align="right">{amount.toFixed(2).replace(".", ",")}&nbsp;{unit}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default MaterialSummaryTable;
