import ApiService from "@/api/ApiService";
import { usePersistantStore } from "@/stores/global";
import {
  Box,
  CircularProgress,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { Fragment } from "react/jsx-runtime";
import { PossibleCalendarTypes } from "./MainCalendar";
import ManyToOneSelect from "@/shared/forms/ManyToOneSelect";
import { AxiosResponse } from "axios";
import { useCallback, useState } from "react";
import FreeContainer from "./FreeContainer";
import {
  ArrowBackIos,
  ArrowForwardIos,
  BeachAccess,
  Bolt,
  Delete,
  Search,
} from "@mui/icons-material";
import CollisionContainer from "./CollisionContainer";

interface OpenRequest {
  fromdate: string;
  todate: string;
  pname: string;
  pnum: string;
  resource_tag_id: number;
  open: number;
  roid: number;
  comment: string;
  name: string;
  TYPE: "RESOURCE" | "CREW" | "HUMAN";
}

export default function OpenRequests({
  setCalendar,
  type,
  pdfref,
}: {
  type: "LONG" | "SHORT";
  pdfref: any;
  setCalendar: (
    type: PossibleCalendarTypes,
    reference: number,
    date: dayjs.Dayjs
  ) => void;
}) {
  const region = usePersistantStore((store) => store.region);
  const dispoCategory = usePersistantStore((store) => store.dispoCategory);
  const setDispoCategory = usePersistantStore(
    (store) => store.setDispoCategory
  );

  const [xtype, setXtype] = useState<"OPEN" | "FREE" | "COLLISIONS">("OPEN");
  const [week, setWeek] = useState(dayjs().startOf("isoWeek"));

  const query = useQuery<OpenRequest[], AxiosResponse>({
    queryKey: ["getOpenRequests", "" + region, dispoCategory, type],
    enabled: !!dispoCategory,
    queryFn: ApiService.getOpenRequests(region, dispoCategory, type),
  });

  const freeQuery = useQuery<any[], AxiosResponse>({
    queryKey: [
      "getOpenRequests",
      "free",
      "" + region,
      dispoCategory,
      week.format("YYYY-MM-DD"),
    ],
    enabled: !!dispoCategory && type === "LONG" && !!region,
    queryFn: ApiService.getFreeRequests(
      region,
      dispoCategory,
      week.format("YYYY-MM-DD")
    ),
  });

  const collisionQuery = useQuery<any[], AxiosResponse>({
    queryKey: ["getOpenRequests", "collisions", "" + region, dispoCategory],
    enabled: !!dispoCategory && type === "LONG" && !!region,
    queryFn: ApiService.getCollisions(region, dispoCategory),
  });

  const [showThrash, setShowThrash] = useState(false);

  const dropPart = useCallback((e) => {
    const type = e.dataTransfer.types[0];
    setShowThrash(false);
    if (type !== "application/resource-booking") {
      return false;
    }

    const parts = e.dataTransfer.getData(e.dataTransfer.types[0]).split("#");
    const resourceBookingId = parseInt(parts[0]);
    const originDate = parts[1];
    const resourceId = parseInt(parts[2] ?? "0");
    const resourceType = parts[3] ?? "NONE";

    pdfref.current?.openModal({
      resourceBookingId,
      originDate,
      resourceId,
      resourceType,
      startDate: originDate,
      jobId: 0,
    });
  }, []);

  console.log(showThrash);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        boxShadow: "0px -2px 4px #0000004a",
        maxWidth: "250px",
        borderLeft: "1px solid #888",
        position: "relative",
      }}
      onDrop={(e) => dropPart(e)}
      onDragLeave={(e) => {
        e.preventDefault();
        setShowThrash(false);
      }}
      onDragOver={(e) => {
        e.preventDefault();
        setShowThrash(
          e.dataTransfer.types[0] === "application/resource-booking"
        );
      }}
      onDragEnter={(e) => {
        e.preventDefault();
        setShowThrash(
          e.dataTransfer.types[0] === "application/resource-booking"
        );
      }}
    >
      <Box sx={{ minWidth: "160px", borderBottom: "1px solid #888" }}>
        <ManyToOneSelect
          sx={{
            m: 0,
            "& .MuiOutlinedInput-notchedOutline": { border: "none" },
            "& .MuiAutocomplete-clearIndicator": { display: "none !important" },
          }}
          remoteFilter={{ region: ["region", region] }}
          entity={"dispoCategory"}
          size="small"
          currentId={dispoCategory}
          disabled={false}
          setId={(id) => !id || setDispoCategory(id)}
          showWithoutChoice
          autoSelect
        />
      </Box>
      {type === "LONG" ? (
        <Box
          sx={{
            borderBottom: "1px solid #aaa",
            cursor: "pointer",
            display: "flex",
          }}
        >
          <Box
            className={xtype === "OPEN" ? "act" : ""}
            sx={{
              "&:last-child": { borderRight: "none" },
              borderRight: "1px solid #aaa",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
              background: "#eee",
              color: "#555",
              "&:hover": {
                background: "#ddd",
                color: "#666",
              },
              "&.act": {
                background: "#fff",
                color: "#000",
              },
            }}
            onClick={() => setXtype("OPEN")}
          >
            <Search />
          </Box>
          <Box
            className={xtype === "FREE" ? "act" : ""}
            sx={{
              "&:last-child": { borderRight: "none" },
              borderRight: "1px solid #aaa",
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "#eee",
              color: "#555",
              "&:hover": {
                background: "#ddd",
                color: "#666",
              },
              "&.act": {
                background: "#fff",
                color: "#000",
              },
            }}
            onClick={() => setXtype("FREE")}
          >
            <BeachAccess />
          </Box>
          <Box
            className={
              (xtype === "COLLISIONS" ? "act" : "") +
              ((collisionQuery?.data ?? []).length > 0 ? " collwarning" : "")
            }
            sx={{
              "&:last-child": { borderRight: "none" },
              borderRight: "1px solid #aaa",
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "#eee",
              color: "#555",
              "&:hover": {
                background: "#ddd",
                color: "#666",
              },
              "&.act": {
                background: "#fff",
                color: "#000",
              },
            }}
            onClick={() => setXtype("COLLISIONS")}
          >
            <Bolt />
          </Box>
        </Box>
      ) : null}
      {xtype === "FREE" ? (
        <Box
          sx={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
            borderBottom: "1px solid #aaa",
          }}
        >
          <IconButton
            size="small"
            onClick={() => setWeek(week.subtract(1, "week"))}
          >
            <ArrowBackIos fontSize="small" />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              minWidth: "145px",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              cursor: "pointer",
            }}
          >
            <Typography variant="h6">KW {week.format("W/GGGG")}</Typography>
          </Box>
          <IconButton size="small" onClick={() => setWeek(week.add(1, "week"))}>
            <ArrowForwardIos fontSize="small" />
          </IconButton>
        </Box>
      ) : null}
      <Box
        sx={{
          overflow: "auto",
          position: "relative",
          background: "#fafafa",
          flex: 1,
        }}
      >
        {xtype === "COLLISIONS" ? (
          <CollisionContainer
            setCalendar={setCalendar}
            query={collisionQuery}
          />
        ) : xtype === "FREE" ? (
          <FreeContainer
            draggable={true}
            setCalendar={setCalendar}
            query={freeQuery}
          />
        ) : query.isLoading ? (
          <CircularProgress />
        ) : query.isLoadingError ? (
          "Error"
        ) : (query?.data ?? []).length === 0 ? (
          <Box
            sx={{ textAlign: "center", color: "#777", p: 1, fontSize: "14px" }}
          >
            Keine offenen Anfragen
          </Box>
        ) : (
          (query?.data ?? []).map((x, i, f) => (
            <Fragment key={x.roid + "_" + x.open}>
              {i === 0 || f[i - 1].fromdate !== x.fromdate ? (
                <Box
                  sx={{
                    position: "sticky",
                    top: 0,
                    color: "#555",
                    p: "4px 6px",
                    lineHeight: 1,
                    fontWeight: "14px",
                    mb: "8px",
                    flexShrink: 0,
                    background: "#edf0f9",
                    borderTop: "1px solid rgba(0,0,0,0.2)",
                    borderBottom: "1px solid rgba(0,0,0,0.2)",
                    boxShadow: "inset 0px -4px 5px rgba(0,0,0,0.1)",
                  }}
                >
                  ab {dayjs(x.fromdate).format("DD.MM.YYYY")}
                </Box>
              ) : null}
              <Box
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    opacity: 0.5,
                  },
                  display: "flex",
                  flexDirection: "column",
                  border: "1px solid #777",
                  mb: "8px",
                  mx: "4px",
                  alignItems: "stretch",
                  overflow: "hidden",
                  borderRadius: "5px",
                  flexShrink: 0,
                  fontSize: "13px",
                }}
                onClick={() =>
                  setCalendar(
                    x.TYPE === "CREW"
                      ? "CREWS"
                      : x.TYPE === "RESOURCE"
                      ? "RESOURCES"
                      : "STAFF",
                    x.resource_tag_id,
                    dayjs(x.fromdate, "YYYY-MM-DD")
                  )
                }
              >
                <Box sx={{ display: "flex", borderBottom: "1px solid #777" }}>
                  <Box
                    sx={{
                      borderRight: "1px solid #aaa",
                      color: "#333",
                      boxShadow: "inset -4px 0px 5px rgba(0,0,0,0.1)",
                      p: "4px 6px",
                      lineHeight: 1,
                      background: "#edf0f9",
                    }}
                  >
                    {x.open}
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                      p: "4px",
                      overflow: "hidden",
                      lineHeight: 1,
                      textTransform: "uppercase",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {(x.TYPE === "CREW"
                      ? "Kol. "
                      : x.TYPE === "HUMAN"
                      ? "Ma. "
                      : "") + x.name}
                  </Box>
                  <Box
                    sx={{
                      borderLeft: "1px solid #aaa",
                      color: "#333",
                      boxShadow: "inset -4px 0px 5px rgba(0,0,0,0.1)",
                      p: "4px 6px",
                      fontSize: "12px",

                      lineHeight: "14px",

                      background: "#edf0f9",
                    }}
                  >
                    &rarr;{dayjs(x.todate).format("DD.MM.")}
                  </Box>
                </Box>
                <Box
                  sx={{
                    lineHeight: 1,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    p: "2px",
                    backgroundColor: "#eee",
                  }}
                >
                  <span style={{ fontSize: "12px", marginRight: "2px" }}>
                    {x.pnum}
                  </span>
                  {x.pname}
                </Box>
                {x.comment.length > 0 ? (
                  <Box
                    sx={{
                      display: "-webkit-box",
                      p: "2px",
                      pt: "3px",
                      borderTop: "1px solid #aaa",
                      "-webkit-line-clamp": "2",
                      "-webkit-box-orient": "vertical",
                      overflow: "hidden",
                      fontSize: "12px", lineHeight: 1.1, textAlign: "right",
                      backgroundColor: "#f7f7f7",
                      boxShadow: "0 2px 3px rgba(0,0,0,0.1) inset",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {x.comment}
                  </Box>
                ) : null}
              </Box>
            </Fragment>
          ))
        )}
      </Box>
      <div className={"thrash-overlay" + (showThrash ? " to-visible" : "")}>
        <Delete fontSize="inherit" />
      </div>
    </Box>
  );
}
