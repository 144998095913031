const here_logistics_day =
  "https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?lang=de&size=512&ppi=400&style=logistics.day&features=vehicle_restrictions:active_and_inactive&apiKey=pegZ-uUwbH06WeXyUQOIsCwaVv_m9u4wCYWAKKJmH6k";
const here_satellite_map =
  "https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?lang=de&size=512&ppi=400&style=explore.satellite.day&features=vehicle_restrictions:active_and_inactive&apiKey=pegZ-uUwbH06WeXyUQOIsCwaVv_m9u4wCYWAKKJmH6k";
const here_attribution = "&copy; HERE";


export {
  here_logistics_day as logistics_day,
  here_satellite_map as satellite_map,
  here_attribution as attribution,
};