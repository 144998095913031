import ApiService from "@/api/ApiService";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { MaterialOrder } from "@/components/planning/Project";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
} from "@mui/material";

import { GridColDef, DataGrid, useGridApiRef } from "@mui/x-data-grid";

import {
  ChatBubble,
  ChatBubbleOutline,
  CheckBox,
  CheckBoxOutlineBlank,
  CheckCircle,
  Close,
  DeleteForever,
  DraftsOutlined,
  Edit,
  Email,
  EmailOutlined,
  Event,
  HowToReg,
  ManageSearch,
  MyLocation,
  NewReleases,
  RemoveCircle,
  ReportProblem,
  Thunderstorm,
  Warehouse,
} from "@mui/icons-material";
import dayjs from "dayjs";
import {
  createArticleText,
  createDeliveryText,
} from "@/components/planning/editors/MaterialTab";
import useRights from "@/shared/api/useRights";
import { DirectExternalMaterialBooking } from "./DirectExternalMaterialBooking";
import { DirectExternalMaterialEditor } from "./DirectExternalMaterialEditor";
import { Fragment } from "react/jsx-runtime";
import MachineTransportWrap from "@/components/machinetransport/MachineTransportWrap";
import { usePersistentDataGrid } from "@/shared/hooks/usePersistentDataGrid";
import { useEffect, useState } from "react";
import OrderHistoryDialog from "../dialogs/OrderHistoryDialog";
import OrderLocationDialog from "../dialogs/OrderLocationDialog";
import { usePersistantStore } from "@/stores/global";

interface ResourceTag {
  id: number;
  name: string;
  type: "HUMAN" | "RESOURCE" | "CREW";
  calendarType: "NONE" | "CALENDAR_ONLY" | "WISHABLE" | "REQUESTABLE";
}

export interface StaffMember {
  id: number;
  name: string;
  tags: ResourceTag[];
}

export interface Crew {
  id: number;
  name: string;
}

export interface Resource {
  id: number;
  name: string;
  tags: ResourceTag[];
  number: string;
}

interface ResourceBooking {
  id: number;
  crew: Crew | null;
  isLeader: boolean;
  resource: Resource | null;
  staffMember: StaffMember | null;
  order: ResourceOrder;
  type: "CREW" | "RESOURCE" | "HUMAN";
}

interface ResourceOrder {
  id: number;
  amount: number;
  tag: ResourceTag | null;
  comment: string;
  type: "CREW" | "RESOURCE" | "HUMAN";
  job: number | null;
  phase: number | null;
  deleted: boolean;
  references: (number | null)[];
  exceptions: string[];
}

export interface WithOrders {
  materialOrders: MaterialOrder[];
  resourceOrders: {
    resourceBookings: ResourceBooking[];
    resourceOrders: ResourceOrder[];
  };
}

export type TransState =
  | "OPEN"
  | "SENT"
  | "ACCEPTED"
  | "RETRACTED"
  | "CHANGED"
  | "MOVED"
  | "STORNO"
  | "DELETED";

export const transStates: Record<
  TransState,
  {
    icon: typeof Email;
    next: TransState;
    title: string;
    iconColorOnWhite: string;
    iconColorOnBackground: string;
    backgroundColor: string;
    needsAction: boolean;
  }
> = {
  OPEN: {
    icon: Email,
    next: "SENT",
    title: "Neue Bestellung",
    iconColorOnWhite: "darkblue",
    iconColorOnBackground: "white",
    backgroundColor: "white",
    needsAction: true,
  },
  RETRACTED: {
    icon: Close,
    next: "RETRACTED",
    title: "Zurückgezogen",
    iconColorOnWhite: "#777",
    iconColorOnBackground: "white",
    backgroundColor: "white",
    needsAction: false,
  },
  SENT: {
    icon: DraftsOutlined,
    next: "ACCEPTED",
    title: "Gesehen",
    iconColorOnWhite: "green",
    iconColorOnBackground: "darkgreen",
    backgroundColor: "lightgreen",
    needsAction: false,
  },
  ACCEPTED: {
    icon: CheckCircle,
    next: "ACCEPTED",
    title: "Akzeptiert",
    iconColorOnWhite: "green",
    iconColorOnBackground: "darkgreen",
    backgroundColor: "lightgreen",
    needsAction: false,
  },
  CHANGED: {
    icon: NewReleases,
    next: "ACCEPTED",
    title: "Geändert",
    iconColorOnWhite: "orange",
    iconColorOnBackground: "black",
    backgroundColor: "orange",
    needsAction: true,
  },
  MOVED: {
    icon: Event,
    next: "ACCEPTED",
    title: "Verschoben",
    iconColorOnWhite: "orange",
    iconColorOnBackground: "black",
    backgroundColor: "orange",
    needsAction: true,
  },
  STORNO: {
    icon: DeleteForever,
    next: "DELETED",
    title: "Storniert",
    iconColorOnWhite: "red",
    iconColorOnBackground: "white",
    backgroundColor: "darkred",
    needsAction: true,
  },
  DELETED: {
    icon: RemoveCircle,
    next: "STORNO",
    title: "Gelöscht",
    iconColorOnWhite: "#777",
    iconColorOnBackground: "darkred",
    backgroundColor: "red",
    needsAction: false,
  },
};

function getColumns(
  modalSetter: (id: number) => void,
  withPlant: boolean,
  writePlantId: number,
  transReadOnly: boolean
): GridColDef[] {
  const cells: GridColDef[] = [];

  if (withPlant) {
    cells.push({
      headerName: "Werk",
      flex: 1,
      field: "plant",
      valueGetter: (value, row) => {
        return row.type === "manual"
          ? row.plantName
          : !row.materialOrder.article
          ? ""
          : row.materialOrder.article.plantName;
      },
    });
  }
  return cells.concat([
    {
      field: "date",
      headerName: "Datum",
      width: 150,
      type: "date",
      display: "flex",
      valueGetter: (value, row) => dayjs(row.date, "YYYY-MM-DD").toDate(),
      renderCell: ({ row }) => (
        <Fragment>
          {!!row.badWeather ? (
            <Tooltip title={row.badWeather.comment}>
              <Thunderstorm fontSize="small" sx={{ mr: 1 }} />
            </Tooltip>
          ) : null}
          {dayjs(row.date, "YYYY-MM-DD").format("ddd, DD.MM.YYYY") +
            (row?.duration === "HALF" ? " - halbtags" : "")}
        </Fragment>
      ),
    },
    {
      field: "loadtime",
      headerName: "LKW an Waage",
      width: 100,
      valueGetter: (_, row) =>
        ((ts) =>
          !ts
            ? "-"
            : !ts.startsWith("x")
            ? ts
            : ts === "xAM"
            ? "vormittags"
            : ts === "xPM"
            ? "nachmittags"
            : "???")(row.type === "manual" ? "-" : row.materialOrder.firstLoad),
    },
    {
      field: "time",
      headerName: "LKW an BS",
      width: 100,
      valueGetter: (_, row) =>
        ((ts) =>
          !ts
            ? "anschließend"
            : !ts.startsWith("x")
            ? ts
            : ts === "xAM"
            ? "vormittags"
            : ts === "xPM"
            ? "nachmittags"
            : "???")(
          row.type === "manual" ? row.firstLoad : row.materialOrder.firstUnload
        ),
    },
    {
      field: "au",
      headerName: "AU-Nr.",
      flex: 1,
      valueGetter: (_, row) =>
        row.type === "manual" ? "-" : row.materialOrder?.position?.au || "-",
    },
    {
      field: "costbase",
      headerName: "Kostenstelle",
      width: 100,
      valueGetter: (_, row) =>
        row.type === "manual" ? row.number : row.phase.project.number,
    },
    {
      field: "project",
      headerName: "Baustelle",
      flex: 1,
      valueGetter: (_, row) =>
        row.type === "manual" ? row.project : row.phase.project.name,
    },
    {
      field: "manager",
      headerName: "Bauleiter",
      flex: 1,
      valueGetter: (_, row) =>
        row.type === "manual"
          ? "-"
          : !!row.phase.project.manager
          ? row.phase.project.manager.name +
            ", " +
            row.phase.project.manager.firstName
          : "",
    },
    {
      field: "crew",
      headerName: "Kolonne",
      flex: 1,
      valueGetter: (_, row) =>
        row.type === "manual"
          ? row.contact
          : Object.values(row.crews).join("; "),
    },
    {
      field: "artikel",
      headerName: "Artikel",
      display: "flex",
      flex: 2,
      renderCell: ({ row }) =>
        row.type === "manual" ? (
          <Fragment>
            <strong>{Math.round(row.amount)} t</strong>&nbsp;{row.article}
          </Fragment>
        ) : (
          createArticleText(row.materialOrder, false)
        ),
      sortable: false,
    },
    {
      field: "productgroup",
      headerName: "Produktgruppe",
      flex: 1,
      valueGetter: (_, row) =>
        row.type === "manual"
          ? "extern"
          : ((pg) => {
              switch (pg) {
                case "ASPHALT":
                  return "Asphalt";
                case "FRAESGUT":
                  return "Fräsgut";
                default:
                  return "-";
              }
            })(row.materialOrder?.article?.productGroup),
    },

    {
      field: "delivery",
      headerName: "Logistik",
      flex: 2,
      valueGetter: (_, row) =>
        row.type === "manual"
          ? row.logistics
          : createDeliveryText(
              row.materialOrder.transportInformation,
              row.materialOrder.takt
            ),
      sortable: false,
    },
    {
      field: "comment",
      flex: 1,
      headerName: "Weitere Informationen",
      valueGetter: (_, row) =>
        row.type === "manual"
          ? row.info
          : [
              !row.materialOrder.position &&
              !!row.materialOrder.article &&
              !row.materialOrder.article.bauhof
                ? "Unbepreister Artikel!"
                : "",
              row.materialOrder.easyTarget === "LAGER"
                ? "Anlieferung zum Lagerplatz!"
                : "",
              row.materialOrder.comment,
            ]
              .filter((x) => x.length > 0)
              .join(" - "),
    },
    {
      field: "state",
      width: 100,
      headerName: "Bestellstatus",
      valueGetter: (_, row) =>
        row.type === "manual"
          ? ""
          : (row.status === "PLANNING"
              ? "in Planung"
              : row.status === "RAW"
              ? "grobgeplant"
              : row.status === "READY"
              ? "grobgeplant"
              : "feingeplant") + (!!row.orderSentBy ? ", bestellt" : ""),
    },
    {
      field: "creation",
      headerName: "Erstelldatum",
      type: "date",
      flex: 1,
      valueGetter: (value, row) =>
        dayjs.utc(row.materialOrder?.createdAt).toDate(),
      valueFormatter: (value, row) => {
        return row.type === "manual"
          ? "-"
          : dayjs
              .utc(row.materialOrder?.createdAt)
              .format("ddd, DD.MM.YYYY HH:mm:ss");
      },
    },
    {
      field: "transportState",
      display: "flex",
      width: 80,
      headerName: "trans",
      renderCell: (params) =>
        params.row.type === "manual" ? null : (
          <TransState
            readOnly={transReadOnly}
            id={params.row.materialOrder.id}
            state={params.row.materialOrder.transportStatus}
          />
        ),
    },
    {
      field: "plantState",
      display: "flex",
      width: 80,
      headerName: "Werk",
      renderCell: (params) =>
        params.row.type === "manual" ? (
          writePlantId !== params.row.plant ? null : (
            <DirectExternalMaterialEditor
              plant={params.row.plant}
              order={params.row}
            />
          )
        ) : (
          <Box
            sx={{ display: "flex", flex: 1, justifyContent: "space-between" }}
          >
            <PlantState
              readOnly={
                params.row.materialOrder?.article?.plantId !== writePlantId
              }
              isBauhof={
                !!params.row.materialOrder.article &&
                !!params.row.materialOrder.article.bauhof
              }
              id={params.row.materialOrder.id}
              state={params.row.materialOrder.plantStatus}
            />
            {!!params.row.materialOrder.article &&
            !!params.row.materialOrder.article.bauhof ? null : (
              <PlantComment
                readOnly={
                  params.row.materialOrder?.article?.plantId !== writePlantId
                }
                materialOrder={params.row.materialOrder}
              />
            )}
          </Box>
        ),
    },
    {
      field: "info",
      display: "flex",
      width: 80,
      headerName: "Historie",
      renderCell: (params) =>
        params.row.type === "manual" ? null : (
          <Tooltip title={"Änderungshistorie"}>
            <ManageSearch
              sx={{ cursor: "pointer", "&:hover": { color: "#666" } }}
              onClick={() =>
                modalSetter(["HISTORY", params.row.materialOrder.id])
              }
            />
          </Tooltip>
        ),
    },
    {
      field: "target",
      display: "flex",
      width: 80,
      headerName: "Ziel",
      renderCell: (params) =>
        params.row.type === "manual" ? null : (
          <Tooltip title={"Zielort anzeigen"}>
            <MyLocation
              sx={{ cursor: "pointer", "&:hover": { color: "#666" } }}
              onClick={() =>
                modalSetter(["LOCATION", params.row.materialOrder.id])
              }
            />
          </Tooltip>
        ),
    },
  ]);
}

const PlantComment = ({
  materialOrder,
  readOnly,
}: {
  materialOrder: any;
  readOnly: boolean;
}) => {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState("");
  const changeMutation = useMutation<any, AxiosResponse, any>({
    mutationFn: ApiService.setPlantStatus(),
    onSettled: (answer) => {
      queryClient.invalidateQueries({ queryKey: ["getMaterialCalendar"] });
      close();
    },
  });

  const close = () => {
    setOpen(false);
  };
  const openModal = () => {
    setComment(materialOrder.plantComment ?? "");
    setOpen(true);
  };
  const save = () =>
    changeMutation.mutate({ plantComment: comment, id: materialOrder.id });

  const Icon =
    materialOrder.plantComment.length > 0 ? ChatBubble : ChatBubbleOutline;

  return (
    <div className="mtcommentwrap">
      <Icon
        fontSize="inherit"
        sx={{
          cursor: "pointer",
          marginLeft: "4px",
          verticalAlign: "middle",
          color: "#444",
          "&:hover": { color: "#666" },
        }}
        onClick={openModal}
      />
      {open ? (
        <Dialog open={open} maxWidth="md" fullWidth onClose={close}>
          <DialogTitle
            sx={{
              p: 1,
              px: 2,
              background: "#002169",
              color: "white",
              borderBottom: "1px solid rgba(255,255,255,0.5)",
            }}
          >
            Interner Werkskommentar
          </DialogTitle>
          <DialogContent
            sx={{
              p: 1,
            }}
          >
            <TextField
              size="small"
              sx={{ mt: 1 }}
              fullWidth
              value={comment}
              placeholder="Kommentar"
              multiline
              onChange={(e) => setComment((e.target as HTMLInputElement).value)}
            />
          </DialogContent>
          <DialogActions
            sx={{
              borderTop: "1px solid #aaa",
              justifyContent: "space-between",
            }}
          >
            <Button onClick={close}>Schließen</Button>
            {!readOnly ? (
              <Button variant="contained" onClick={save}>
                Speichern
              </Button>
            ) : null}
          </DialogActions>
        </Dialog>
      ) : null}
    </div>
  );
};

type WerkState =
  | "OPEN"
  | "RETRACTED"
  | "READ"
  | "CRITICAL"
  | "PACKED"
  | "CHANGED"
  | "MOVED"
  | "STORNO"
  | "DELETED";

export const states: Record<
  WerkState,
  [typeof Email, WerkState, string, string]
> = {
  OPEN: [Email, "READ", "Neue Bestellung", "darkblue"],
  CRITICAL: [ReportProblem, "OPEN", "Kritisch", "darkred"],
  READ: [DraftsOutlined, "CRITICAL", "Gesehen", "green"],
  PACKED: [Warehouse, "READ", "Gepackt", "grey"],
  CHANGED: [NewReleases, "READ", "Geändert", "orange"],
  RETRACTED: [Close, "RETRACTED", "Zurückgezogen", "#777"],
  MOVED: [Event, "READ", "Verschoben", "orange"],
  STORNO: [DeleteForever, "DELETED", "Storniert", "red"],
  DELETED: [RemoveCircle, "STORNO", "Gelöscht", "#777"],
};

export const PlantState = ({
  state,
  id,
  readOnly,
  isBauhof,
}: {
  state: WerkState;
  id: number;
  readOnly: boolean;
  isBauhof: boolean;
}) => {
  const queryClient = useQueryClient();
  const changeMutation = useMutation<any, AxiosResponse, ChangeData>({
    mutationFn: ApiService.setPlantOrderStatus(id),
    onSettled: (answer) => {
      queryClient.invalidateQueries({ queryKey: ["getMaterialCalendar"] });
    },
  });

  const Icon = states[state][0];

  return changeMutation.isPending ? (
    <CircularProgress />
  ) : (
    <Tooltip title={states[state][2]}>
      <Icon
        sx={{
          cursor: "pointer",
          color: states[state][3],
          "&:hover": { color: "#666" },
        }}
        onClick={
          readOnly
            ? undefined
            : () =>
                changeMutation.mutate(
                  state === "READ" && isBauhof ? "PACKED" : states[state][1]
                )
        }
      />
    </Tooltip>
  );
};

export const TransState = ({
  state,
  id,
  readOnly,
}: {
  state: TransState;
  id: number;
  readOnly: boolean;
}) => {
  const [cstate, setCstate] = useState(state);
  const queryClient = useQueryClient();
  const changeMutation = useMutation<any, AxiosResponse, ChangeData>({
    mutationFn: ApiService.setTransportOrderStatus(id),
    onSettled: (answer) => {
      setCstate((sstate) => transStates[sstate].next);
      queryClient.invalidateQueries({ queryKey: ["getMaterialCalendar"] });
    },
  });

  useEffect(() => setCstate(state), [state]);

  const Icon = transStates[cstate].icon;

  return changeMutation.isPending ? (
    <CircularProgress />
  ) : (
    <Tooltip title={transStates[cstate].title}>
      <Icon
        sx={{
          cursor: "pointer",
          color: transStates[cstate].iconColorOnWhite,
          "&:hover": { color: "#666" },
        }}
        onClick={
          readOnly
            ? undefined
            : () => changeMutation.mutate(transStates[cstate].next)
        }
      />
    </Tooltip>
  );
};

export default function MixingPlantView({
  reference,
  history,
}: {
  reference: string | null;
  history: boolean;
}) {
  const rights = useRights();
  const region = usePersistantStore((r) => r.region);
  const apiRef = useGridApiRef();
  const [modalOpen, setModalOpen] = useState(null);

  const gridProps = usePersistentDataGrid(
    "mixingplants",
    getColumns(
      setModalOpen,
      reference === "0" || (!!reference && ("" + reference).includes(",")),
      !("PLANT" in rights) ? 0 : parseInt(rights["PLANT"]),
      !("TRANSPORTDISPO" in rights)
    ),
    {
      columnVisibilityModel: {
        comment: false,
        crew: false,
        delivery: false,
        info: false,
        plantState: false,
        state: false,
        target: false,
        time: false,
        transportState: false,
      },
    }
  );

  const materialQuery = useQuery<any[], AxiosResponse>({
    queryKey: [
      "getMaterialCalendar",
      "" + reference,
      "" + region,
      history ? "history" : "none",
    ],
    queryFn: ApiService.getCalendar(
      "MATERIAL",
      reference,
      history ? "history" : "none",
      region
    ),
  });

  return (
    <div className="calListWrapper">
      <OrderHistoryDialog
        orderId={!!modalOpen && modalOpen[0] === "HISTORY" ? modalOpen[1] : 0}
        close={() => setModalOpen(null)}
      />
      <OrderLocationDialog
        orderId={!!modalOpen && modalOpen[0] === "LOCATION" ? modalOpen[1] : 0}
        close={() => setModalOpen(null)}
      />
      {materialQuery.isLoading ? (
        <CircularProgress />
      ) : materialQuery.isLoadingError ? (
        "Error"
      ) : (
        <DataGrid
          disableRowSelectionOnClick
          getRowHeight={({ model }) =>
            25 + (model.materialOrder?.additionalArticles?.length || 0) * 14
          }
          rows={materialQuery.data}
          apiRef={apiRef}
          getRowId={(row) =>
            row.type === "manual" ? row.id : row.materialOrder.id
          }
          loading={materialQuery.isLoading}
          getRowClassName={(params: any) => {
            const currentRowId = params.id;

            // Step 1: Get sorted row IDs (includes all rows, even filtered-out ones)
            const sortedRowIds = apiRef.current?.getSortedRowIds() || [];

            // Step 2: Filter only currently visible rows using `getRowIndexRelativeToVisibleRows`
            const visibleSortedRows = sortedRowIds.filter((rowId) => {
              return (
                apiRef.current?.getRowIndexRelativeToVisibleRows(rowId) !== -1
              ); // Row must be in visible rows
            });

            // Step 3: Find index of current row in visible sorted rows
            const currentIndex = visibleSortedRows.indexOf(currentRowId);
            const currentRow = apiRef.current?.getRow(currentRowId);

            // Step 4: Check if the row is deleted (applies even to first row)
            const isBadWeather = !!currentRow?.badWeather
              ? " order-badWeather"
              : "";
            const isDeleted = currentRow?.materialOrder?.deleted
              ? "order-deleted"
              : "";

            if (currentIndex === 0) {
              return isDeleted + isBadWeather; // First row doesn't compare with previous row but can be deleted
            }

            // Step 5: Get previous row ID and row data
            const previousRowId = visibleSortedRows[currentIndex - 1];
            const previousRow = apiRef.current?.getRow(previousRowId);

            // Ensure both rows exist and have a valid `date` property
            if (
              !currentRow ||
              !previousRow ||
              !currentRow.date ||
              !previousRow.date
            ) {
              return isDeleted;
            }

            // Step 6: Compare dates
            const currentDate = dayjs(currentRow.date, "YYYY-MM-DD");
            const previousDate = dayjs(previousRow.date, "YYYY-MM-DD");

            const isDateDifferent = currentDate.isSame(previousDate, "day")
              ? ""
              : "date-different-row";

            // Step 7: Combine classes
            return `${isDateDifferent} ${isDeleted} ${isBadWeather}`.trim(); // Trim ensures no extra spaces if one class is empty
          }}
          {...gridProps}
        />
      )}
    </div>
  );
}
