import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Fragment, useCallback, useEffect, useState } from "react";
import LabelWrapper from "../../root/LabelWrapper";

import TimePicker from "react-time-picker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { deDE } from "@mui/x-date-pickers/locales";

export interface ExternalOrder {
  info: string;
  logistics: string;
  article: string;
  amount: number;
  contact: string;
  project: string;
  number: string;
  firstLoad: string | null;
  date: string;
}

export default function AddExternalDialog({
  save,
  OpenButton,
  initialState,
}: {
  save: (order: ExternalOrder) => void;
  OpenButton: any;
  initialState: ExternalOrder;
}) {
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState("100");
  const [updateAmount, setUpdateAmount] = useState(false);
  const [state, setState] = useState<ExternalOrder>(initialState);

  useEffect(() => {
    if (!updateAmount) return;
    setUpdateAmount(false);
    setAmount("" + state.amount);
  }, [updateAmount]);

  const close = useCallback(() => {
    setOpen(false);
  }, []);

  const openAndSet = useCallback(() => {
    setState(initialState);
    setAmount("" + initialState.amount);
    setOpen(true);
  }, [initialState]);

  const valid = true;

  return (
   <LocalizationProvider
         adapterLocale="de"
         dateAdapter={AdapterDayjs}
         localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}
       >
      <OpenButton onClick={openAndSet} />
      <Dialog open={open} maxWidth="xl" fullWidth onClose={close}>
        <Fragment>
          <DialogTitle
            sx={{
              p: 1,
              px: 2,
              background: "#002196",
              color: "white",
              borderBottom: "1px solid rgba(255,255,255,0.5)",
            }}
          >
            Externer Auftrag
          </DialogTitle>
          <DialogContent
            sx={{
              p: 0,
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <LabelWrapper label="Datum">
              <DatePicker
                {...{
                  slotProps: { textField: { size: "small" } },
                  value: dayjs(state.date, "YYYY-MM-DD"),
                  onChange: (val) =>
                    setState((s) => ({ ...s, date: val.format("YYYY-MM-DD") })),
                  displayWeekNumber: true,
                }}
              />
            </LabelWrapper>
            <LabelWrapper label="Erster LKW an Waage">
              <ToggleButtonGroup
                size="small"
                orientation="horizontal"
                exclusive
                value={
                  !state.firstLoad
                    ? "none"
                    : state.firstLoad.startsWith("x")
                    ? state.firstLoad
                    : "custom"
                }
                onChange={(_, fu) =>
                  fu &&
                  setState((s) => ({
                    ...s,
                    firstLoad:
                      fu === "none" ? null : fu.startsWith("x") ? fu : "07:00",
                  }))
                }
              >
                <ToggleButton value="none">anschließend</ToggleButton>
                <ToggleButton value="xAM">vormittags</ToggleButton>
                <ToggleButton value="xPM">nachmittags</ToggleButton>
                <ToggleButton value="custom">feste Uhrzeit</ToggleButton>
              </ToggleButtonGroup>
              {!!state.firstLoad && !state.firstLoad.startsWith("x") ? (
                <div style={{ marginTop: "5px" }}>
                  <TimePicker
                    onChange={(firstLoad) =>
                      setState((s) => ({
                        ...s,
                        firstLoad,
                      }))
                    }
                    className={!state.firstLoad ? "nostart" : ""}
                    value={state.firstLoad}
                  />
                </div>
              ) : null}
            </LabelWrapper>
            <LabelWrapper label="Menge">
              <TextField
                size="small"
                fullWidth
                value={amount}
                autoFocus
                placeholder="Menge"
                multiline
                onChange={(e) => {
                  setAmount((e.target as HTMLInputElement).value);
                  setState((s) => ({
                    ...s,
                    amount: parseInt((e.target as HTMLInputElement).value) || 0,
                  }));
                }}
              />
            </LabelWrapper>
            <LabelWrapper label="Artikel">
              <TextField
                size="small"
                fullWidth
                value={state.article}
                placeholder="Artikel"
                multiline
                onChange={(e) =>
                  setState((s) => ({
                    ...s,
                    article: (e.target as HTMLInputElement).value,
                  }))
                }
              />
            </LabelWrapper>
            <LabelWrapper label="Kostenstelle">
              <TextField
                size="small"
                fullWidth
                value={state.number}
                placeholder="Kostenstelle"
                multiline
                onChange={(e) =>
                  setState((s) => ({
                    ...s,
                    number: (e.target as HTMLInputElement).value,
                  }))
                }
              />
            </LabelWrapper>
            <LabelWrapper label="Kunde/Baustelle">
              <TextField
                size="small"
                fullWidth
                value={state.project}
                placeholder="Kunde/Baustelle"
                multiline
                onChange={(e) =>
                  setState((s) => ({
                    ...s,
                    project: (e.target as HTMLInputElement).value,
                  }))
                }
              />
            </LabelWrapper>
            <LabelWrapper label="Ansprechpartner/Kolonne">
              <TextField
                size="small"
                fullWidth
                value={state.contact}
                placeholder="Ansprechpartner/Kolonne"
                multiline
                onChange={(e) =>
                  setState((s) => ({
                    ...s,
                    contact: (e.target as HTMLInputElement).value,
                  }))
                }
              />
            </LabelWrapper>
            <LabelWrapper label="Weitere Informationen">
              <TextField
                size="small"
                fullWidth
                value={state.info}
                placeholder="Weitere Informationen"
                multiline
                onChange={(e) =>
                  setState((s) => ({
                    ...s,
                    info: (e.target as HTMLInputElement).value,
                  }))
                }
              />
            </LabelWrapper>
          </DialogContent>
          <DialogActions sx={{ borderTop: "1px solid #aaa" }}>
            <Button onClick={close}>Abbrechen</Button>
            <Button
              variant="outlined"
              color="primary"
              disabled={!valid}
              onClick={() => {
                save(state);
                close();
              }}
            >
              OK
            </Button>
          </DialogActions>
        </Fragment>
      </Dialog>
    </LocalizationProvider>
  );
}
