import { downloadCsv } from "@/shared/helpers/downloadCsv";
import { FileDownload } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { DataGrid, GridColDef, GridRowsProp, GridValidRowModel } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { Fragment } from "react/jsx-runtime";

interface TablePanelProps {
  rows: GridRowsProp;
  type: string;
  setSelectedMarkerFromId: (s: string ,name: string, type: string) => void;
}
const getDefinition = (setSelectedMarkerFromId) => [
  {
    field: "time",
    width: 118,
    headerName: "🕐",
    valueGetter: (_, row) => {
      const part1 = dayjs.utc(row.loadedAt).local().format("HH:mm");
      if (!!row.startUnloadAt)
        return `${part1} → ${dayjs
          .utc(row.startUnloadAt)
          .local()
          .format("HH:mm")}`;
      if (!!row.enterBsAt)
        return `${part1} → ${dayjs.utc(row.enterBsAt).local().format("HH:mm")}`;
      if (!!row.leaveBsAt)
        return `${part1} → ${dayjs.utc(row.leaveBsAt).local().format("HH:mm")}`;
      return part1;
    },
    excludeForType: "",
  },
  {
    field: "werk",
    headerName: "Werk",
    width: 50,
    valueGetter: (_, row) => row.plant.number + " " + row.plant.name,
    renderCell: ({ row }) => (
      <span
        className="withLink"
        onClick={() => setSelectedMarkerFromId(row.plant.id, "plant", row.plant.name )}
        title={row.plant.name}
      >
        {row.plant.number}
      </span>
    ),
    excludeForType: "mischanlage",
  },
  {
    field: "bs",
    flex: 1,
    headerName: "Baustelle",
    valueGetter: (_, row) => row.project.name + " " + row.project.number,
    renderCell: ({ row }) => (
      <span
        className="withLink"
        onClick={() => setSelectedMarkerFromId(row.project.id, "site", row.project.name)}
        title={row.project.number + ": " + row.project.name}
      >
        {row.project.name}
      </span>
    ),
    excludeForType: "baustelle",
  },
  {
    field: "lkw",
    width: 100,
    headerName: "LKW",
    valueGetter: (_, row) => row.truck,
    renderCell: ({ row }) => (
      <span
        className="withLink"
        onClick={() => setSelectedMarkerFromId(row.truck, "truck", row.truck)}
      >
        {row.truck}
      </span>
    ),
    excludeForType: "lkw",
  },
  {
    field: "mat",
    flex: 1,
    headerName: "Material",
    valueGetter: (_, row) => row.article.name + " " + row.article.number,
    renderCell: ({ row }) => (
      <span title={row.article.number + ": " + row.article.name}>
        {row.article.name}
      </span>
    ),
    excludeForType: "",
  },
  {
    field: "amount",
    width: 70,
    align: "right",
    headerName: "Menge",
    valueGetter: (_, row) =>
      row.tonnage.replace(".", ",") + " " + row.article.unit,
    excludeForType: "",
  },
  {
    field: "lfs",
    width: 110,
    headerName: "Lieferschein",
    valueGetter: (_, row) => row.number,
    excludeForType: "",
  },
];



const TablePanel: React.FC<TablePanelProps> = ({
  rows,
  type,
  setSelectedMarkerFromId,
}) => {
  const columns = getDefinition(setSelectedMarkerFromId)
    .filter((x) => x.excludeForType !== type)
    .map(({ excludeForType, ...x }) => x);
  return (
      <Box
        height="100%"
        width="100%"
        sx={{ ".MuiDataGrid-columnHeaderTitle": { fontWeight: "bold" } }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          density="compact"
          disableRowSelectionOnClick
          hideFooter
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "grey.100",
            },
            "& .withLink": {
              textDecoration: "underline",
              cursor: "pointer",
            },
            "& .withLink:hover": {
              textDecoration: "none",
            },
          }}
        />
      </Box>
  );
};

export default TablePanel;
