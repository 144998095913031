import ApiService from "@/api/ApiService";
import { Delete, Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { Fragment } from "react";
import AddCommentDialog, { CalendarComment } from "./AddCommentDialog";


export function DirectCommentEditor({
  comment
}: {
  comment: CalendarComment;
}) {
  const queryClient = useQueryClient();

  const saveMutation = useMutation<
  CalendarComment,
    AxiosResponse,
    CalendarComment
  >({
    mutationFn: ApiService.editEntity("calendarNote"),
    onSuccess: (answer) => {
      queryClient.invalidateQueries({queryKey: ["getOverview"]});
    },
  });

  

  const loading = saveMutation.isPending;

  return true ? (
    <Fragment>
      <AddCommentDialog
        save={function (order: CalendarComment): void {
          saveMutation.mutate(order);
        }}
        OpenButton={({ onClick }) => (
          <Edit fontSize="inherit" onClick={onClick} />
        )}
        initialState={comment}
      />
   
        <Delete fontSize="inherit" onClick={() => saveMutation.mutate({ ...comment, deleted: true })} />
    </Fragment>
  ) : null;
}
