import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import { Fragment } from "react";
import { useQuery } from "@tanstack/react-query";
import ApiService from "@/api/ApiService";
import { AxiosResponse } from "axios";
import dayjs from "dayjs";
import { ArrowRight, East } from "@mui/icons-material";
import { states, transStates } from "../listView/MixingPlantView";
import { createSingleDeliveryText } from "@/components/planning/editors/MaterialTab";

const keytranslate = (key: string) => {
  switch (key) {
    case "article":
      return "Artikel";
    case "amount":
      return "Menge";
    case "takt":
      return "Takt";
    case "firstUnload":
      return "Erste Entladung";
    case "transportInformation":
      return "Logistik";
    case "easyTarget":
      return "Entladestelle";
    case "transportStatus":
      return "Trans-Status";
    case "plantStatus":
      return "Werk-Status";
    case "date":
      return "Datum";
    default:
      return "??? " + key;
  }
};

const formatter = (key: string, value: string) => {
  switch (key) {
    case "amount":
      return value;
    case "takt":
      return !value ? "gleichzeitig" : value + " min";
    case "firstUnload":
      return !value
        ? "anschließend"
        : !value.startsWith("x")
        ? value + " Uhr"
        : value === "xAM"
        ? "vormittags"
        : value === "xPM"
        ? "nachmittags"
        : "???";
    case "easyTarget":
      return value === "SITE"
        ? "Baustelle"
        : value === "LAGER"
        ? "Lagerplatz"
        : value;
    case "plantStatus":
      return (states[value] ?? ["", "", "Unbekannter Status: " + value])[2];
    case "transportStatus":
      return transStates[value]?.title || "Unbekannter Status: " + value;
    case "date":
      return dayjs(value, "YYYY-MM-DD").format("ddd, DD.MM.YYYY");
    default:
      return value;
  }
};

const TransportInfoDisplay = ({ change }: { change: [any, any] }) => {
  const old = change[0].map((x) => createSingleDeliveryText(x));
  const newx = change[1].map((x) => createSingleDeliveryText(x));

  return (
    <div className="changesets csarray">
      <div className="changesigs cs_trucks">
        {old.length === 0
          ? "keine"
          : old.map((line, i) => (
              <div
                className={
                  "changesigs_line" +
                  (newx.length <= i
                    ? " cs_deleted"
                    : newx[i] !== line
                    ? " cs_changed"
                    : "")
                }
              >
                {line}
              </div>
            ))}
      </div>
      <East className="chagarro" fontSize="inherit" />
      <div className="changesigs cs_trucks">
        {newx.length === 0
          ? "keine"
          : newx.map((line, i) => (
              <div
                className={
                  "changesigs_line" +
                  (old.length <= i
                    ? " cs_created"
                    : old[i] !== line
                    ? " cs_changed"
                    : "")
                }
              >
                {line}
              </div>
            ))}
      </div>
    </div>
  );
};

const ArticleDisplay = ({
  name,
  change,
}: {
  name: string;
  change: [any, any];
}) => {
  const oldName = change[0].name + " (" + change[0].number + ")";
  const newName = change[1].name + " (" + change[1].number + ")";
  const oldPlant = change[0].plantName;
  const newPlant = change[1].plantName;

  const old = [
    oldName === newName ? "" : oldName,
    oldPlant === newPlant ? "" : oldPlant,
  ]
    .filter((x) => x.length > 0)
    .join(" - ");
  const newx = [
    oldName === newName ? "" : newName,
    oldPlant === newPlant ? "" : newPlant,
  ]
    .filter((x) => x.length > 0)
    .join(" - ");

  return !old ? (
    <div className="changesets cssingleval">Keine Änderung</div>
  ) : (
    <div className="changesets csarray">
      <div className="changesigs">{old}</div>
      <East className="chagarro" fontSize="inherit" />
      <div className="changesigs">{newx}</div>
    </div>
  );
};

const ChangeDisplay = ({
  name,
  change,
}: {
  name: string;
  change: [any, any] | string;
}) => {
  return Array.isArray(change) ? (
    name === "article" ? (
      <ArticleDisplay name={name} change={change} />
    ) : name === "transportInformation" ? (
      <TransportInfoDisplay change={change} />
    ) : (
      <div className="changesets csarray">
        <div className="changesigs">{formatter(name, change[0])}</div>
        <East className="chagarro" fontSize="inherit" />
        <div className="changesigs">{formatter(name, change[1])}</div>
      </div>
    )
  ) : (
    <div className="changesets cssingleval">{formatter(name, change)}</div>
  );
};

export default function OrderHistoryDialog({ orderId, close }) {
  const query = useQuery<any, AxiosResponse>({
    queryKey: ["getOrderHistory", "" + orderId],
    enabled: !!orderId,
    queryFn: ApiService.getOrderHistory(orderId),
  });

  return (
    <Dialog open={!!orderId} maxWidth="lg" fullWidth onClose={close}>
      {query.isLoading || !orderId ? (
        <CircularProgress />
      ) : query.isLoadingError ? (
        "Error"
      ) : (
        <Fragment>
          <DialogTitle
            sx={{
              p: 1,
              px: 2,
              background: "#002169",
              color: "white",
              borderBottom: "1px solid rgba(255,255,255,0.5)",
            }}
          >
            Änderungshistorie
          </DialogTitle>
          <DialogContent
            sx={{
              p: 0,
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {query.data.length === 0 ? (
              "Keine nachverfolgten Änderungen gefunden."
            ) : (
              <div className="changes_container">
                {query.data.map((x, i) => (
                  <div className="singleChange" key={i}>
                    <div className="changeHeader">
                      <div className="changedatehea">
                        <Tooltip
                          title={
                            dayjs
                              .utc(x.when, "YYYY-MM-DD HH:mm:ss")
                              .local()
                              .format("ddd DD.MM.YYYY, HH:mm:ss") + " Uhr"
                          }
                        >
                          {dayjs
                            .utc(x.when, "YYYY-MM-DD HH:mm:ss")
                            .local()
                            .fromNow()}
                        </Tooltip>
                      </div>
                      <div className="changeuserhead">
                        {x.who.firstName + " " + x.who.name}
                      </div>
                    </div>
                    <div className="changechanges">
                      {Object.entries(x.what).map(([key, change]) => (
                        <div className="singchange" key={key}>
                          <div className="skekyna">{keytranslate(key)}</div>
                          <ChangeDisplay name={key} change={change} />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </DialogContent>
          <DialogActions
            sx={{
              borderTop: "1px solid #aaa",
              justifyContent: "space-between",
            }}
          >
            <div />
            <Button onClick={close}>Schließen</Button>
          </DialogActions>
        </Fragment>
      )}
    </Dialog>
  );
}
