export function downloadCsv(pairs, filename = "export.csv") {
  // German Excel expects UTF-16LE with BOM and semicolon as separator
  const separator = "\t";
  const mains = [];
  for (let [rows, config] of pairs) {
    const csvContentSub = [
      // First row: headers
      config.map((col) => `"${col.header}"`).join(separator),
      // Following rows: values
      ...rows.map((row) =>
        config
          .map((col) => {
            console.log(row);
            const value = col.valueGetter(row) ?? "";
            // Escape double quotes by doubling them
            return `"${value.replace(/"/g, '""')}"`;
          })
          .join(separator)
      ),
    ].join("\r\n");
    mains.push(csvContentSub);
  }
  const csvContent = mains.join("\r\n\r\n\r\n");

  // Encode as UTF-16LE with BOM
  // Manuell in UTF-16LE konvertieren
  const utf16leBuffer = stringToUtf16LeWithBom(csvContent);
  const blob = new Blob([utf16leBuffer], { type: 'text/csv;charset=utf-16le;' });

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

// 🔧 UTF-16LE Encoding mit BOM
function stringToUtf16LeWithBom(input: string): Uint8Array {
  const buffer = new Uint8Array(2 + input.length * 2); // 2 Byte BOM + 2 Byte pro Zeichen
  buffer[0] = 0xFF; // BOM Byte 1
  buffer[1] = 0xFE; // BOM Byte 2

  for (let i = 0; i < input.length; i++) {
    const code = input.charCodeAt(i);
    buffer[2 + i * 2] = code & 0xFF; // low byte
    buffer[2 + i * 2 + 1] = code >> 8; // high byte
  }

  return buffer;
}
