import { ArrowDropDown, CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { Button, Menu, MenuItem } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import useReferenceNames from "@/shared/forms/useReferenceNames";
import useRights from "@/shared/api/useRights";
import ApiService from "@/api/ApiService";
import { useQuery } from "@tanstack/react-query";

interface Plant {
  supplierName: string;
  id: number;
  name: string;
}

export default function SelectPlantDialog({
  reference,
  onChange,
}: {
  reference: string | null;
  onChange: (reference: number | null) => void;
}) {
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const open2 = !!anchorEl2;

  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const close = useCallback(() => {
    setAnchorEl2(null);
  }, []);

  const selected = ((reference||"0")+"").split(",").map(x => parseInt(x));

  const rights = useRights();
  let plantId = 0;
  if ("PLANT" in rights) {
    plantId = parseInt(rights["PLANT"]);
  }

  const plantQuery = useQuery<Plant[], AxiosResponse>({
    queryKey: ["getPlantsForList"],
    queryFn: ApiService.getEntitiesWithFilter(
      "plant",
      { showInList: true },
      "calendar"
    ),
  });

  const plantNames = useMemo(() => {
    if (!plantQuery.isSuccess) return new Map<number,Plant>();
    const x: [number, Plant][] = [[0, { supplierName: "", id: 0, name: "Alle" }]];
    const y = x.concat(
      plantQuery.data
        .map((x) => [
          x.id,
          {
            id: x.id,
            name:
              (x.name || "Unbekanntes Lieferwerk") +
              " (" +
              (x.supplierName || "Unbekannter Lieferant") +
              ")",
          },
        ] as ([number, Plant]))
        .sort((a, b) => a[1].name.localeCompare(b[1].name))
    );
    return new Map<number,Plant>(y);
  }, [plantQuery.data]);

  useEffect(() => {
    if (plantId > 0 && ("SUPPLIER_VIEW" in rights) && reference !== ""+plantId) {
      onChange(""+plantId);
      return;
    }
  }, [plantId, reference, rights]);

  const title2 = ((x) => (!!x ? x.name || x.supplierName : "Mehrere Werke"))(
    plantNames.get((""+(reference||"")).includes(",") ? -1 :  parseInt(reference??0))
  );

  return (
    <div>
      <Button
        onClick={!("SUPPLIER_VIEW" in rights) ? handleClick2 : undefined}
        endIcon={<ArrowDropDown />}
      >
        {title2}
      </Button>
      <Menu
        open={open2}
        anchorEl={anchorEl2}
        onClose={close}
        slotProps={{
          paper: {
            style: {
              maxHeight: "400px",
            },
          },
        }}
      >
        {Array.from(plantNames.values()).map((x) => (
          <MenuItem
            key={x.id}
            onClick={() => {
              if (x.id === 0){
                onChange("0");
                close();
              } else {
                let newx = selected.slice().filter(e => e !== 0);
                if (selected.includes(x.id)){
                  newx = newx.filter(m => m !== x.id);
                } else {
                  newx.push(x.id);
                }
                if (newx.length === 0){
                  newx = [0];
                }
                onChange(newx.join(","));
              }
            }}
          >
            {selected.includes(x.id) ? <CheckBox sx={{mr: 1}} /> : <CheckBoxOutlineBlank sx={{mr: 1}} />}{x.name || x?.supplierName || "Unbekannt"}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
