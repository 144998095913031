import { useGlobalStore, usePersistantStore } from "@/stores/global";
import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import ApiService from "@/api/ApiService";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { TopBar } from "@/shared/structure/TopBar";
import dayjs from "dayjs";
import useRights from "@/shared/api/useRights";
import {
  ArrowBackIos,
  ArrowForwardIos,
  NoLuggageOutlined,
} from "@mui/icons-material";
import DatePicker from "react-date-picker";
import ManyToOneSelect from "@/shared/forms/ManyToOneSelect";

function BmErfasserChanger({ bookingId, erfasserId, primaries }) {
  const region = usePersistantStore((state) => state.region);
  const [waitForLoad, setWaitForLoad] = useState(false);

  const queryClient = useQueryClient();

  useEffect(() => {
    setWaitForLoad(false);
  },[erfasserId]);

  const saveMutation = useMutation<any, AxiosResponse, number>(
    {
      mutationFn: ApiService.updateErfasser(bookingId),
      onSuccess: (answer) => {
        queryClient.invalidateQueries({ queryKey: ["getBaumobilErfasser"] });
        queryClient.invalidateQueries({ queryKey: ["getNull"] });
        setWaitForLoad(true);
      },
    }
  );

  return (
    saveMutation.isPending || waitForLoad ? <Skeleton variant="text" sx={{ fontSize: "2rem" }} /> : <ManyToOneSelect
      entity={"staffMember"}
      favorites={primaries}
      favoritesTitle={"Auf der Baustelle"}
      remoteFilter={{
        region: ["region", region],
        baumobilErfasser: true,
      }}
      clearIcon={null}
      size="small"
      placeholder="Bitte wählen"
      currentId={erfasserId || 0}
      disabled={false}
      sideEffectOnlyOnChange={true}
      setId={saveMutation.mutate}
    />
  );
}

export default function BaumobilCheck() {
  const setPageTitle = useGlobalStore((state) => state.setPageTitle);

  const [date, setDate] = useState(dayjs().add(1, "day"));
  const [calendarOpen, setCalendarOpen] = useState(false);
  const region = usePersistantStore((state) => state.region);

  useEffect(() => setPageTitle("Baumobil-Erfasser"), []);

  const rights = useRights();

  const query = useQuery<any[], AxiosResponse>({
    queryKey: ["getBaumobilErfasser", date.format("YYYY-MM-DD"), "" + region],
    queryFn: ApiService.getBookingList(date.format("YYYY-MM-DD"), region),
  });

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          p: 0,
          background: "#eee",
        }}
      >
        <TopBar>
          <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <IconButton
              size="small"
              onClick={() => setDate(date.subtract(1, "day"))}
            >
              <ArrowBackIos fontSize="small" />
            </IconButton>
            <Box
              onClick={() => setCalendarOpen(true)}
              sx={{
                display: "flex",
                minWidth: "145px",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                cursor: "pointer",
              }}
            >
              <Typography variant="h6">
                {date.format("dd., DD.MM.YYYY")}
              </Typography>
              <Box
                sx={{
                  position: "absolute",
                  left: 0,
                  bottom: 0,
                  ".invisiblePicker .react-date-picker__wrapper": {
                    display: "none",
                  },
                }}
              >
                <DatePicker
                  className={"invisiblePicker"}
                  onChange={(date: Date) => setDate(dayjs(date))}
                  isOpen={calendarOpen}
                  clearIcon={null}
                  onCalendarClose={() => {
                    console.log("calendar  closes");
                    setCalendarOpen(false);
                  }}
                  value={date.toDate()}
                />
              </Box>
            </Box>
            <IconButton
              size="small"
              onClick={() => setDate(date.add(1, "day"))}
            >
              <ArrowForwardIos fontSize="small" />
            </IconButton>
          </Box>
        </TopBar>

        <Paper sx={{ m: 1, position: "relative", overflow: "auto" }}>
          {(query.data ?? [])
            .sort(
              (a, b) =>
                a.job.phase.project.number.localeCompare(
                  b.job.phase.project.number
                ) || a.resource.number.localeCompare(b.resource.number)
            )
            .map((x, i, a) => (
              <Fragment key={x.booking_id}>
                {i === 0 ||
                a[i - 1].job.phase.project.number !==
                  x.job.phase.project.number ? (
                  <div className="bm_projectheader">
                    <span className="pnumber">
                      {x.job.phase.project.number}
                    </span>
                    {x.job.phase.project.name}
                  </div>
                ) : null}
                <div className="bm_resource">
                <div className="bm_erfasser">
                    <BmErfasserChanger
                      bookingId={x.booking_id}
                      erfasserId={x.erfasser_id}
                      primaries={x.primary}
                    />
                  </div>
                  <div className="bm_resname">
                    <span className="pnumber">{x.resource.number}</span>
                    {x.resource.name}
                  </div>
                  
                </div>
              </Fragment>
            ))}

          {!query.isSuccess ? (
            <div
              style={{
                padding: 2,
                background: "rgba(255,255,255,0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {query.isLoading ? <CircularProgress /> : <div>Error</div>}
            </div>
          ) : null}
        </Paper>
      </Box>
    </Box>
  );
}
