import { Box, Button, CircularProgress, Typography } from "@mui/material";
import Sidebar from "./Sidebar";
import TablePanel from "./TablePanel";
import MaterialSummaryTable from "./MaterialSummaryTable";
import { LivemapState, useLivemapStore } from "./useLivemapStore";
import { useTableData } from "./useTableData";
import DispoCalendarDateSelector from "../calendar/calendarElements/DispoCalendarDateSelector";
import { TopBar } from "@/shared/structure/TopBar";
import ApiService from "@/api/ApiService";
import { attribution, logistics_day, satellite_map } from "@/shared/maps/tiles";
import { useQuery } from "@tanstack/react-query";
import L, { LatLngExpression } from "leaflet";
import { useRef, useMemo, useEffect, useCallback } from "react";
import { MapContainer, LayersControl, TileLayer, Marker } from "react-leaflet";
import site_active from "@/assets/icons/site_active.png";
import plant from "@/assets/icons/mixingplant.png";
import ghost_full from "@/assets/icons/ghost_full.png";
import ghost_empty from "@/assets/icons/ghost_empty.png";
import truck_full from "@/assets/icons/truck_full.png";
import truck_empty from "@/assets/icons/truck_empty.png";
import { usePersistantStore } from "@/stores/global";
import dayjs from "dayjs";
import { downloadCsv } from "@/shared/helpers/downloadCsv";
import { FileDownload } from "@mui/icons-material";

type MarkerType = "site" | "plant" | "truck" | "ghost";

interface MarkerData {
  id: string;
  type: MarkerType;
  position: [number, number];
  data: any;
}

interface MarkerManagerProps {
  onMarkerClick?: (markerId: string, data: MarkerData, date: string) => void;
}

const LivemapContainer = () => {
  const dateObj = useLivemapStore((s) => s.getDate());
  const setDate = useLivemapStore((s) => s.setDate);
  const region = usePersistantStore((s) => s.region);

  const map = useRef();
  const date = useLivemapStore((s) => s.date);
  const hasFitted = useRef(false);
  const setSelectedMarker = useLivemapStore((s) => s.setSelectedMarker);
  const selectedMarker = useLivemapStore((s) => s.selectedMarker);

  const { data } = useQuery({
    queryKey: ["mapData", date, region],
    queryFn: ApiService.getLiveMap("map", date, region),
    refetchInterval: date === getTodayDate() ? 10_000 : 60_000,
  });

  const markers = useMemo(() => {
    if (!data) return [];
    return Object.entries(data.markers).map(([id, info]: any) => ({
      id,
      type: info.type as MarkerType,
      position: info.position as LatLngExpression,
      data: info.data,
    }));
  }, [data]);

  useEffect(() => {
    map.current?.invalidateSize();
  }, [selectedMarker]);

  useEffect(() => {
    hasFitted.current = false;
  }, [date, region]);

  useEffect(() => {
    if (!map.current || hasFitted.current || markers.length === 0) return;

    const bounds = L.latLngBounds(markers.map((m) => m.position));
    map.current.fitBounds(bounds, { paddingBottomRight: [0, 0] });
    hasFitted.current = true;
  }, [map.current, markers]);

  const setSelectedMarkerFromId = useCallback(
    (id, type, name) => {
      const marker = markers.find(
        (marker) => (""+marker.id) === (type !== "truck" ? (type === "plant" ? "ama" : type) : "") + id
      );
      console.log((type !== "truck" ? type : "") + id, markers, id, type, name);
      if (!marker) {
        setSelectedMarker(
          id,
          { type, data: { actuality: "00:00", id, name, status: "EMPTY" } },
          date
        );
        return;
      } else {
        console.log("marker found", marker);
        setSelectedMarker(marker.id, marker, date);
        map.current.flyTo(
          marker.position,
          Math.max(map.current.getZoom(), 14),
          {
            animate: true,
          }
        );
      }
    },
    [markers, date]
  );

  const {
    data: tableData,
    isLoading: isLoadingTable,
    isError,
  } = useTableData(selectedMarker?.tableId ?? "", selectedMarker?.type ?? "");

  console.log(selectedMarker);

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        p: 0,
        background: "#eee",
      }}
    >
      <TopBar>
        <DispoCalendarDateSelector
          overwriteDate={dateObj}
          overwriteSetDate={setDate}
          noFuture={true}
          month={false}
        />
      </TopBar>
      <Box display="flex" flex={1} overflow={"hidden"}>
        {/* Left: Map */}
        <Box flex={1}>
          <MapContainer
            ref={map}
            center={[49.7668, 7.3393]}
            zoom={11}
            style={{ height: "100%", width: "100%" }}
          >
            <LayersControl position="bottomleft">
              <LayersControl.BaseLayer checked name="Straßenkarte">
                <TileLayer attribution={attribution} url={logistics_day} />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="Satellit">
                <TileLayer attribution={attribution} url={satellite_map} />
              </LayersControl.BaseLayer>
            </LayersControl>
            {markers.map((marker) => {
              const icon = getIcon(marker.type, marker.data?.status);

              return (
                <Marker
                  key={marker.id}
                  position={marker.position}
                  icon={icon}
                  eventHandlers={{
                    click: () => {
                      setSelectedMarker?.(marker.id, marker, date);
                      map.current.flyTo(
                        marker.position,
                        Math.max(map.current.getZoom(), 14),
                        {
                          animate: true,
                        }
                      );
                    },
                  }}
                ></Marker>
              );
            })}
          </MapContainer>
        </Box>

        {/* Right: Sidebar */}
        <Sidebar
          open={!!selectedMarker}
          title={
            selectedMarker?.data.name +
            (!!selectedMarker?.data.number
              ? " (" + selectedMarker?.data.number + ")"
              : "")
          }
          description={getDescription(selectedMarker)}
        >
          {isLoadingTable ? (
            <CircularProgress size={24} />
          ) : isError ? (
            <Typography color="error">Fehler beim Laden der Tabelle</Typography>
          ) : (
            <>
              <MaterialSummaryTable material={tableData?.material ?? {}} />
              <Box mt={2}>
                <TablePanel
                  setSelectedMarkerFromId={setSelectedMarkerFromId}
                  type={selectedMarker?.type ?? ""}
                  rows={tableData?.rows ?? []}
                />
              </Box>
              <Box sx={{ mt: 1, display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  onClick={() =>
                    downloadCsv<any>([
                      [
                        [
                          {
                            date,
                            title:
                              selectedMarker?.data.name +
                              (!!selectedMarker?.data.number
                                ? " (" + selectedMarker?.data.number + ")"
                                : ""),
                          },
                        ],
                        excelTitleConfig,
                      ],
                      [Object.entries(tableData?.material), excelSumConfig],
                      [tableData?.rows, excelMainConfig],
                    ])
                  }
                  startIcon={<FileDownload />}
                >
                  Exportieren
                </Button>
              </Box>
            </>
          )}
        </Sidebar>
      </Box>
    </Box>
  );
};

export default LivemapContainer;

// 🔍 Sidebar Description Logic
function getDescription(marker: LivemapState["selectedMarker"]): string {
  if (!marker) return "";
  const { type, data } = marker;

  switch (type) {
    case "mischanlage":
      return "";
    case "baustelle":
      return "";
    case "lkw":
      return data.actuality
        ? `Der LKW war um ${data.actuality} Uhr an dieser Position.`
        : "Die Position wurde hochgerechnet – die genaue Position ist unbekannt.";
    default:
      return "";
  }
}

const getTodayDate = () => new Date().toISOString().split("T")[0];

const getIcon = (type: MarkerType, status?: string): L.Icon => {
  let iconUrl: string;
  let size: [number, number];

  switch (type) {
    case "ghost":
      iconUrl = status === "EMPTY" ? ghost_empty : ghost_full;
      size = [40, status === "EMPTY" ? 28 : 30];
      break;
    case "truck":
      iconUrl = status === "EMPTY" ? truck_empty : truck_full;
      size = [40, status === "EMPTY" ? 28 : 30];
      break;
    case "site":
      iconUrl = site_active;
      size = [54, 57];
      break;
    case "plant":
      iconUrl = plant;
      size = [54, 52];
      break;
  }

  return L.icon({
    iconUrl,
    iconSize: size,
    iconAnchor: [size[0] / 2, size[1] / 2],
  });
};

const excelTitleConfig = [
  {
    header: "Datum",
    valueGetter: (row) => dayjs(row.date).format("DD.MM.YYYY"),
  },
  { header: "Titel", valueGetter: (row) => row.title },
];

const excelSumConfig = [
  { header: "Artikelnummer", valueGetter: (row) => row[1][1] },
  { header: "Artikelname", valueGetter: (row) => row[0] },
  { header: "Menge", valueGetter: (row) => (row[1][0] + "").replace(".", ",") },
  { header: "Einheit", valueGetter: (row) => row[1][2] },
];

const excelMainConfig = [
  { header: "Lieferscheinnummer", valueGetter: (row) => row.number },
  { header: "Werksnummer", valueGetter: (row) => row.plant.number },
  { header: "Werksname", valueGetter: (row) => row.plant.name },
  { header: "Baustellennummer", valueGetter: (row) => row.project.number },
  { header: "Baustellenname", valueGetter: (row) => row.project.name },
  { header: "Artikelnummer", valueGetter: (row) => row.article.number },
  { header: "Artikelname", valueGetter: (row) => row.article.name },
  { header: "Menge", valueGetter: (row) => row.tonnage.replace(".", ",") },
  { header: "Einheit", valueGetter: (row) => row.article.unit },
  { header: "LKW", valueGetter: (row) => row.truck },
  {
    header: "Beladung",
    valueGetter: (row) =>
      dayjs.utc(row.loadedAt).local().format("DD.MM.YYYY HH:mm:ss"),
  },
  {
    header: "Ankunft Baustelle",
    valueGetter: (row) =>
      !row.enterBsAt
        ? ""
        : dayjs.utc(row.enterBsAt).local().format("DD.MM.YYYY HH:mm:ss"),
  },
  {
    header: "Entladebeginn",
    valueGetter: (row) =>
      !row.startUnloadAt
        ? ""
        : dayjs.utc(row.startUnloadAt).local().format("DD.MM.YYYY HH:mm:ss"),
  },
  {
    header: "Entladeende",
    valueGetter: (row) =>
      !row.endUnloadAt
        ? ""
        : dayjs.utc(row.endUnloadAt).local().format("DD.MM.YYYY HH:mm:ss"),
  },
  {
    header: "Verlassen Baustelle",
    valueGetter: (row) =>
      !row.leaveBsAt
        ? ""
        : dayjs.utc(row.leaveBsAt).local().format("DD.MM.YYYY HH:mm:ss"),
  },
];
