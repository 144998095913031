import {
  Construction,
  KeyboardArrowDown,
  KeyboardArrowRight,
  People,
  Person,
} from "@mui/icons-material";
import { CircularProgress, Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useCallback, useState } from "react";
import { Fragment } from "react/jsx-runtime";

const user = new Image();
user.src =
  " data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAYUAAAGFAE70cj0AAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAATZJREFUOI2tlb2NhDAQhZ9XIkFuAYsqoAASyJZC3AG0gSlgTxdcBXSw2wMS5A7JVmLngjukxX8Q7JMm8Xg+yX7jMYgIrgBQAbgDICPuACpvnQNUAHg5QGasAIogEMDXCdAuoij6dgIB3FwFwzDQpmEYfODbDvh/TGvjNE1kap5nH7R4B1p3xjm3YJs45y7gi4hwYYyVABgMpWlqLh3lGGOsAoCH78J9Cpj0QMhBKaUFk1IeOR9uiyRJqO97UkpRkiSHbcQ26qd08SXqusY4jliWZRfjOOJ6vQahlilKKa8hm7qu85pSmYmzcgCrrbHXbVEIsZ4FCiGsxnY+Pa31IUxr7X965Jg0bdt6YU3ThIcDBSYO5/yZZZnO81xzzp9mPo7jH/LNw7fjf2bAGuDS1VL4+wJKX90v7Ce+c9O5jtgAAAAASUVORK5CYII=";

const device = new Image();
device.src =
  " data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAYUAAAGFAE70cj0AAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAqhJREFUOI2VlT9IW1EUxn8vvuZhQHFQEa2DIYOu7SIKzm7aalp1UIwuZhIpVHDwz2ZbHBwCmVtbdal1FxyELGnBRUptQ0g0IiImUfDFKl+HJvKisdoPznK+c3733fveO9eQxE0ZhuEGngFdwBPgcd7aA74Ba8CapItbzZKKAugBfgG6J34Cz2/1O0BlwLsHgG7GW8BVCvhgWFVVlWZmZuTz+Qq5+SJgfpv3gjwejwKBgCYmJpRMJhUKhZx+d+F9uPPncSfI6/VqYWFBJycnkqRgMCjbthWJRJx1sTyLl/+Ctbe3y7ZtSdLBwYF6e3sFaH19XWdnZyorK3PW+wE+/QsYCAQUDocVCoVUXV19ne/r65MkNTc3O+s/APy4CzY9Pa1kMqm9vT2lUim1trZee+Xl5Uqn0+rv73f2fAc4vQu4s7OjlpYWjY+Py+/3a3l5ucgfHBxUZ2enM3daBOzp6VE0Gr0O27Y1MDCgqakpmaapWCym2tpaAfL5fMpkMlpcXHQCMy4gRV4dHR3E43Hi8TixWAzLslhaWqKmpobLy0u2t7dpbGzEsixWVlaorKxkf38fh1IAHwsrTE5OKpvNKpvNKpPJqKCjoyNFo1Gl02l5vV61tbUpHA5rdnZWHo/H+YTvDeAFsAJgmiZjY2M0NDRQSsfHx0QiEba2tkr6gN8FfObvh83IyAhzc3NIYnR0FEkMDw9zdXXF0NAQ9fX1bG5u0tTUVAoWA9Zdkn4DrwEsyyKXy5FIJLBtm1QqRS6X4/DwkPPzcxKJBC6XC8uySgEnJF0Yhf/PMIy3brf7VTAYpK6uDtM0qaiouNW1sbHB6urqzfS8pEmgaNq4gDf8//iap9T4coC7gd0HgHaBrpv911t2yjCMR3lwF/CU4ivgK3+vgC/58y/SH5cdIXnGOSoYAAAAAElFTkSuQmCC";


function DraggableRes({x, draggable}){

    const dragstart = useCallback(
        (e) => {
          console.log(x);
          e.dataTransfer.clearData();
          e.dataTransfer.setData("application/free-resource", "0#" + x.date + "#" + x.id + "#"+x.type);
          e.dataTransfer.setDragImage(x.type === "HUMAN" || x.type === "CREW" ? user : device, 0, 0);
          e.dataTransfer.effectAllowed = "move";
          e.target.classList.add("dragging");
          e.target.style.cursor = "grabbing !important";
        },
        [x.date, x.id, x.tag_id]
      );

    return <Box
            draggable={true}
            onDragStart={(e) => dragstart(e)}
            onDragEnd={(e) => e.target.classList.remove("dragging")}
            sx={{
              lineHeight: 1,
              padding: "3px 3px",
              fontSize: "13px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              cursor: "pointer",
              userSelect: "none",
              textOverflow: "ellipsis",
              "&:hover":{
                fontWeight: "bold"
              },
              "&.grabbing": {
                color: "#888"
              }
            }}
          >
            {x.number.length > 0 ? (
              <span
                style={{ color: "#444", fontSize: "12px", marginRight: "3px" }}
              >
                {x.number}
              </span>
            ) : null}
            {x.name}
          </Box>;
}

export default function FreeContainer({ query, setCalendar, draggable }) {
  const [open, setOpen] = useState<Set<string>>(new Set());
  const toggle = (value: string) => {
    setOpen((prev) => {
      const newSet = new Set(prev); // Create a copy of the current Set
      if (newSet.has(value)) {
        newSet.delete(value); // Remove the value if it exists
      } else {
        newSet.add(value); // Add the value if it doesn't exist
      }
      return newSet; // Return the updated Set
    });
  };

  return query.isLoading ? (
    <CircularProgress />
  ) : query.isLoadingError ? (
    "Error"
  ) : (
    (query?.data ?? []).map((x, i, f) => (
      <Fragment key={x.date + "_" + x.tag_id +"_"+ x.id}>
        {i === 0 || f[i - 1].date !== x.date ? (
          <Box
            sx={{
              position: "sticky",
              top: 0,
              color: "#555",
              p: "4px 6px",
              lineHeight: 1,
              height: "26px",
              fontWeight: "14px",
              flexShrink: 0,
              background: "#edf0f9",
              borderTop: "1px solid rgba(0,0,0,0.2)",
              borderBottom: "1px solid rgba(0,0,0,0.2)",
              boxShadow: "inset 0px -4px 5px rgba(0,0,0,0.1)",
            }}
          >
            {dayjs(x.date).format("DD.MM.YYYY")}
          </Box>
        ) : null}
        {i === 0 || f[i - 1].tag_name !== x.tag_name ? (
          <Box
            
            style={
              open.has(x.date + "-" + x.tag_id)
                ? { borderBottom: "1px solid #aaa" }
                : {}
            }
            sx={{
              position: "sticky",
              top: "26px",
              background: "white",
              userSelect: "none",
              gap: "5px",
              fontSize: "14px",
              lineHeight: 1,
              whiteSpace: "nowrap",
              display: "flex",
              alignItems: "center",
              borderTop: "1px solid #aaa",
              cursor: "pointer",
              "&:hover": {
                background: "#eee",
              },
            }}
          >
            <Box onClick={() => toggle(x.date + "-" + x.tag_id)} sx={{ display: "flex", alignItems: "center" }}>
              {open.has(x.date + "-" + x.tag_id) ? (
                <KeyboardArrowDown fontSize="inherit" />
              ) : (
                <KeyboardArrowRight fontSize="inherit" />
              )}
            </Box>
            <Box onClick={() => toggle(x.date + "-" + x.tag_id)} sx={{ flex: 1, p: "2px 0px" }}>{x.tag_name}</Box>
            <Box onClick={() => setCalendar(
                    x.type === "CREW"
                      ? "CREWS"
                      : x.type === "RESOURCE"
                      ? "RESOURCES"
                      : "STAFF",
                    x.tag_id,
                    dayjs(x.date, "YYYY-MM-DD")
                  )} sx={{ display: "flex", background: "#fff", "&:hover": {background: "#002594", color:"white"}, alignItems: "center", borderLeft: "1px solid #aaa", p: "0px 2px", alignSelf: "stretch" }}>
              {x.type === "CREW" ? (
                <People fontSize="inherit" />
              ) : x.type === "RESOURCE" ? (
                <Construction fontSize="inherit" />
              ) : (
                <Person fontSize="inherit" />
              )}
            </Box>
          </Box>
        ) : null}
        {open.has(x.date + "-" + x.tag_id) ? (
          <DraggableRes draggable={draggable} x={x} />
        ) : null}
      </Fragment>
    ))
  );
}
