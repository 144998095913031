import ListBasedManyToManySelect from "@/shared/forms/ListBasedManyToManySelect";
import ManyToOneSelect from "@/shared/forms/ManyToOneSelect";
import { TextField, ToggleButtonGroup, ToggleButton } from "@mui/material";
import LabelWrapper from "../root/LabelWrapper";
import { displayFunctions } from "./definitions";
import React from "react";
import { POIEditor } from "@/shared/views/POIEditor";

const nothing = a => a;

function DynamicField ({field,value, disabled, setObject, region, relation}) {
field.sideEffectFn = !!field.sideEffectFn
                ? field.sideEffectFn
                : nothing;



              return (
                <LabelWrapper key={field.id} label={field.title}>
                  {field.type === "string" ? (
                    <TextField
                      size="small"
                      fullWidth
                      disabled={
                        disabled
                      }
                      value={value}
                      placeholder={field.title}
                      onChange={(e) =>
                        setObject((o) =>
                          field.sideEffectFn({
                            ...o,
                            [field.id]: (e.target as HTMLInputElement).value,
                          })
                        )
                      }
                    />
                  ) : field.type === "enum" ? (
                    <ToggleButtonGroup
                      exclusive
                      disabled={disabled
                      }
                      value={value}
                      onChange={(_, status) =>
                        status &&
                        setObject((o) =>
                          field.sideEffectFn({ ...o, [field.id]: status })
                        )
                      }
                    >
                      {Object.entries(field.values).map(([key, value]) => (
                        <ToggleButton value={key} key={key}>{value}</ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  ) : field.type === "boolean" ? (
                    <ToggleButtonGroup
                      exclusive
                      disabled={
                        disabled
                      }
                      value={value}
                      onChange={(_, status) =>
                        setObject((o) =>
                          field.sideEffectFn({ ...o, [field.id]: status })
                        )
                      }
                    >
                      <ToggleButton color="success" value={true}>
                        Ja
                      </ToggleButton>
                      <ToggleButton color="error" value={false}>
                        Nein
                      </ToggleButton>
                    </ToggleButtonGroup>
                  ) : field.type === "Position" ? (
                    <POIEditor noPOIs={true} lat={field.getLat(value)} lng={field.getLng(value)} setLatLng={(latlng) =>
                      setObject((o) =>
                        field.sideEffectFn({
                          ...o,
                          [field.id]: {site: latlng},
                        })
                      )
                    } />
                  ) : field.type === "ManyToOne" ? (
                    <ManyToOneSelect<{ id: number; name: string }>
                      disabled={
                        disabled
                      }
                      size="small"
                      entity={field.relation}
                      showWithoutChoice
                      remoteFilter={
                        field.regionalized
                          ? { region: ["region", region], ...(field.remoteFilter ?? {})}
                          : field.remoteFilter ?? undefined
                      }
                      autoSelect={false}
                      displayFunction={
                        displayFunctions[field.relation] ?? undefined
                      }
                      currentId={value ?? 0}
                      setId={(id) =>
                        setObject((o) =>
                          field.sideEffectFn({
                            ...o,
                            [field.id]: id > 0 ? id : null,
                          })
                        )
                      }
                      placeholder={field.title}
                    />
                  ) : field.type === "ManyToMany" ? (
                    <ListBasedManyToManySelect<{ id: number; name: string }>
                      disabled={
                       disabled
                      }
                      groupByFunction={field.groupBy}
                      entity={
                        relation
                      }
                      remoteFilter={
                        field.regionalized
                          ? {
                              region: ["region", region],
                              ...(field.remoteFilter ?? {}),
                            }
                          : field.remoteFilter ?? undefined
                      }
                      displayFunction={
                        displayFunctions[
                          relation]??undefined
                      }
                      currentIds={value ?? []}
                      setIds={(ids) =>
                        setObject((o) =>
                          field.sideEffectFn({ ...o, [field.id]: ids })
                        )
                      }
                    />
                  ) : null}
                </LabelWrapper>
              );
            }
            
export default React.memo(DynamicField);