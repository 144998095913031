import ApiService from "@/api/ApiService";
import { Delete, Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { Fragment } from "react";
import AddExternalDialog, { ExternalOrder } from "./AddExternalDialog";

type SingleJobExternalMaterialOrder = ExternalOrder & { plant: number };

export function DirectExternalMaterialEditor({
  plant,
  order
}: {
  plant: number;
  order: ExternalOrder;
}) {
  const queryClient = useQueryClient();

  const saveMutation = useMutation<
  SingleJobExternalMaterialOrder,
    AxiosResponse,
    SingleJobExternalMaterialOrder
  >({
    mutationFn: ApiService.editEntity("manualMaterialOrder"),
    onSuccess: (answer) => {
      queryClient.invalidateQueries({queryKey: ["getMaterialCalendar"]});
    },
  });

  

  const loading = saveMutation.isPending;

  return true ? (
    <Fragment>
      <AddExternalDialog
        save={function (order: ExternalOrder): void {
          saveMutation.mutate({ ...order, plant });
        }}
        OpenButton={({ onClick }) => (
          <IconButton size="small" onClick={onClick}>
            <Edit fontSize="small"  />
          </IconButton>
        )}
        initialState={order}
      />
      <IconButton
        size="small"
        onClick={() => saveMutation.mutate({ ...order, plant, deleted: true })}
      >
        <Delete fontSize="small" />
      </IconButton>
    </Fragment>
  ) : null;
}
