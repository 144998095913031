import { useCallback } from "react";

const user = new Image();
user.src =
  " data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAYUAAAGFAE70cj0AAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAATZJREFUOI2tlb2NhDAQhZ9XIkFuAYsqoAASyJZC3AG0gSlgTxdcBXSw2wMS5A7JVmLngjukxX8Q7JMm8Xg+yX7jMYgIrgBQAbgDICPuACpvnQNUAHg5QGasAIogEMDXCdAuoij6dgIB3FwFwzDQpmEYfODbDvh/TGvjNE1kap5nH7R4B1p3xjm3YJs45y7gi4hwYYyVABgMpWlqLh3lGGOsAoCH78J9Cpj0QMhBKaUFk1IeOR9uiyRJqO97UkpRkiSHbcQ26qd08SXqusY4jliWZRfjOOJ6vQahlilKKa8hm7qu85pSmYmzcgCrrbHXbVEIsZ4FCiGsxnY+Pa31IUxr7X965Jg0bdt6YU3ThIcDBSYO5/yZZZnO81xzzp9mPo7jH/LNw7fjf2bAGuDS1VL4+wJKX90v7Ce+c9O5jtgAAAAASUVORK5CYII=";

const device = new Image();
device.src =
  " data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAYUAAAGFAE70cj0AAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAqhJREFUOI2VlT9IW1EUxn8vvuZhQHFQEa2DIYOu7SIKzm7aalp1UIwuZhIpVHDwz2ZbHBwCmVtbdal1FxyELGnBRUptQ0g0IiImUfDFKl+HJvKisdoPznK+c3733fveO9eQxE0ZhuEGngFdwBPgcd7aA74Ba8CapItbzZKKAugBfgG6J34Cz2/1O0BlwLsHgG7GW8BVCvhgWFVVlWZmZuTz+Qq5+SJgfpv3gjwejwKBgCYmJpRMJhUKhZx+d+F9uPPncSfI6/VqYWFBJycnkqRgMCjbthWJRJx1sTyLl/+Ctbe3y7ZtSdLBwYF6e3sFaH19XWdnZyorK3PW+wE+/QsYCAQUDocVCoVUXV19ne/r65MkNTc3O+s/APy4CzY9Pa1kMqm9vT2lUim1trZee+Xl5Uqn0+rv73f2fAc4vQu4s7OjlpYWjY+Py+/3a3l5ucgfHBxUZ2enM3daBOzp6VE0Gr0O27Y1MDCgqakpmaapWCym2tpaAfL5fMpkMlpcXHQCMy4gRV4dHR3E43Hi8TixWAzLslhaWqKmpobLy0u2t7dpbGzEsixWVlaorKxkf38fh1IAHwsrTE5OKpvNKpvNKpPJqKCjoyNFo1Gl02l5vV61tbUpHA5rdnZWHo/H+YTvDeAFsAJgmiZjY2M0NDRQSsfHx0QiEba2tkr6gN8FfObvh83IyAhzc3NIYnR0FEkMDw9zdXXF0NAQ9fX1bG5u0tTUVAoWA9Zdkn4DrwEsyyKXy5FIJLBtm1QqRS6X4/DwkPPzcxKJBC6XC8uySgEnJF0Yhf/PMIy3brf7VTAYpK6uDtM0qaiouNW1sbHB6urqzfS8pEmgaNq4gDf8//iap9T4coC7gd0HgHaBrpv911t2yjCMR3lwF/CU4ivgK3+vgC/58y/SH5cdIXnGOSoYAAAAAElFTkSuQmCC";

function CrewDetails({ element, settings, date, readOnly }) {
  const dragstart = useCallback(
    (e, x) => {
      console.log(x);
      e.dataTransfer.clearData();
      e.dataTransfer.setData("application/resource-booking", x.id + "#" + date);
      e.dataTransfer.setDragImage(x.type === "HUMAN" ? user : device, 0, 0);
      e.dataTransfer.effectAllowed = "move";
      e.target.classList.add("dragging");
      e.target.style.cursor = "grabbing !important";
    },
    [date]
  );

  return (
    <div className="crewpartsdate">
      {!!settings.showMaterial && Array.isArray(element.materialOrders)
        ? element.materialOrders
            .filter((x) => !!x.article)
            .map((x) => (
              <div key={"mat" + x.id} className="crewpart material">
                {x.amount +
                  " " + x.article.unit +
                  " " +
                  x.article.name +
                  " (" +
                  x.article.plantName +
                  ")"}
              </div>
            ))
        : null}
      {element.bookingsAndOrders.resourceBookings
        .filter((x) =>
          x.type === "HUMAN"
            ? (!!x.crew && !!settings.showCrewMembers) ||
              (!x.crew && !!settings.showOtherStaff)
            : (!!x.crew && !!settings.showCrewResources) ||
              (!x.crew && !!settings.showOtherResources)
        )
        .map((b, i, x) => (
          <div
            draggable={b.isLeader || readOnly ? false : true}
            onDragStart={(e) => dragstart(e, b)}
            onDragEnd={(e) => e.target.classList.remove("dragging")}
            //onDragEnd={mouseUp}
            key={b.id}
            title={b.type === "HUMAN" ? b.staffMember.name : b.resource.name+" ("+b.resource.number+")"}
            className={
              "crewpart" +
              (b.vacation > 0 ? " hasVacation"+b.vacation : "") +
              ((i === 0 &&
                !!settings.showMaterial &&
                element.materialOrders.length > 0) ||
              (i !== 0 &&
                (b.type !== x[i - 1].type ||
                  !!b.crew !== !!x[i - 1].crew ||
                  (!!b.crew && b.crew.id !== x[i - 1].crew.id)))
                ? " withTopLine"
                : "") +
              (b.isLeader ? " isLeader" : "")
            }
          >
            {b.type === "HUMAN" ? b.staffMember.name : b.resource.name}
          </div>
        ))}
    </div>
  );
}

export default function CrewParts({ elements, i, settings, readOnly }) {
  const dates = Object.keys(elements).sort();
  return (
    <div className="crewparts">
      {dates.map((date) => { 
        
        const xel = elements[date][i];

        if (!xel || !xel.element){
          return null;
        }
        
        return <CrewDetails
          settings={settings}
          readOnly={readOnly}
          element={xel.element}
          key={date}
          date={date}
        />;})}
    </div>
  );
}
